import { Drawer, IconButton, List } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  Contacts,
  Folder,
  Group,
  Home as HomeIcon,
  Money,
  QuestionAnswer,
  Sms,
  SupervisedUserCircle,
  Wc
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import { Constant } from "../../Constant";
import {
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";

const structure = [
  // { id: 24, label: "dashboard", link: "/app/dashboard", icon: <PermDataSetting /> },
  { id: 15, label: "Assemblees", link: "/app/Assemblées", icon: <HomeIcon /> },
  { id: 16, label: "Departements", link: "/app/Départements", icon: <Contacts /> },
  { id: 17, label: "Membres", link: "/app/Membres", icon: <Group /> },
  { id: 21, label: "Mariages", link: "/app/Mariages", icon: <Wc /> },
  { id: 18, label: "Activites", link: "/app/Activités", icon: <SupervisedUserCircle /> },
  { id: 20, label: "Projets", link: "/app/Projets", icon: <Folder /> },
  { id: 19, label: "Comptabilites", link: "/app/Comptabilités", icon: <Money /> },
  { id: 23, label: "Culte", link: "/app/Culte", icon: <Group /> },
  { id: 22, label: "SMS", link: "/app/SMS", icon: <Sms /> },
  { id: 24, label: "BetiwQuiz", link: "/app/BetiwQuiz", icon: <QuestionAnswer /> },
  { id: 25, label: "BetiwEnseignement", link: "/app/BetiwEnseignement", icon: <Group /> },
  // { id: 23, label: "Statistiques", link: "/app/Statistiques", icon: <BarChart /> },
]

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  let user = JSON.parse(localStorage.getItem('user_info'));
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const value = (link) =>{
    return (<SidebarLink
      key={link.id}
      location={location}
      isSidebarOpened={isSidebarOpened}
      {...link}
    />)
  }

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => {
          let hasRole = false;
              if(link.id === 24 && user !== null && (user.roles.some(e => e.nom === Constant.ROLE_BETIW_QUIZ || e.nom === Constant.ROLE_ADMIN))) {
                hasRole = true
              } else if(link.id === 25 && user !== null && (user.roles.some(e => e.nom === Constant.ROLE_BETIW_ENSEIGNEMENT_FR || e.nom === Constant.ROLE_BETIW_ENSEIGNEMENT_ML ||  e.nom === Constant.ROLE_BETIW_ENSEIGNEMENT_EN || e.nom === Constant.ROLE_ADMIN))) {
                hasRole = true
              } else if(link.id === 15 && user !== null && (user.roles.some(e => e.nom === Constant.ROLE_ASSEMBLE || e.nom === Constant.ROLE_ADMIN))) {
                hasRole = true
              } else if(link.id === 16 && user !== null && (user.roles.some(e => e.nom === Constant.ROLE_RESPONSABLE_ASSEMBLE || e.nom === Constant.ROLE_RESPONSABLE_DEPARTEMENT || e.nom === Constant.ROLE_DEPARTEMENT || e.nom === Constant.ROLE_ASSEMBLE || e.nom === Constant.ROLE_ADMIN))) {
                hasRole = true
              } else if(link.id === 17 && user !== null && (user.roles.some(e => e.nom === Constant.ROLE_RESPONSABLE_ASSEMBLE || e.nom === Constant.ROLE_MEMBRES || e.nom === Constant.ROLE_ASSEMBLE || e.nom === Constant.ROLE_ADMIN))) {
                hasRole = true
              } else if(link.id === 21 && user !== null && (user.roles.some(e => e.nom === Constant.ROLE_RESPONSABLE_ASSEMBLE || e.nom === Constant.ROLE_MARIAGE || e.nom === Constant.ROLE_ASSEMBLE || e.nom === Constant.ROLE_ADMIN))) {
                hasRole = true
              } else if(link.id === 18 && user !== null && (user.roles.some(e => e.nom === Constant.ROLE_RESPONSABLE_ASSEMBLE || e.nom === Constant.ROLE_ACTIVITE || e.nom === Constant.ROLE_ASSEMBLE || e.nom === Constant.ROLE_ADMIN))) {
                hasRole = true
              } else if(link.id === 20 && user !== null && (user.roles.some(e => e.nom === Constant.ROLE_RESPONSABLE_ASSEMBLE || e.nom === Constant.ROLE_PROJET_DEPARTEMENT || e.nom === Constant.ROLE_ASSEMBLE || e.nom === Constant.ROLE_ADMIN))) {
                hasRole = true
              } else if(link.id === 19 && user !== null && (user.roles.some(e => e.nom === Constant.ROLE_RESPONSABLE_ASSEMBLE || e.nom === Constant.ROLE_COMPTABLE_DEPARTEMENT || e.nom === Constant.ROLE_ASSEMBLE || e.nom === Constant.ROLE_ADMIN))) {
                hasRole = true
              } else if(link.id === 22 && user !== null && (user.roles.some(e => e.nom === Constant.ROLE_RESPONSABLE_ASSEMBLE || e.nom === Constant.ROLE_SMS || e.nom === Constant.ROLE_ASSEMBLE || e.nom === Constant.ROLE_ADMIN))) {
                hasRole = true
              } else if(link.id === 23 && user !== null && (user.roles.some(e => e.nom === Constant.ROLE_RESPONSABLE_ASSEMBLE || e.nom === Constant.ROLE_CULTE || e.nom === Constant.ROLE_ASSEMBLE || e.nom === Constant.ROLE_ADMIN))) {
                hasRole = true
              } else {
                hasRole = false
              }
              return hasRole ? value(link) : null;
        })}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
