import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import ReactDOM from "react-dom";

// import i18next from "i18next";
// import { I18nextProvider } from "react-i18next";
import App from "./components/App";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import './i18n';
import * as serviceWorker from "./serviceWorker";
import Themes from "./themes";

// i18next.init({
//   interpolation: { escapeValue: false },  // React already does escaping
// });

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
            {/* <I18nextProvider i18n={i18next}> */}
              <App />
            {/* </I18nextProvider> */}
        </ThemeProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
