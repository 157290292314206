import {
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
  
  // styles
  import useStyles from "./../../pages/login/styles";
  
  // context
  import { Add, Delete } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { Button as Button2, Card, Col, Divider, Form, Input, Modal, Popover, Row, Select } from "antd";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { withTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Constant } from "../../Constant";
import { useUserDispatch } from "../../context/UserContext";
import Service, { url } from "../../service";
import logo from "./../../assets/logonoir.jpeg";

function Accueil(props) {
    let date = new Date()

    const { t } = props;
  const theme = useTheme();
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [allAssemble, setAllAssemble] = useState({});
  var [data, setData] = useState({});
  const [isModalOpenInformation, setIsModalOpenInformation] = useState(false);
  const [isModalOpenPaiement, setIsModalOpenPaiement] = useState(false);
  var [information, setInformation] = useState("");
  var [recherhce, setRecherche] = useState({});
  var [pagination, setPagination] = useState({page: 0, size: 10, totalElements: 0});
  let search = "";
  const [form] = Form.useForm();
  var [response, setResponse] = useState({});
  var [responseMembre, setResponseMembre] = useState([]);
  var [tableauPaiement, setTableauPaiement] = useState([]);
  var [montantSaisie, setMontantSaisie] = useState(0);
  const [open2, setOpen2] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen2(newOpen);
  };
let formatDateFrançais = "DD/MM/YYYY"
  useEffect(()=>{
    (()=>{
      getAllAssemble("&search=true", 0, 5000)
    })();
  }, [])


  const getAllAssemble = (searchFait, page, size) => {
    setIsLoading(true);
    Service.getAll(Constant.anonyme+"/assemble", page, size, searchFait, false).then(
      async (response) => {
        setAllAssemble(response.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        if(error.response !== undefined) {
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      })
  }

  const onFinish = (values) => {
    setIsLoading(true);
    let searchActivite = `&activite=assemble.id='${values.assembleId}'`
    let searchprojet = `&projet=t.departement.assemble.id='${values.assembleId}'`
    if(values.nom1 !== undefined && values.nom1.trim() !== ""){
      searchActivite = `${searchActivite} AND nom LIKE _1_${values.nom1}_2_`
      searchprojet = `${searchprojet} AND t.projets.nom LIKE _1_${values.nom1}_2_`
    }
    searchActivite = `${searchActivite} ORDER BY datecreation DESC`
    searchprojet = `${searchprojet} ORDER BY t.projets.dateajout DESC`
    let search =  `${searchActivite}${searchprojet}`
    Service.getAll(Constant.anonyme+"/assembleActiviteProjet", 0, 5000, search, false).then(
      async (response) => {
        setResponse(response.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const handleCancel = () => {
    setIsModalOpenInformation(false);
    setIsModalOpenPaiement(false);
  };

  const addAuTableau = (value) => {
    setTableauPaiement(tableauPaiement => [...tableauPaiement, 
        {
            prenom: value.prenom, 
            nom: value.nom, 
            telephone: value.telephone, 
            genre: value.genre,
            montant: data.type === Constant.Projets ? data.data.montant : data.data.fraisparticipation,
            membres: {
             id: value.id !== undefined ? value.id : null
            },
            projets: {
             id: data.type === Constant.Projets ? data.data.id : null
            },
            activite: {
             id: data.type === Constant.Activites ? data.data.id : null
            }
         }
    ])
    form.resetFields();
  };

  const removeAuTableau = (value) => {
    setTableauPaiement(tableauPaiement.filter(item => item !== value))
  };

  const payerMaintenant = (type, data) => {
    setIsModalOpenPaiement(true);
    setData({
        type: type,
        data: data,
    })
  };

  const afficher = async (page, size, genre) => {
    search = "&search="
    if(recherhce.prenom !== undefined && recherhce.prenom.trim() !== ""){
      search = `${search}prenom LIKE _1_${recherhce.prenom}_2_ AND `
    }
    if(recherhce.nom !== undefined && recherhce.nom.trim() !== ""){
      search = `${search}nom LIKE _1_${recherhce.nom}_2_ AND `
    }
    if(recherhce.telephone !== undefined && recherhce.telephone.trim() !== ""){
      search = `${search}telephone LIKE _1_${recherhce.telephone}_2_ AND `
    }
    if(recherhce.pere !== undefined && recherhce.pere.trim() !== ""){
      search = `${search}pere=(SELECT id from Membres WHERE telephone LIKE _1_${recherhce.pere}_2_) AND `
    }
    if(recherhce.mere !== undefined && recherhce.mere.trim() !== ""){
      search = `${search}mere=(SELECT id from Membres WHERE telephone LIKE _1_${recherhce.mere}_2_) AND `
    }
    getAll((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size, genre)
  }


  const getAll = (searchFait, page, size, genre) => {
    setIsLoading(true);
    searchFait = `${searchFait} ORDER BY prenom ASC`
    Service.getAll(Constant.anonyme+"/membres", page, size, searchFait, false).then(
      async (response) => {
        setResponseMembre(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const columnsPaiement = [
    {
      name: "prenom",
      label: t("prenom"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "nom",
      label: t("nomP"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "genre",
      label: t("genre"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "telephone",
      label: t("phone"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "montant",
      label: t("montant"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
            return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " FCFA";
          }
        
       }
    },
    {
        label: t("Action"),
        name: "id",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
            let data = tableMeta.tableData[tableMeta.rowIndex];
            return <div>
                <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {removeAuTableau(data)}}> <Delete /> {t("supprimer")} </IconButton>
            </div>;
            }
        }
    }
  ];

  const columns = [
    {
        label: t("Action"),
        name: "id",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
            let data = tableMeta.tableData[tableMeta.rowIndex];
            return <div>
                <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {addAuTableau(data)}}> <Add /> {t("ajouter")} </IconButton>
            </div>;
            }
        }
    },
    {
      name: "prenom",
      label: t("prenom"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "nom",
      label: t("nomP"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "genre",
      label: t("genre"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "telephone",
      label: t("phone"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "adresse",
      label: t("adresse"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "ethni",
      label: t("ethnie"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "nationalite",
      label: t("Nationalite"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let val = JSON.parse(value);
          return val.label;
        }
       }
    },
    {
      name: "pere",
      label: t("pere"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(value !== null){
          return <div>
              <strong>{value.prenom} {value.nom}</strong>
              <br/>
              <strong>{value.telephone}</strong>
          </div>
          }
          return null;
       }
      }
    },
    {
      name: "mere",
      label: t("mere"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(value !== null){
          return <div>
              <strong>{value.prenom} {value.nom}</strong>
              <br/>
              <strong>{value.telephone}</strong>
          </div>
          }
          return null;
       }
      }
    },
    {
      name: "assemble.nom",
      label: t("newAssemble"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "culte",
      label: t("Culte"),
      options: {
        filter: true,
        sort: true,
      }
    },
  ];

  const inscription = async (choix) => {
    if(tableauPaiement.length === 0 && data.type === Constant.Activites){
        toast.error(t("personneDansTableau"))
    } else{
        let data2 = []
        if(tableauPaiement.length > 0) {
            data2 = tableauPaiement
        } else {
            data2.push({
                    prenom: null, 
                    nom: null, 
                    telephone: null, 
                    genre: null,
                    montant: montantSaisie,
                    membres: {
                     id: null
                    },
                    projets: {
                     id: data.data.id
                    },
                    activite: {
                     id: null
                    }
            })
        }
        setIsLoading(true);
        let d = date.getTime();
        search =  `?datetime=${d}`
          Service.addAll(Constant.anonyme+"/addMembreActviteProjet", search, data2, false).then(
            async (response) => {
              setIsLoading(false);
              if(choix === Constant.paiementWeb) {
                paiementWeb(d)
              } else {
                  toast.success(t(response.data.message))
              }
            }, error => {
              setIsLoading(false);
              if(error.response !== undefined) {
                  toast.error(t(error.response.data.message))
                  if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
                      toast.error(error.response.data.cause.message)
                    }
                } else {
                    toast.error(t("accesRefuse"))
                }
            }
          )

    }
  };
  const paiementWeb = async (time) => {
    setIsLoading(true);
    let body = {
        'mode': Constant.mode,
        'cle': data.data.clepaiement,
        'nom_entreprise': Constant.appname,
        'lien_logo_entreprise': Constant.lien_logo_entreprise,
        'montant': data.type === Constant.Activites ? (tableauPaiement.length * tableauPaiement[0].montant) : montantSaisie,
        'commande_id': time,
        'autre_information': data.type + ' (' + data.data.nom + ')',
        'return_url': window.location.href,
        'cancel_url': window.location.href,
        'success_url': url + Constant.anonyme +'/validerPaiement/'+time,
        'error_url': url + Constant.anonyme +'/validerPaiement/'+time,
      }
      Service.getPaiementLink(body).then(
        (response) => {
          if(response.data.code === "200") {
            window.open(response.data.url, "_self")
          } else {
            toast.error(response.data.message)
          }
          setIsLoading(false);
        }, error => {
            setIsLoading(false);
            if(error.response !== undefined) {
              toast.error(t(error.response.data.message))
              if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
                toast.error(error.response.data.cause.message)
              }
            } else {
              toast.error(t("accesRefuse"))
            }
        })
  };

    return <>
        <div className={classes.logotypeContainer}>
          <img src={logo} alt="logo" className={classes.logotypeImage} />
          <Typography className={classes.logotypeText}>{Constant.appname}</Typography>
          <h2 style={{color: "white", textAlign: 'center'}}>~ {t("jean1721verset")} ~</h2>
          <h1>{t("jean1721")}</h1>
        </div>
        {isLoading && <LinearProgress color="secondary" />}
            <Form
                name="basic4"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                
                onFinish={onFinish}
                autoComplete="off"
              >
                <Row gutter={24} style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: 50}}>
                  <Col className="gutter-row" xxl={7}>
                    <Form.Item
                        label={<label style={{ color: theme.palette.primary.main, fontSize: 20, fontWeight: 'bold' }}>{t("queEstVotreAssemble")}</label>}
                        name="assembleId"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: "100%", zIndex: 1}}
                            options=
                            {
                              allAssemble.length > 0 && allAssemble.map((element) => {
                                return { "value": element.id, "label": `${element.nom}` }
                              })
                            }
                          />
                      </Form.Item>
                  </Col>
                  <Col className="gutter-row" xxl={5}>
                      <Form.Item
                      label={<label style={{ color: theme.palette.primary.main, fontSize: 20, fontWeight: 'bold' }}>{t("nom")}</label>}
                      name="nom1"
                      rules={[
                        {
                          required: false,
                          // message: t("champObligatoire"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xxl={2} style={{marginTop: 30}}>
                      {isLoading ? (
                        <CircularProgress size={26} className={classes.loginLoader} />
                      ) : (
                        <Button2 type="primary" htmlType="submit" style={{marginRight: 10, width: 200}}>
                          {t("afficher")}
                        </Button2>
                      )}
                  </Col>
                </Row>
            </Form>

                {response.activite !== undefined &&
                  <Row gutter={24} style={{marginTop: 50}}>
                    <Col className="gutter-row" lg={3}></Col>
                      <Col className="gutter-row" lg={9}>
                        <Divider style={{color: "red"}}>{t("Activites")}</Divider>
                        {response.activite.map((element, index)=> {
                          return <Card hoverable={true} key={index + "a"} title={element.nom} style={{marginBottom: 10}} extra={<p style={{color: 'blue', cursor: 'pointer'}} onClick={(e)=> {setInformation(element.information); setIsModalOpenInformation(true)}}>{t("information")}</p>}
                            actions={
                              (element.fraisparticipation !== null)
                              ?
                              [
                                <Button2 type="primary" onClick={(e)=> payerMaintenant(Constant.Activites, element)} style={{marginRight: 10, width: 200}}>
                                  {element.fraisparticipation > 0 ? t("payerMaintenant") : t("inscrivezvousMaintenant")}
                                </Button2>,
                              ]
                            :
                              null
                            }
                            >
                            {(element.periodenombre > 0 && element.periode !== null) &&
                              <p style={{textAlign: 'center'}}>
                                <strong>{t("Chaque")} {element.periodenombre} {element.periode}</strong>
                              </p>
                            }
                            <p style={{float: 'left', fontSize: 15}}>
                              <strong style={{fontSize: 20, color: 'green'}}>{t("du")}: </strong> {moment(element.datedebut).format(formatDateFrançais)} 
                            </p>
                            <p style={{float: 'right', fontSize: 15}}>
                              <strong style={{fontSize: 20, color: 'red'}}>{t("au")}: </strong> {moment(element.datefin).format(formatDateFrançais)} 
                            </p>
                            <br/>
                            <br/>
                            <p style={{float: 'left', fontSize: 15}}>
                              <strong style={{fontSize: 20, color: 'green'}}>{t("de")}: </strong> {element.heuredebut} 
                            </p>
                            <p style={{float: 'right', fontSize: 15}}>
                              <strong style={{fontSize: 20, color: 'red'}}>{t("a")}: </strong> {element.heurefin} 
                            </p>
                            <br/>
                            <br/>
                            <p style={{textAlign: 'center'}}>
                              <strong style={{fontSize: 20}}>{t("lieu")}</strong>
                              <br/>
                              {element.lieu}
                            </p>
                            <br/>
                            <p style={{textAlign: 'center', backgroundColor: (element.fraisparticipation !== null && element.fraisparticipation > 0) ? "rgba(255, 0, 0, 0.3)" : "rgba(0, 255, 0, 0.3)", padding: 10}}>
                              {t("fraisParticipation")}: <strong>{(element.fraisparticipation !== null && element.fraisparticipation > 0) ? element.fraisparticipation.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " FCFA" : t("gratuit")}</strong>
                            </p>
                          </Card>
                        })}
                      </Col>
                      <Col className="gutter-row" lg={9}>
                        <Divider style={{color: "red"}}>{t("Projets")}</Divider>
                          {response.projet.map((element, index)=> {
                            return <Card hoverable={true} key={index + "a"} title={element.nom} style={{marginBottom: 10}} extra={<p style={{color: 'blue', cursor: 'pointer'}} onClick={(e)=> {setInformation(element.information); setIsModalOpenInformation(true)}}>{t("information")}</p>}
                              actions={
                                (element.montant !== null && element.montant > 0)
                                ?
                                [
                                  <Button2 type="primary" onClick={(e)=> payerMaintenant(Constant.Projets, element)} style={{marginRight: 10, width: 200}}>
                                    {t("contibuerMaintenant")}
                                  </Button2>,
                                ]
                              :
                                null
                              }
                              >
                              <p style={{float: 'left', fontSize: 15}}>
                                <strong style={{fontSize: 20, color: 'green'}}>{t("du")}: </strong> {moment(element.datedebut).format(formatDateFrançais)} 
                              </p>
                              <p style={{float: 'right', fontSize: 15}}>
                                <strong style={{fontSize: 20, color: 'red'}}>{t("au")}: </strong> {moment(element.datefin).format(formatDateFrançais)} 
                              </p>
                              <br/>
                              <br/>
                              <p style={{float: 'left', fontSize: 15}}>
                                <strong style={{fontSize: 20, color: 'green'}}>{t("de")}: </strong> {element.heuredebut} 
                              </p>
                              <p style={{float: 'right', fontSize: 15}}>
                                <strong style={{fontSize: 20, color: 'red'}}>{t("a")}: </strong> {element.heurefin} 
                              </p>
                              <br/>
                              <br/>
                              {(element.montant !== null && element.montant > 0) &&
                                <p style={{textAlign: 'center', backgroundColor: "rgba(255, 0, 0, 0.3)", padding: 10}}>
                                  {t("montant")}: <strong>{element.montant.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " FCFA"}</strong>
                                </p>
                              }
                            </Card>
                          })}
                      </Col>
                    <Col className="gutter-row" lg={3}></Col>
                  </Row>
                }
{/* Information */}
      <Modal zIndex={1200} width={1000} title={<p style={{borderBottom: "1px solid black"}}>{t("information")}</p>} open={isModalOpenInformation} footer={null} onCancel={handleCancel} >
        <div dangerouslySetInnerHTML={{ __html: information }} />
      </Modal>
{/* Paiement */}
      <Modal zIndex={1200} width="90%" title={<p style={{borderBottom: "1px solid black"}}>{data.type !== undefined && data.type} ({data.data !== undefined && data.data.nom})</p>} open={isModalOpenPaiement} footer={null} onCancel={handleCancel} >
        <Row gutter={24}>
            <Col className="gutter-row" lg={16} style={{borderRight: "2px solid black"}}>
                <Divider style={{color: 'red'}}>Si la personne est inscrite sur {Constant.appname}</Divider>
                <Row gutter={24} style={{marginBottom: 20}}>
                    <Col className="gutter-row" lg={8}>
                        <label>{t("prenom")}</label>
                        <br/>
                        <Input onChange={(e)=> setRecherche({...recherhce, prenom: e.target.value})}/>
                    </Col>
                    <Col className="gutter-row" lg={8}>
                        <label>{t("nom")}</label>
                        <br/>
                        <Input onChange={(e)=> setRecherche({...recherhce, nom: e.target.value})}/>
                    </Col>
                    <Col className="gutter-row" lg={8}>
                        <label>{t("phone")}</label>
                        <br/>
                        <PhoneInput
                            onChange={(e)=> setRecherche({...recherhce, telephone: e})}
                            country={"ml"}
                            inputProps={{
                                name: 'telephone',
                                required: true,
                                autoFocus: true,
                            }}
                            inputStyle={{width: "100%", height:30}}
                        />
                    </Col>
                    <Col className="gutter-row" lg={8}>
                        <label>{t("pere")}</label>
                        <br/>
                        <PhoneInput
                            onChange={(e)=> setRecherche({...recherhce, pere: e})}
                            country={"ml"}
                            inputProps={{
                                name: 'telephone',
                                required: true,
                                autoFocus: true,
                            }}
                            inputStyle={{width: "100%", height:30}}
                        />
                    </Col>
                    <Col className="gutter-row" lg={8}>
                        <label>{t("mere")}</label>
                        <br/>
                        <PhoneInput
                            onChange={(e)=> setRecherche({...recherhce, mere: e})}
                            country={"ml"}
                            inputProps={{
                                name: 'telephone',
                                required: true,
                                autoFocus: true,
                            }}
                            inputStyle={{width: "100%", height:30}}
                        />
                    </Col>
                    <Col className="gutter-row" lg={8}>
                        <br/>
                        <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={(e)=>{afficher(pagination.page, pagination.size)}}
                        >
                            {t("chercher")}
                        </Button>
                    </Col>
                </Row>
                {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
                <MUIDataTable
                    data={responseMembre}
                    columns={columns}
                    options={{
                    draggableColumns: { enabled: true },
                    enableNestedDataAccess: '.',
                    filterType: "multiselect",
                    jumpToPage: true,
                    serverSide: true,
                    rowsPerPageOptions:[10, 50, 100, 500, 1000],
                    rowsPerPage: pagination.size,
                    count: pagination.totalElements,
                    // textLabels: Constant.textLabels,
                    selectableRows: "none",
                    onChangePage(currentPage) {
                        afficher(currentPage, pagination.size)
                    },
                    onChangeRowsPerPage(numberOfRows) {
                        afficher(0, numberOfRows)
                    },
                    }}
                />
            </Col>
            <Col className="gutter-row" lg={8}>
                <Divider style={{color: 'red'}}>Si la personne n'est pas inscrite sur {Constant.appname}</Divider>
                <div style={{marginLeft: 50}}>
                    <Form
                        name="basic3"
                        labelCol={{
                        span: 24,
                        }}
                        wrapperCol={{
                        span: 24,
                        }}
                        initialValues={{
                        remember: true,
                        }}
                        // style={{width: 400}}
                        onFinish={addAuTableau}
                        autoComplete="off"
                        form={form}
                    >
                        <Row gutter={24}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                label={t("prenom")}
                                name="prenom"
                                rules={[
                                    {
                                    required: true,
                                    message: t("champObligatoire"),
                                    },
                                ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                label={t("nomP")}
                                name="nom"
                                rules={[
                                    {
                                    required: true,
                                    message: t("champObligatoire"),
                                    },
                                ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                label={t("genre")}
                                name="genre"
                                rules={[
                                    {
                                    required: true,
                                    message: t("champObligatoire"),
                                    },
                                ]}
                                >
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{ width: "100%", zIndex: 1}}
                                        options={[
                                            { "value": Constant.homme, "label": t(Constant.homme) },
                                            { "value": Constant.femme, "label": t(Constant.femme) },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                label={t("phone")}
                                name="telephone"
                                rules={[
                                    {
                                    required: true,
                                    message: t("champObligatoire"),
                                    },
                                ]}
                                >
                                    <PhoneInput
                                        country={"ml"}
                                        inputProps={{
                                            name: 'telephone',
                                            required: true,
                                            autoFocus: true,
                                        }}
                                        inputStyle={{width: "100%", height:30}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24} style={{marginTop: 30}}>
                                
                                {isLoading ? (
                                    <CircularProgress size={26} className={classes.loginLoader} />
                                ) : (
                                    <Button2 type="primary" htmlType="submit" style={{marginRight: 10, width: 200}}>
                                    {t("ajouter")}
                                    </Button2>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
            <Col className="gutter-row" lg={24}>
            <br/>
            <br/>
                <MUIDataTable
                    title={
                        <>
                            {
                                (tableauPaiement.length > 0 && data.type === Constant.Activites && data.data.fraisparticipation >0)
                                &&
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    onClick={(e)=>{inscription(Constant.paiementWeb)}}
                                >
                                    {t("payerMaintenant")} {`(${(tableauPaiement.length * tableauPaiement[0].montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} FCFA)`}
                                </Button>
                            }
                            {
                                (data.type === Constant.Activites && data.data.fraisparticipation === 0)
                                &&
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    onClick={(e)=>{inscription("")}}
                                >
                                    {t("inscrivezvousMaintenant")}
                                </Button>
                            }
                            {
                                (data.type === Constant.Projets)
                                &&
                                <Popover
                                    content={
                                        <>
                                            <Input type="number" onChange={(e)=> setMontantSaisie(e.target.value)}/>
                                            <br/>
                                            <br/>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                onClick={(e)=>{if(montantSaisie > 0){inscription(Constant.paiementWeb)}}}
                                            >
                                                {t("payerMaintenant")}
                                            </Button>
                                        </>
                                    }
                                    title={t("montant")}
                                    trigger="click"
                                    open={open2}
                                    color='rgba(0, 255, 0, 0.4)'
                                >
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="secondary"
                                        onClick={(e)=> handleOpenChange(true)}
                                    >
                                        {t("contibuerMaintenant")}
                                    </Button>
                                </Popover>
                            }
                        </>
                    }
                    data={tableauPaiement}
                    columns={columnsPaiement}
                    options={{
                        draggableColumns: { enabled: true },
                        enableNestedDataAccess: '.',
                        filterType: "multiselect",
                        jumpToPage: true,
                        serverSide: true,
                        selectableRows: "none",
                        pagination: false
                    }}
                />
            </Col>
        </Row>
      </Modal>

    </>
}
export default withTranslation()(withRouter(Accueil));