import React, { useState } from "react";

// styles
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { CircularProgress } from "@material-ui/core";
import { Button as Button2, Col, Divider, Form, Input, Row } from "antd";
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Constant } from "../../../Constant";
import Service from "../../../service";
import useStyles from "./styles";
import "./tree.css";
dayjs.locale('fr');

function BetiwEnseignement(props) {
  const {t} = props;
  var classes = useStyles();
  let user = JSON.parse(localStorage.getItem('user_info'));
  var [isLoading, setIsLoading] = useState(false);
  let search = "";
  let mlaudio = "";
  let fraudio = "";
  let enaudio = "";

  const [form] = Form.useForm();
  
  const onFinish = (values) => {
    setIsLoading(true)
    if(mlaudio !== "") {
      values.mlaudio = mlaudio
    }
    if(fraudio !== "") {
      values.fraudio = fraudio
    }
    if(enaudio !== "") {
      values.enaudio = enaudio
    }

      Service.addAll(Constant.access+"/betiwEnseignement", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
  };

  const getFileBase64 = async (file, type) => {
    let src = file.url;
        src = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.target.files[0]);
          reader.onload = () => {
            if(type === "mlaudio") {
              mlaudio = reader.result;
            } else if(type === "fraudio") {
              fraudio = reader.result;
            } else if(type === "enaudio") {
              enaudio = reader.result;
            }
          };
        });
  }


  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{width: '50%'}}
      />
      <PageTitle title={t("BetiwEnseignement")} />
      <Row gutter={16} >
        <Col className="gutter-row" span={24}>
          <div style={{backgroundColor: 'rgba(255, 255, 255)', padding: 20, borderRadius: 20, boxShadow: "1px 2px 20px 2px black"}}>
            <Form
                name="basic"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                <Row gutter={24}>
                  
{/* Bambara */}
                {(user !== null && (user.roles.some(e => e.nom === Constant.ROLE_BETIW_ENSEIGNEMENT_ML || e.nom === Constant.ROLE_ADMIN))) &&
                  <Col className="gutter-row" xxl={8} style={{marginBottom: 10}}>
                    <Row>
                      <Divider>{t("Bambara")}</Divider>
                      <Col className="gutter-row" xxl={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("theme")}
                          name="mltheme"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                          >
                          <Input.TextArea/>
                        </Form.Item>
                      </Col>
          {/* Contenu */}
                      <Col className="gutter-row" xxl={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("contenu")}
                          name="mlcontenu"
                          rules={[
                            {
                              required: false,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                         <CKEditor
                            editor={ ClassicEditor }
                            onChange={ ( event, editor ) => {
                              form.setFieldValue("mlcontenu", editor.getData())
                            }}
                        />
                        </Form.Item>
                      </Col>
          {/* audio */}
                      <Col className="gutter-row" xxl={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("audio")}
                          name="mlaudio"
                          rules={[
                            {
                              required: false,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input type="file" accept="audio/mp3,audio/*;capture=microphone" onChange={(e)=> getFileBase64(e, "mlaudio")}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                }
{/* Français */}
                {(user !== null && (user.roles.some(e => e.nom === Constant.ROLE_BETIW_ENSEIGNEMENT_FR || e.nom === Constant.ROLE_ADMIN))) &&
                  <Col className="gutter-row" xxl={8} style={{marginBottom: 10}}>
                    <Row>
                      <Divider>{t("Français")}</Divider>
                      <Col className="gutter-row" xxl={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("theme")}
                          name="frtheme"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                          >
                          <Input.TextArea/>
                        </Form.Item>
                      </Col>
          {/* contenu */}
                      <Col className="gutter-row" xxl={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("contenu")}
                          name="frcontenu"
                          rules={[
                            {
                              required: false,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <CKEditor
                            editor={ ClassicEditor }
                            onChange={ ( event, editor ) => {
                              form.setFieldValue("frcontenu", editor.getData())
                            }}
                        />
                        </Form.Item>
                      </Col>
          {/* audio */}
                      <Col className="gutter-row" xxl={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("audio")}
                          name="fraudio"
                          rules={[
                            {
                              required: false,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input type="file" accept="audio/mp3,audio/*;capture=microphone" onChange={(e)=> getFileBase64(e, "fraudio")}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                }
{/* Anglais */}
                {(user !== null && (user.roles.some(e => e.nom === Constant.ROLE_BETIW_ENSEIGNEMENT_EN || e.nom === Constant.ROLE_ADMIN))) &&
                  <Col className="gutter-row" xxl={8} style={{marginBottom: 10}}>
                    <Row>
                      <Divider>{t("Anglais")}</Divider>
                      <Col className="gutter-row" xxl={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("theme")}
                          name="entheme"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                          >
                          <Input.TextArea/>
                        </Form.Item>
                      </Col>
          {/* contenu */}
                      <Col className="gutter-row" xxl={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("contenu")}
                          name="encontenu"
                          rules={[
                            {
                              required: false,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <CKEditor
                            editor={ ClassicEditor }
                            onChange={ ( event, editor ) => {
                              form.setFieldValue("encontenu", editor.getData())
                            }}
                        />
                        </Form.Item>
                      </Col>
          {/* audio */}
                      <Col className="gutter-row" xxl={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("audio")}
                          name="enaudio"
                          rules={[
                            {
                              required: false,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input type="file" accept="audio/mp3,audio/*;capture=microphone" onChange={(e)=> getFileBase64(e, "enaudio")}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                }
                </Row>
                <br/>
                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  {isLoading === true ? 
                    <CircularProgress size={26} className={classes.loginLoader} />
                  : 
                    <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                      {t("ajouter")}
                    </Button2>
                  }
                </Form.Item>

              </Form>
          </div>
        </Col>
      </Row>

    </div>
  );
}
export default withTranslation()(BetiwEnseignement)