import { Box } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

function Footer(props) {
    return <Box
        mt={5}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent="space-between"
    >
        <div>
        </div>
        <div>
        © 2024-{new Date().getFullYear()} Idrissa Diarra dit Pierre
        </div>
    </Box>
}
export default withTranslation()(withRouter(Footer));