import React, { useEffect, useState } from "react";

// styles
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, CircularProgress, IconButton, LinearProgress } from "@material-ui/core";
import { Check, Delete, Edit, RemoveRedEye } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { Button as Button2, Col, ConfigProvider, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import locale from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import ReactCountryFlagsSelect from "react-country-flags-select";
import { withTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from "react-toastify";
import { Constant } from "../../../Constant";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";
import useStyles from "./styles";
import "./tree.css";

dayjs.locale('fr');


function Mariage(props) {
  const {t} = props;
  var classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenInformation, setIsModalOpenInformation] = useState(false);
  const [visible, setVisible] = useState({
    homme: false,
    femme: false
  });
  var [information, setInformation] = useState("");
  const [value, setValue] = useState()
  var [isLoading, setIsLoading] = useState(false);
  var [response, setResponse] = useState([]);
  var [allAssemble, setAllAssemble] = useState([]);
  var [forUpdate, setForUpdate] = useState({});
  var [parent, setParent] = useState({});
  var [orgChart, setOrgChart] = useState({});
  var [recherhce, setRecherche] = useState({});
  const theme = useTheme();
  var [treeParam, setTreeParam] = useState({
    orientation: "vertical",
    chemin: "diagonal",
    espace: 2,
  });
  var [pagination, setPagination] = useState({page: 0, size: 50, totalElements: 0});
  let search = "";
  const { RangePicker } = DatePicker;
  let formatDateFrançais = "DD/MM/YYYY"
  let formatDateAnglais = "YYYY-MM-DD"


  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpenInformation(false);
  };

  useEffect(()=>{
    (()=>{
      getAllAssemble("&search=true", 0, 5000)
      afficher(pagination.page, pagination.size)
    })();
  }, [])


  const getAllAssemble = (searchFait, page, size) => {
    setIsLoading(true);
    Service.getAll(Constant.access+"/assemble", page, size, searchFait, true).then(
      async (response) => {
        setAllAssemble(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const afficher = async (page, size, genre) => {
    search = "&search="
// Pour l'homme ou la femme
    if(genre !== undefined && genre !== null){
      if(recherhce.prenom !== undefined && recherhce.prenom.trim() !== ""){
        search = `${search}prenom LIKE _1_${recherhce.prenom}_2_ AND `
      }
      if(recherhce.nom !== undefined && recherhce.nom.trim() !== ""){
        search = `${search}nom LIKE _1_${recherhce.nom}_2_ AND `
      }
      search = `${search}genre LIKE _1_${genre}_2_ AND `
      if(forUpdate.id !== undefined) {
        search = `${search}id!='${forUpdate.id}' AND `
      }
      if(recherhce.telephone !== undefined && recherhce.telephone.trim() !== ""){
        search = `${search}telephone LIKE _1_${recherhce.telephone}_2_ AND `
      }
    }
// Pour le mariage
    else {
      if(recherhce.assemble !== undefined && recherhce.assemble.trim() !== ""){
        search = `${search}t.assemble.id='${recherhce.assemble}' AND `
      }
      if(recherhce.prenomHomme !== undefined && recherhce.prenomHomme.trim() !== ""){
        search = `${search}homme.prenom LIKE _1_${recherhce.prenomHomme}_2_ AND `
      }
      if(recherhce.prenomFemme !== undefined && recherhce.prenomFemme.trim() !== ""){
        search = `${search}femme.prenom LIKE _1_${recherhce.prenomFemme}_2_ AND `
      }
      if(recherhce.nomHomme !== undefined && recherhce.nomHomme.trim() !== ""){
        search = `${search}homme.nom LIKE _1_${recherhce.nomHomme}_2_ AND `
      }
      if(recherhce.nomFemme !== undefined && recherhce.nomFemme.trim() !== ""){
        search = `${search}femme.nom LIKE _1_${recherhce.nomFemme}_2_ AND `
      }
      if(recherhce.datenaissanceHomme !== undefined && recherhce.datenaissanceHomme.length > 0 && recherhce.datenaissanceHomme[1].trim() !== ""){
        let debut  = moment(recherhce.datenaissanceHomme[0], formatDateFrançais).format(formatDateAnglais);
        let fin  = moment(recherhce.datenaissanceHomme[1], formatDateFrançais).format(formatDateAnglais);
        search = `${search}homme.date_naissance BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
      }
      if(recherhce.datenaissanceFemme !== undefined && recherhce.datenaissanceFemme.length > 0 && recherhce.datenaissanceFemme[1].trim() !== ""){
        let debut  = moment(recherhce.datenaissanceFemme[0], formatDateFrançais).format(formatDateAnglais);
        let fin  = moment(recherhce.datenaissanceFemme[1], formatDateFrançais).format(formatDateAnglais);
        search = `${search}femme.date_naissance BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
      }
      if(recherhce.lieu_naissanceHomme !== undefined && recherhce.lieu_naissanceHomme.trim() !== ""){
        search = `${search}homme.lieu_naissance LIKE _1_${recherhce.lieu_naissanceHomme}_2_ AND `
      }
      if(recherhce.lieu_naissanceFemme !== undefined && recherhce.lieu_naissanceFemme.trim() !== ""){
        search = `${search}femme.lieu_naissance LIKE _1_${recherhce.lieu_naissanceFemme}_2_ AND `
      }
      if(recherhce.telephoneHomme !== undefined && recherhce.telephoneHomme.trim() !== ""){
        search = `${search}homme.telephone LIKE _1_${recherhce.telephoneHomme}_2_ AND `
      }
      if(recherhce.telephoneFemme !== undefined && recherhce.telephoneFemme.trim() !== ""){
        search = `${search}femme.telephone LIKE _1_${recherhce.telephoneFemme}_2_ AND `
      }
      if(recherhce.adresseHomme !== undefined && recherhce.adresseHomme.trim() !== ""){
        search = `${search}homme.adresse LIKE _1_${recherhce.adresseHomme}_2_ AND `
      }
      if(recherhce.adresseFemme !== undefined && recherhce.adresseFemme.trim() !== ""){
        search = `${search}femme.adresse LIKE _1_${recherhce.adresseFemme}_2_ AND `
      }
      if(recherhce.ethniHomme !== undefined && recherhce.ethniHomme.trim() !== ""){
        search = `${search}homme.ethni LIKE _1_${recherhce.ethniHomme}_2_ AND `
      }
      if(recherhce.ethniFemme !== undefined && recherhce.ethniFemme.trim() !== ""){
        search = `${search}femme.ethni LIKE _1_${recherhce.ethniFemme}_2_ AND `
      }
      if(recherhce.nationaliteHomme !== undefined && recherhce.nationaliteHomme.trim() !== ""){
        search = `${search}homme.nationalite LIKE _1_${recherhce.nationaliteHomme}_2_ AND `
      }
      if(recherhce.nationaliteFemme !== undefined && recherhce.nationaliteFemme.trim() !== ""){
        search = `${search}femme.nationalite LIKE _1_${recherhce.nationaliteFemme}_2_ AND `
      }
      if(recherhce.professionHomme !== undefined && recherhce.professionHomme.trim() !== ""){
        search = `${search}homme.profession LIKE _1_${recherhce.professionHomme}_2_ AND `
      }
      if(recherhce.professionFemme !== undefined && recherhce.professionFemme.trim() !== ""){
        search = `${search}femme.profession LIKE _1_${recherhce.professionFemme}_2_ AND `
      }
      if(recherhce.pereHomme !== undefined && recherhce.pereHomme.trim() !== ""){
        search = `${search}homme.pere='${recherhce.pereHomme}' AND `
      }
      if(recherhce.pereFemme !== undefined && recherhce.pereFemme.trim() !== ""){
        search = `${search}femme.pere='${recherhce.pereFemme}' AND `
      }
      if(recherhce.mereHomme !== undefined && recherhce.mereHomme.trim() !== ""){
        search = `${search}homme.mere='${recherhce.mereHomme}' AND `
      }
      if(recherhce.mereFemme !== undefined && recherhce.mereFemme.trim() !== ""){
        search = `${search}femme.mere='${recherhce.mereFemme}' AND `
      }
      if(recherhce.assembleHomme !== undefined && recherhce.assembleHomme.trim() !== ""){
        search = `${search}homme.assemble.id='${recherhce.assembleHomme}' AND `
      }
      if(recherhce.assembleFemme !== undefined && recherhce.assembleFemme.trim() !== ""){
        search = `${search}femme.assemble.id='${recherhce.assembleFemme}' AND `
      }
      if(recherhce.culteHomme !== undefined && recherhce.culteHomme.trim() !== ""){
        search = `${search}homme.culte LIKE _1_${recherhce.culteHomme}_2_ AND `
      }
      if(recherhce.culteFemme !== undefined && recherhce.culteFemme.trim() !== ""){
        search = `${search}femme.culte LIKE _1_${recherhce.culteFemme}_2_ AND `
      }
      if(recherhce.date_conversionHomme !== undefined && recherhce.date_conversionHomme.length > 0 && recherhce.date_conversionHomme[1].trim() !== ""){
        let debut  = moment(recherhce.date_conversionHomme[0], formatDateFrançais).format(formatDateAnglais);
        let fin  = moment(recherhce.date_conversionHomme[1], formatDateFrançais).format(formatDateAnglais);
        search = `${search}homme.date_conversion BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
      }
      if(recherhce.date_conversionFemme !== undefined && recherhce.date_conversionFemme.length > 0 && recherhce.date_conversionFemme[1].trim() !== ""){
        let debut  = moment(recherhce.date_conversionFemme[0], formatDateFrançais).format(formatDateAnglais);
        let fin  = moment(recherhce.date_conversionFemme[1], formatDateFrançais).format(formatDateAnglais);
        search = `${search}femme.date_conversion BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
      }
      if(recherhce.lieu_conversionHomme !== undefined && recherhce.lieu_conversionHomme.trim() !== ""){
        search = `${search}homme.lieu_conversion LIKE _1_${recherhce.lieu_conversionHomme}_2_ AND `
      }
      if(recherhce.lieu_conversionFemme !== undefined && recherhce.lieu_conversionFemme.trim() !== ""){
        search = `${search}femme.lieu_conversion LIKE _1_${recherhce.lieu_conversionFemme}_2_ AND `
      }
      if(recherhce.date_baptemeHomme !== undefined && recherhce.date_baptemeHomme.length > 0 && recherhce.date_baptemeHomme[1].trim() !== ""){
        let debut  = moment(recherhce.date_baptemeHomme[0], formatDateFrançais).format(formatDateAnglais);
        let fin  = moment(recherhce.date_baptemeHomme[1], formatDateFrançais).format(formatDateAnglais);
        search = `${search}homme.date_bapteme BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
      }
      if(recherhce.date_baptemeFemme !== undefined && recherhce.date_baptemeFemme.length > 0 && recherhce.date_baptemeFemme[1].trim() !== ""){
        let debut  = moment(recherhce.date_baptemeFemme[0], formatDateFrançais).format(formatDateAnglais);
        let fin  = moment(recherhce.date_baptemeFemme[1], formatDateFrançais).format(formatDateAnglais);
        search = `${search}femme.date_bapteme BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
      }
      if(recherhce.lieu_baptemeHomme !== undefined && recherhce.lieu_baptemeHomme.trim() !== ""){
        search = `${search}homme.lieu_bapteme LIKE _1_${recherhce.lieu_baptemeHomme}_2_ AND `
      }
      if(recherhce.lieu_baptemeFemme !== undefined && recherhce.lieu_baptemeFemme.trim() !== ""){
        search = `${search}femme.lieu_bapteme LIKE _1_${recherhce.lieu_baptemeFemme}_2_ AND `
      }
      if(recherhce.roledansegliseHomme !== undefined && recherhce.roledansegliseHomme.trim() !== ""){
        search = `${search}homme.roledanseglise LIKE _1_${recherhce.roledansegliseHomme}_2_ AND `
      }
      if(recherhce.roledansegliseFemme !== undefined && recherhce.roledansegliseFemme.trim() !== ""){
        search = `${search}femme.roledanseglise LIKE _1_${recherhce.roledansegliseFemme}_2_ AND `
      }
      if(recherhce.statut_actuelleHomme !== undefined && recherhce.statut_actuelleHomme.trim() !== ""){
        search = `${search}homme.statut_actuelle LIKE _1_${recherhce.statut_actuelleHomme}_2_ AND `
      }
      if(recherhce.statut_actuelleFemme !== undefined && recherhce.statut_actuelleFemme.trim() !== ""){
        search = `${search}femme.statut_actuelle LIKE _1_${recherhce.statut_actuelleFemme}_2_ AND `
      }
      if(recherhce.informationHomme !== undefined && recherhce.informationHomme.trim() !== ""){
        search = `${search}homme.information LIKE _1_${recherhce.informationHomme}_2_ AND `
      }
      if(recherhce.informationFemme !== undefined && recherhce.informationFemme.trim() !== ""){
        search = `${search}femme.information LIKE _1_${recherhce.informationFemme}_2_ AND `
      }
      if(recherhce.activeHomme !== undefined && recherhce.activeHomme.trim() !== ""){
        search = `${search}homme.active=${recherhce.activeHomme} AND `
      }
      if(recherhce.activeFemme !== undefined && recherhce.activeFemme.trim() !== ""){
        search = `${search}femme.active=${recherhce.activeFemme} AND `
      }
      if(recherhce.datemariage !== undefined && recherhce.datemariage.length > 0 && recherhce.datemariage[1].trim() !== ""){
        let debut  = moment(recherhce.datemariage[0], formatDateFrançais).format(formatDateAnglais);
        let fin  = moment(recherhce.datemariage[1], formatDateFrançais).format(formatDateAnglais);
        search = `${search}datemariage BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
      }
      if(recherhce.Dateajout !== undefined && recherhce.Dateajout.length > 0 && recherhce.Dateajout[1].trim() !== ""){
        let debut  = moment(recherhce.Dateajout[0], formatDateFrançais).format(formatDateAnglais);
        let fin  = moment(recherhce.Dateajout[1], formatDateFrançais).format(formatDateAnglais);
        search = `${search}Dateajout BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
      }
    }
    getAll((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size, genre)
  }

  const getAll = (searchFait, page, size, genre) => {
    setIsLoading(true);
    if(genre !== undefined && genre !== null) {
      Service.getAll(Constant.access+"/membres", page, size, searchFait, true).then(
        async (response) => {
            setParent({
              type: genre,
              data: response.data.data
            })
          setIsLoading(false);
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        })
    } else {
      searchFait = `${searchFait} ORDER BY datemariage DESC`
      Service.getAll(Constant.access+"/mariage", page, size, searchFait, true).then(
        async (response) => {
            setPagination({
              page: page,
              size: size,
              totalElements: response.data.totalElements
            })
            setResponse(response.data.data)
          setIsLoading(false);
        }, error => {
          console.log(error);

          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        })
    }
  }

  const onFinish = (values) => {
    setIsLoading(true);
    values.homme = {
      id: values.homme
    }
    values.femme = {
      id: values.femme
    }
    search = `?assembleId=${values.assembleId}`;
    if(forUpdate.id === undefined) {
      Service.addAll(Constant.access+"/mariage", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    } else {
      values.id = forUpdate.id
      Service.putAll(Constant.access+"/mariage", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          handleCancel()
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    }
  };

  const columnsParent = [
    {
      name: "prenom",
      label: t("prenom"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "nom",
      label: t("nomP"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "telephone",
      label: t("phone"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      label: t("Action"),
      name: "id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
            <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {ValiderParent(data)}}>  <span><Check /> {t("activer")}</span> </IconButton>
          </div>;
        }
      }
    }
  ];

  const columns = [
    {
      name: "homme.prenom",
      label: <span>{`${t("prenom")}`} <strong style={{color: "green"}}>({t("Homme")})</strong></span>,
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "homme.nom",
      label: <span>{`${t("nomP")}`} <strong style={{color: "green"}}>({t("Homme")})</strong></span>,
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "homme.date_naissance",
      label: <span>{`${t("datenaissance")}`} <strong style={{color: "green"}}>({t("Homme")})</strong></span>,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      name: "homme.assemble.nom",
      label: <span>{`${t("provenance")}`} <strong style={{color: "green"}}>({t("Homme")})</strong></span>,
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "femme.prenom",
      label: <span>{`${t("prenom")}`} <strong style={{color: "red"}}>({t("Femme")})</strong></span>,
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "femme.nom",
      label: <span>{`${t("nomP")}`} <strong style={{color: "red"}}>({t("Femme")})</strong></span>,
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "femme.date_naissance",
      label: <span>{`${t("datenaissance")}`} <strong style={{color: "red"}}>({t("Femme")})</strong></span>,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      name: "femme.assemble.nom",
      label: <span>{`${t("provenance")}`} <strong style={{color: "red"}}>({t("Femme")})</strong></span>,
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "assemble.nom",
      label: t("Assemblees"),
      options: {
        filter: false,
        sort: false,
       }
    },
    {
      name: "information",
      label: t("information"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <IconButton style={{color: 'blue', fontSize: 15}} onClick={(e)=>{setInformation(value); setIsModalOpenInformation(true)}}> <RemoveRedEye /> Voir </IconButton>;
        }
       }
    },
    {
      name: "datemariage",
      label: t("datemariage"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      name: "dateajout",
      label: t("dateajout"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      label: t("Action"),
      name: "id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
            <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm(t("suppressionConfirm")))deleteAction(value)}}> <Delete /> {t("supprimer")} </IconButton>
            <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifierAction(data)}}> <Edit /> {t("modifier")} </IconButton>
          </div>;
        }
      }
    }
  ];

  const deleteAction = (id) => {
    setIsLoading(true);
    Service.deleteAll(Constant.access+"/mariage", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const modifierAction = (data) => {
    setForUpdate(data)
    form.setFields([
      {name: "datemariage", value: dayjs(moment(forUpdate.datemariage).format(formatDateFrançais), formatDateFrançais)},
      {name: "homme", value: data.homme.id},
      {name: "femme", value: data.femme.id},
      {name: "assembleId", value: data.assemble.id},
      {name: "information", value: data.information},
    ])
    showModal()
  }

  const searchParent = (genre) =>{
    setParent({})
    afficher(pagination.page, pagination.size, genre)
  }

  const ValiderParent = (parent) =>{
    if(parent.genre === Constant.homme) {
      form.setFieldValue("homme", parent.id)
    } else {
      form.setFieldValue("femme", parent.id)
    }
    setParent({})
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{width: '50%'}}
      />
      <PageTitle title={t("Mariages")} button={
        <div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={(e)=>{setForUpdate({}); showModal()}}
          >
              {t("newMariages")}
          </Button>
        </div>
      }/>

        <div>
{/* Pour l'homme */}
          <h3>{t("Homme")} <Button style={{backgroundColor: "green", color: 'white'}} onClick={(e)=> setVisible({...visible, homme: !visible.homme})}>{!visible.homme ? t("afficher") : t("cacher")}</Button></h3>
            {visible.homme &&
              <Row gutter={16} style={{marginBottom: 20, backgroundColor: 'rgba(0, 255, 0, 0.3)', padding: 10}}>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("prenom")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, prenomHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("nom")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, nomHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("datenaissance")}</label>
                    <br/>
                    <ConfigProvider locale={locale}>
                      <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, datenaissanceHomme: datesString})} placeholder={[t("du"), t("au")]}/>
                    </ConfigProvider>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("lieunaissance")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, lieu_naissanceHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("phone")}</label>
                    <br/>
                    <PhoneInput
                        value={value}
                        onChange={(e)=> setRecherche({...recherhce, telephoneHomme: e})}
                        country={"ml"}
                        inputProps={{
                            name: 'telephone',
                            required: true,
                            autoFocus: true,
                          }}
                          inputStyle={{width: "100%", height:30}}
                    />
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("adresse")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, adresseHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("ethnie")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, ethniHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("Nationalite")}</label>
                    <br/>
                    <ReactCountryFlagsSelect fullWidth searchable 
                          searchPlaceholder=""
                            onSelect={(e)=> {
                              if(e !== null) {
                                setRecherche({...recherhce, nationaliteHomme: e.label})
                              }
                            }} 
                          />
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("Profession")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, professionHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("pere")} ID</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, pereHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("mere")} ID</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, mereHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("Assemblees")}</label>
                    <br/>
                      <Select
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: "100%", minWidth: 200, zIndex: 1}}
                            onChange={(e)=>setRecherche({...recherhce, assembleHomme: e})}
                            options=
                            {
                              allAssemble.length > 0 && allAssemble.map((element) => {
                                return { "value": element.id, "label": `${element.nom}` }
                              })
                            }
                          />
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("Culte")}</label>
                    <br/>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, culteHomme: e})}
                        options={[
                          { "value": Constant.Bambara, "label": t(Constant.Bambara) },
                          { "value": Constant.Français, "label": t(Constant.Français) },
                          { "value": Constant.Anglais, "label": t(Constant.Anglais) },
                          { "value": Constant.Bilingue, "label": t(Constant.Bilingue) },
                        ]}
                      />
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("dateconversion")}</label>
                    <br/>
                    <ConfigProvider locale={locale}>
                      <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, date_conversionHomme: datesString})} placeholder={[t("du"), t("au")]}/>
                    </ConfigProvider>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("lieuconversion")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, lieu_conversionHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("datebapteme")}</label>
                    <br/>
                    <ConfigProvider locale={locale}>
                      <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, date_baptemeHomme: datesString})} placeholder={[t("du"), t("au")]}/>
                    </ConfigProvider>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("lieubapteme")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, lieu_baptemeHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("roleEglise")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, roledansegliseHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("statutactuelle")}</label>
                    <br/>
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", minWidth: 200, zIndex: 1}}
                      onChange={(e)=>setRecherche({...recherhce, statut_actuelleHomme: e})}
                      options={[
                        { "value": Constant.Present, "label": t(Constant.Present) },
                        { "value": Constant.deces, "label": t(Constant.deces) },
                        { "value": Constant.depart, "label": t(Constant.depart) },
                        { "value": Constant.Autre, "label": t(Constant.Autre) },
                      ]}
                    />
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("information")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, informationHomme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("Statut")}</label>
                    <br/>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: "100%", minWidth: 200, zIndex: 1}}
                            onChange={(e)=>setRecherche({...recherhce, activeHomme: e})}
                            options={[
                              { "value": "", "label":  t("tout")},
                              { "value": "true", "label":  t("active")},
                              { "value": "false", "label":  t("desactive")},
                            ]}
                          />
                  </Col>
              </Row>
            }
{/* Pour la femme */}
          <h3>{t("Femme")} <Button style={{backgroundColor: "red", color: 'white'}} onClick={(e)=> setVisible({...visible, femme: !visible.femme})}>{!visible.femme ? t("afficher") : t("cacher")}</Button></h3>
            {visible.femme &&
              <Row gutter={16} style={{marginBottom: 20, backgroundColor: 'rgba(255, 0, 0, 0.3)', padding: 10}}>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("prenom")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, prenomFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("nom")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, nomFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("datenaissance")}</label>
                    <br/>
                    <ConfigProvider locale={locale}>
                      <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, datenaissanceFemme: datesString})} placeholder={[t("du"), t("au")]}/>
                    </ConfigProvider>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("lieunaissance")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, lieu_naissanceFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("phone")}</label>
                    <br/>
                    <PhoneInput
                        value={value}
                        onChange={(e)=> setRecherche({...recherhce, telephoneFemme: e})}
                        country={"ml"}
                        inputProps={{
                            name: 'telephone',
                            required: true,
                            autoFocus: true,
                          }}
                          inputStyle={{width: "100%", height:30}}
                    />
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("adresse")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, adresseFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("ethnie")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, ethniFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("Nationalite")}</label>
                    <br/>
                    <ReactCountryFlagsSelect fullWidth searchable 
                          searchPlaceholder=""
                            onSelect={(e)=> {
                              if(e !== null) {
                                setRecherche({...recherhce, nationaliteFemme: e.label})
                              }
                            }} 
                          />
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("Profession")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, professionFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("pere")} ID</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, pereFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("mere")} ID</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, mereFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("Assemblees")}</label>
                    <br/>
                      <Select
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: "100%", minWidth: 200, zIndex: 1}}
                            onChange={(e)=>setRecherche({...recherhce, assembleFemme: e})}
                            options=
                            {
                              allAssemble.length > 0 && allAssemble.map((element) => {
                                return { "value": element.id, "label": `${element.nom}` }
                              })
                            }
                          />
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("Culte")}</label>
                    <br/>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, culteFemme: e})}
                        options={[
                          { "value": Constant.Bambara, "label": t(Constant.Bambara) },
                          { "value": Constant.Français, "label": t(Constant.Français) },
                          { "value": Constant.Anglais, "label": t(Constant.Anglais) },
                          { "value": Constant.Bilingue, "label": t(Constant.Bilingue) },
                        ]}
                      />
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("dateconversion")}</label>
                    <br/>
                    <ConfigProvider locale={locale}>
                      <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, date_conversionFemme: datesString})} placeholder={[t("du"), t("au")]}/>
                    </ConfigProvider>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("lieuconversion")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, lieu_conversionFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("datebapteme")}</label>
                    <br/>
                    <ConfigProvider locale={locale}>
                      <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, date_baptemeFemme: datesString})} placeholder={[t("du"), t("au")]}/>
                    </ConfigProvider>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("lieubapteme")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, lieu_baptemeFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("roleEglise")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, roledansegliseFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("statutactuelle")}</label>
                    <br/>
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", minWidth: 200, zIndex: 1}}
                      onChange={(e)=>setRecherche({...recherhce, statut_actuelleFemme: e})}
                      options={[
                        { "value": Constant.Present, "label": t(Constant.Present) },
                        { "value": Constant.deces, "label": t(Constant.deces) },
                        { "value": Constant.depart, "label": t(Constant.depart) },
                        { "value": Constant.Autre, "label": t(Constant.Autre) },
                      ]}
                    />
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("information")}</label>
                    <br/>
                    <Input onChange={(e)=> setRecherche({...recherhce, informationFemme: e.target.value})}/>
                  </Col>
                  <Col className="gutter-row" xxl={4}>
                    <label>{t("Statut")}</label>
                    <br/>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: "100%", minWidth: 200, zIndex: 1}}
                            onChange={(e)=>setRecherche({...recherhce, activeFemme: e})}
                            options={[
                              { "value": "", "label":  t("tout")},
                              { "value": "true", "label":  t("active")},
                              { "value": "false", "label":  t("desactive")},
                            ]}
                          />
                  </Col>
              </Row>
            }
{/* Pour homme et la femme */}
          <Row gutter={16} style={{marginBottom: 20}}>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Assemblees")}</label>
                <br/>
                  <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, assemble: e})}
                        options=
                        {
                          allAssemble.length > 0 && allAssemble.map((element) => {
                            return { "value": element.id, "label": `${element.nom}` }
                          })
                        }
                      />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("datemariage")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, datemariage: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("dateajout")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, Dateajout: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <br/>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={(e)=>{afficher(pagination.page, pagination.size)}}
                >
                    {t("chercher")}
                </Button>
              </Col>
          </Row>
          {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title={<h2>{`${t("total")}: ${pagination.totalElements}`}</h2>}
            data={response}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: true,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: pagination.size,
              count: pagination.totalElements,
              // textLabels: Constant.textLabels,
              selectableRows: "none",
              onChangePage(currentPage) {
                afficher(currentPage, pagination.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                afficher(0, numberOfRows)
              },
            }}
          />
        </div>
        
{/* Information */}
      <Modal zIndex={1200} width={1000} title={<p style={{borderBottom: "1px solid black"}}>{t("information")}</p>} open={isModalOpenInformation} footer={null} onCancel={handleCancel} >
        <div dangerouslySetInnerHTML={{ __html: information }} />
      </Modal>

{/* Mariage */}
      <Modal width={"50%"} zIndex={1200} title={<p style={{borderBottom: "1px solid black"}}>{t("newMariages")}</p>} open={isModalOpen} footer={null} onCancel={handleCancel} >
        <div>
        <Form
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
                <Row gutter={16}>
            {/* Homme */}
                  <Col className="gutter-row" xxl={12}>
                      <Form.Item
                        label={<span>{t("Homme")} {(form.getFieldValue("homme") !== undefined && form.getFieldValue("homme") !== null) && <Check style={{color: 'green'}}/>}</span>}
                        name="homme"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <div style={{backgroundColor: "rgba(0, 0, 0, 0.3)", padding: 10}}>
                          <Row gutter={16}>
                            <Col className="gutter-row" lg={12}>
                              <label>{t("prenom")}</label>
                              <br/>
                              <Input onChange={(e)=> setRecherche({...recherhce, prenom: e.target.value})}/>
                            </Col>
                            <Col className="gutter-row" lg={12}>
                              <label>{t("nom")}</label>
                              <br/>
                              <Input onChange={(e)=> setRecherche({...recherhce, nom: e.target.value})}/>
                            </Col>
                            <Col className="gutter-row" lg={12}>
                              <label>{t("phone")}</label>
                              <br/>
                              <PhoneInput
                                  value={value}
                                  onChange={(e)=> setRecherche({...recherhce, telephone: e})}
                                  country={"ml"}
                                  inputProps={{
                                      name: 'telephone',
                                      required: true,
                                      autoFocus: true,
                                    }}
                                    inputStyle={{width: "100%", height:30}}
                              />
                            </Col>
                            <Col className="gutter-row" lg={12}>
                              <Button2 onClick={(e)=> {searchParent(Constant.homme)}} style={{backgroundColor: 'green', color: 'white', marginTop: 20}}>
                                {t("chercher")}
                              </Button2>
                            </Col>
                            {parent.type !== undefined && parent.type === Constant.homme &&
                              <>
                                <hr/>
                                <Col className="gutter-row" lg={24}>
                                  <MUIDataTable
                                      data={parent.data}
                                      columns={columnsParent}
                                      options={{
                                        draggableColumns: { enabled: true },
                                        enableNestedDataAccess: '.',
                                        filterType: "multiselect",
                                        jumpToPage: true,
                                        pagination: false,
                                        // textLabels: Constant.textLabels,
                                        selectableRows: "none",
                                      }}
                                    />
                                </Col>
                              </>
                            }
                          </Row>
                        </div>
                      </Form.Item>
                      
                  </Col>
              {/* Femme */}
                  <Col className="gutter-row" xxl={12}>
                    <Form.Item
                        label={<span>{t("Femme")} {(form.getFieldValue("femme") !== undefined && form.getFieldValue("femme") !== null)  && <Check style={{color: 'green'}}/>}</span>}
                        name="femme"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <div style={{backgroundColor: "rgba(0, 0, 0, 0.3)", padding: 10}}>
                          <Row gutter={16}>
                            <Col className="gutter-row" lg={12}>
                              <label>{t("prenom")}</label>
                              <br/>
                              <Input onChange={(e)=> setRecherche({...recherhce, prenom: e.target.value})}/>
                            </Col>
                            <Col className="gutter-row" lg={12}>
                              <label>{t("nom")}</label>
                              <br/>
                              <Input onChange={(e)=> setRecherche({...recherhce, nom: e.target.value})}/>
                            </Col>
                            <Col className="gutter-row" lg={12}>
                              <label>{t("phone")}</label>
                              <br/>
                              <PhoneInput
                                  value={value}
                                  onChange={(e)=> setRecherche({...recherhce, telephone: e})}
                                  country={"ml"}
                                  inputProps={{
                                      name: 'telephone',
                                      required: true,
                                      autoFocus: true,
                                    }}
                                    inputStyle={{width: "100%", height:30}}
                              />
                            </Col>
                            <Col className="gutter-row" lg={12}>
                              <Button2 onClick={(e)=> {searchParent(Constant.femme)}} style={{backgroundColor: 'green', color: 'white', marginTop: 20}}>
                                {t("chercher")}
                              </Button2>
                            </Col>
                            {parent.type !== undefined && parent.type === Constant.femme &&
                              <>
                                <hr/>
                                <Col className="gutter-row" span={24}>
                                  <MUIDataTable
                                      data={parent.data}
                                      columns={columnsParent}
                                      options={{
                                        draggableColumns: { enabled: true },
                                        enableNestedDataAccess: '.',
                                        filterType: "multiselect",
                                        jumpToPage: true,
                                        pagination: false,
                                        // textLabels: Constant.textLabels,
                                        selectableRows: "none",
                                      }}
                                    />
                                </Col>
                              </>
                            }
                          </Row>
                        </div>
                      </Form.Item>
                  </Col>
                  <Col className="gutter-row" xxl={12} style={{marginTop: 20}}>
                      <Form.Item
                        label={t("Assemblees")}
                        name="assembleId"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: "100%", zIndex: 1}}
                            options=
                            {
                              allAssemble.length > 0 && allAssemble.map((element) => {
                                return { "value": element.id, "label": `${element.nom}` }
                              })
                            }
                          />
                      </Form.Item>
                  </Col>
                  <Col className="gutter-row" xxl={12} style={{marginTop: 20}}>
                      <Form.Item
                            label={t("datemariage")}
                            name="datemariage"
                            rules={[
                              {
                                type: 'object',
                                required: true,
                                message: t("champObligatoire"),
                              },
                            ]}
                            >
                            <ConfigProvider locale={locale}>
                                <DatePicker style={{width: '100%'}}
                                  defaultValue={forUpdate.datemariage !== undefined && dayjs(moment(forUpdate.datemariage).format(formatDateFrançais), formatDateFrançais)}
                                  format={formatDateFrançais}
                                  placeholder=""
                                  onChange={ (date, dateString) => {
                                    form.setFieldValue("datemariage", date)
                                  }}
                                />
                            </ConfigProvider>
                      </Form.Item>
                  </Col>
                  <Col className="gutter-row" xxl={24} style={{marginTop: 20}}>
                      <Form.Item
                        label={t("information")}
                        name="information"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                      >
                        <CKEditor
                            editor={ ClassicEditor }
                            data={(forUpdate.information !== null && forUpdate.information !== undefined) ? forUpdate.information : ""}
                            onChange={ ( event, editor ) => {
                              form.setFieldValue("information", editor.getData())
                            }}
                        />
                      </Form.Item>
                  </Col>
                </Row>
              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 16,
                }}
              >
                <div style={{marginTop: 20}}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                      {forUpdate.id !== undefined ? t("modifier") : t("ajouter")}
                    </Button2>
                  )}
                  <Button2 onClick={handleCancel} type="primary" danger>
                    {t("annuler")}
                  </Button2>
                </div>
              </Form.Item>

            </Form>
        </div>
      </Modal>
    </div>
  );
}
export default withTranslation()(Mariage)