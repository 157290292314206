import React, { useEffect, useState } from "react";

// styles
import { Button, CircularProgress, LinearProgress } from "@material-ui/core";
import { Button as Button2, Col, ConfigProvider, DatePicker, Divider, Form, Input, Row, Select, TimePicker } from "antd";
import locale from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import { withTranslation } from "react-i18next";
import PageTitle from "../../../components/PageTitle/PageTitle";
import useStyles from "./styles";

import { useTheme } from "@material-ui/styles";
import 'dayjs/locale/fr';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from "react-toastify";
import { Constant } from "../../../Constant";
import Service from "../../../service";
import "./tree.css";

dayjs.locale('fr');


function Message(props) {
  const {t} = props;
  var classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenInformation, setIsModalOpenInformation] = useState(false);
  var [information, setInformation] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var [response, setResponse] = useState([]);
  var [allDepartement, setDepartement] = useState([]);
  var [allAssemble, setAllAssemble] = useState([]);
  var [allActivite, setAllActivite] = useState([]);
  var [allProjet, setAllProjet] = useState([]);
  var [forUpdate, setForUpdate] = useState({});
  var [dateEtHeure, setDateEtHeure] = useState({});
  var [orgChart, setOrgChart] = useState({});
  var [recherhce, setRecherche] = useState({});
  const theme = useTheme();
  var [treeParam, setTreeParam] = useState({
    orientation: "vertical",
    chemin: "diagonal",
    espace: 2,
  });
  var [pagination, setPagination] = useState({page: 0, size: 50, totalElements: 0});
  let search = "";
  const { RangePicker } = DatePicker;
  let formatDateFrançais = "DD/MM/YYYY"
  let formatDateFrançaisAvecHeure = "DD/MM/YYYY HH:mm:ss"
  let formatDateAnglais = "YYYY-MM-DD"


  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpenInformation(false);
  };

  useEffect(()=>{
    (()=>{
      afficher(pagination.page, pagination.size)
      getDepartement("&search=true", 0, 5000)
      getAllAssemble("&search=true", 0, 5000)
      getAllActivite("&search=true", 0, 5000)
      getAllProjet("&search=true", 0, 5000)
    })();
  }, [])

  const getAllProjet = (searchFait, page, size) => {
    Service.getAll(Constant.access+"/projet", page, size, searchFait, true).then(
      async (response) => {
        setAllProjet(response.data.data)
      })
  }

  const getAllActivite = (searchFait, page, size) => {
    Service.getAll(Constant.access+"/activites", page, size, searchFait, true).then(
      async (response) => {
        setAllActivite(response.data.data)
      })
  }

  const getDepartement = (searchFait, page, size) => {
    Service.getAll(Constant.access+"/departements", page, size, searchFait, true).then(
      async (response) => {
        setDepartement(response.data.data)
      })
  }

  const getAllAssemble = (searchFait, page, size) => {
    Service.getAll(Constant.access+"/assemble", page, size, searchFait, true).then(
      async (response) => {
        setAllAssemble(response.data.data)
      })
  }

  const afficher = async (page, size) => {
    search = "&search="
    if(recherhce.clesms !== undefined && recherhce.clesms.trim() !== ""){
      search = `${search}t.clesms LIKE _1_${recherhce.clesms}_2_ AND `
    }
    if(recherhce.expediteursms !== undefined && recherhce.expediteursms.trim() !== ""){
      search = `${search}t.expediteursms LIKE _1_${recherhce.expediteursms}_2_ AND `
    }
    if(recherhce.messageconfirmation !== undefined && recherhce.messageconfirmation.trim() !== ""){
      search = `${search}t.messageconfirmation LIKE _1_${recherhce.messageconfirmation}_2_ AND `
    }
    if(recherhce.assemblees !== undefined && recherhce.assemblees.trim() !== ""){
      search = `${search}t.assemblees LIKE _1_${recherhce.assemblees}_2_ AND `
    }
    if(recherhce.departements !== undefined && recherhce.departements.trim() !== ""){
      search = `${search}t.departements LIKE _1_${recherhce.departements}_2_ AND `
    }
    if(recherhce.activites !== undefined && recherhce.activites.trim() !== ""){
      search = `${search}t.activites LIKE _1_${recherhce.activites}_2_ AND `
    }
    if(recherhce.projets !== undefined && recherhce.projets.trim() !== ""){
      search = `${search}t.projets LIKE _1_${recherhce.projets}_2_ AND `
    }
    if(recherhce.dateajout !== undefined && recherhce.dateajout.length > 0 && recherhce.dateajout[1].trim() !== ""){
      let debut  = moment(recherhce.dateajout[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.dateajout[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}t.dateajout BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    if(recherhce.heureajout !== undefined){
      search = `${search}t.heureajout=CAST('${recherhce.heureajout}' as TIME) AND `
    }
    getAll((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size)
  }

  const getAll = (searchFait, page, size) => {
    setIsLoading(true);
    searchFait = `${searchFait} ORDER BY t.dateajout,t.heureajout DESC`
    Service.getAll(Constant.access+"/SendSmsDepAssProjActivite", page, size, searchFait, true).then(
      async (response) => {
        setPagination({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setResponse(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const onFinish = (values) => {
    setIsLoading(true);
    search =  `?assemblees=${(values.assemblees && values.assemblees.length > 0) !== undefined ? values.assemblees : []}
    &departements=${(values.departements && values.departements.length > 0) !== undefined ? values.departements : []}
    &activites=${(values.activites && values.activites.length > 0) !== undefined ? values.activites : []}
    &projets=${(values.projets && values.projets.length > 0) !== undefined ? values.projets : []}`
      Service.addAll(Constant.access+"/SendSmsDepAssProjActivite", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          // form.resetFields();
          // afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
  };

  const columns = [
    {
      name: "clesms",
      label: t("clesSms"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "expediteursms",
      label: t("expediteursms"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "messageconfirmation",
      label: t("SMS"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "assemblees",
      label: t("Assemblees"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = value.split('"').join("").split('[').join("").split(']').join("").split(',')
          return <div>
            {data.length > 0 && data.map((element, index)=>{
              return <p key={index+"1"} style={{backgroundColor:"rgba(0, 255, 0, 0.1)", marginBottom: 5}}>{element.trim()}</p>
            })}
          </div>
        }
       }
    },
    {
      name: "departements",
      label: t("Departements"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = value.split('"').join("").split('[').join("").split(']').join("").split(',')
          return <div>
            {data.length > 0 && data.map((element, index)=>{
              return <p key={index+"2"} style={{backgroundColor:"rgba(0, 255, 0, 0.1)", marginBottom: 5}}>{element.trim()}</p>
            })}
          </div>
        }
       }
    },
    {
      name: "activites",
      label: t("Activites"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = value.split('"').join("").split('[').join("").split(']').join("").split(',')
          return <div>
            {data.length > 0 && data.map((element, index)=>{
              return <p key={index+"3"} style={{backgroundColor:"rgba(0, 255, 0, 0.1)", marginBottom: 5}}>{element.trim()}</p>
            })}
          </div>
        }
       }
    },
    {
      name: "projets",
      label: t("Projets"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = value.split('"').join("").split('[').join("").split(']').join("").split(',')
          return <div>
            {data.length > 0 && data.map((element, index)=>{
              return <p key={index+"4"} style={{backgroundColor:"rgba(0, 255, 0, 0.1)", marginBottom: 5}}>{element.trim()}</p>
            })}
          </div>
        }
       }
    },
    {
      name: "dateajout",
      label: t("dateajout"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      name: "heureAjout",
      label: t("heureAjout"),
      options: {
        filter: true,
        sort: true,
       }
    },
  ];


  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{width: '50%'}}
      />
      <PageTitle title={t("SMS")} />
      <Row gutter={16} >
        <Col className="gutter-row" span={8}>
          <div style={{backgroundColor: 'rgba(255, 255, 255)', padding: 20, borderRadius: 20, boxShadow: "1px 2px 20px 2px black"}}>
            <Form
                name="basic"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                <Row gutter={24}>
                  <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                    <Form.Item
                      label={t("clesSms")}
                      name="clesms"
                      rules={[
                        {
                          required: true,
                          message: t("champObligatoire"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                      <Form.Item
                      label={t("expediteursms")}
                      name="expediteursms"
                      rules={[
                        {
                          required: true,
                          message: t("champObligatoire"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("SMS")}
                          name="messageconfirmation"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                          >
                          <Input.TextArea maxLength={155}/>
                        </Form.Item>
                  </Col>
                  <Divider>{t("destinateur")}</Divider>
                  <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                    <Form.Item
                      label={t("Assemblees")}
                      name="assemblees"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                          mode="multiple"
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options=
                          {
                            allAssemble.length > 0 && allAssemble.map((element) => {
                              return { "value": element.id, "label": `${element.nom}` }
                            })
                          }
                        />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                    <Form.Item
                      label={t("Departements")}
                      name="departements"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                          mode="multiple"
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options=
                          {
                            allDepartement.length > 0 && allDepartement.map((element) => {
                              return { "value": element.id, "label": `${element.nom}` }
                            })
                          }
                        />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                    <Form.Item
                      label={t("Activites")}
                      name="activites"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                          mode="multiple"
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options=
                          {
                            allActivite.length > 0 && allActivite.map((element) => {
                              return { "value": element.id, "label": `${element.nom}` }
                            })
                          }
                        />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                    <Form.Item
                      label={t("Projets")}
                      name="projets"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                          mode="multiple"
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options=
                          {
                            allProjet.length > 0 && allProjet.map((element) => {
                              return { "value": element.id, "label": `${element.nom}` }
                            })
                          }
                        />
                    </Form.Item>
                  </Col>
                </Row>
                <br/>
                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                      {t("send")}
                    </Button2>
                  )}
                </Form.Item>

              </Form>
          </div>
        </Col>
        <Col className="gutter-row" span={16}>
          <div>
            <Row gutter={16} style={{marginBottom: 20}}>
                <Col className="gutter-row" xxl={6}>
                  <label>{t("clesSms")}</label>
                  <br/>
                  <Input onChange={(e)=> setRecherche({...recherhce, clesms: e.target.value})}/>
                </Col>
                <Col className="gutter-row" xxl={6}>
                  <label>{t("expediteursms")}</label>
                  <br/>
                  <Input onChange={(e)=> setRecherche({...recherhce, expediteursms: e.target.value})}/>
                </Col>
                <Col className="gutter-row" xxl={6}>
                  <label>{t("SMS")}</label>
                  <br/>
                  <Input onChange={(e)=> setRecherche({...recherhce, messageconfirmation: e.target.value})}/>
                </Col>
                <Col className="gutter-row" xxl={6}>
                  <label>{t("Assemblees")}</label>
                  <br/>
                  <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, assemblees: e})}
                        options=
                        {
                          allAssemble.length > 0 && allAssemble.map((element) => {
                            return { "value": element.nom, "label": `${element.nom}` }
                          })
                        }
                      />
                </Col>
                <Col className="gutter-row" xxl={6}>
                  <label>{t("Departements")}</label>
                  <br/>
                  <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, departements: e})}
                        options=
                        {
                          allDepartement.length > 0 && allDepartement.map((element) => {
                            return { "value": element.nom, "label": `${element.nom}` }
                          })
                        }
                      />
                </Col>
                <Col className="gutter-row" xxl={6}>
                  <label>{t("Activites")}</label>
                  <br/>
                  <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, activites: e})}
                        options=
                        {
                          allActivite.length > 0 && allActivite.map((element) => {
                            return { "value": element.nom, "label": `${element.nom}` }
                          })
                        }
                      />
                </Col>
                <Col className="gutter-row" xxl={6}>
                  <label>{t("Projets")}</label>
                  <br/>
                  <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, projets: e})}
                        options=
                        {
                          allProjet.length > 0 && allProjet.map((element) => {
                            return { "value": element.nom, "label": `${element.nom}` }
                          })
                        }
                      />
                </Col>
                <Col className="gutter-row" xxl={6}>
                  <label>{t("dateajout")}</label>
                  <br/>
                  <ConfigProvider locale={locale}>
                    <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, dateajout: datesString})} placeholder={[t("du"), t("au")]}/>
                  </ConfigProvider>
                </Col>
                <Col className="gutter-row" xxl={6}>
                  <label>{t("heureAjout")}</label>
                  <br/>
                  <TimePicker style={{width: "100%"}} onChange={(time, timeString)=> setRecherche({...recherhce, heureajout: timeString})}/>
                </Col>
                <Col className="gutter-row" xxl={4}>
                  <br/>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={(e)=>{afficher(pagination.page, pagination.size)}}
                  >
                      {t("chercher")}
                  </Button>
                </Col>
            </Row>
            {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
            <MUIDataTable
              title={<h2>{`${t("total")}: ${pagination.totalElements}`}</h2>}
              data={response}
              columns={columns}
              options={{
                draggableColumns: { enabled: true },
                enableNestedDataAccess: '.',
                filterType: "multiselect",
                jumpToPage: true,
                serverSide: true,
                rowsPerPageOptions:[50, 100, 500, 1000],
                rowsPerPage: pagination.size,
                count: pagination.totalElements,
                // textLabels: Constant.textLabels,
                selectableRows: "none",
                onChangePage(currentPage) {
                  afficher(currentPage, pagination.size)
                },
                onChangeRowsPerPage(numberOfRows) {
                  afficher(0, numberOfRows)
                },
              }}
            />
          </div>
        </Col>
      </Row>

    </div>
  );
}
export default withTranslation()(Message)