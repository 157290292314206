import React from "react";
import { withRouter } from "react-router-dom";

// styles

// context
import { withTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Layout/Footer";
import Accueil from "../../components/Layout/accueil";
function Login(props) {
  return (
    <>
      <Header />
      <Accueil />
      <div style={{padding:20}}><Footer /></div>
    </>
  );
}

export default withTranslation()(withRouter(Login));
