import axios from 'axios';

export const header = {'Authorization': `Basic ${localStorage.getItem('id_token') !== null ? localStorage.getItem('id_token') : ""}`};


export const url = process.env.REACT_APP_BASE_URL
class Service {
      constructor() {}
       
       static inscription(data) {
        return axios.post(url + '/anonyme/inscription', data).catch(function (error) {
          if(error.response === undefined || error.response.status === 403 || error.response.status === 401) {
            return axios.post(url + '/users/inscription', data);
          }
        });
      }

      static connexion(token) {
        const HEADERS = {'Authorization' : `Basic ${token}`};
        return axios.get(url + '/access/login', {headers: HEADERS});
      }

      static modifierMotDePasse(path, data, token) {
        const HEADERS = {'Authorization' : `Basic ${token}`};
        return axios.post(url + path, data, {headers: HEADERS});
      }

      static getAll(path, page, size, search, autorisation) {
        return axios.get(url + path + `?page=${page}&size=${size}` + search, {headers: autorisation ? header : null});
      }

      static changeStatutAll(path, id, autorisation) {
        return axios.get(url + path + `?id=` + id, {headers: autorisation ? header : null});
      }

      static deleteAll(path, id, autorisation) {
        return axios.delete(url + path + `?id=` + id, {headers: autorisation ? header : null});
      }

      static addAll(path, search, data, autorisation) {
        return axios.post(url + path + search, data, {headers: autorisation ? header : null});
      }

      static allStatistique(path, search, data, autorisation) {
        return axios.post(url + path + search, data, {headers: autorisation ? header : null});
      }

      static putAll(path, search, data, autorisation) {
        return axios.put(url + path + search, data, {headers: autorisation ? header : null});
      }


      static getPaiementLink(data) {
        return axios.post('https://api.sevob.net', data);
      }

}

export default Service;