import React, { useEffect, useState } from "react";

// styles
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, CircularProgress, IconButton, LinearProgress } from "@material-ui/core";
import { Button as Button2, Col, ConfigProvider, DatePicker, Form, Input, Modal, Row, Select, Space, TimePicker } from "antd";
import locale from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import { withTranslation } from "react-i18next";
import PageTitle from "../../../components/PageTitle/PageTitle";
import useStyles from "./styles";

import { Delete, Edit, Group, RemoveRedEye, Search } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import 'dayjs/locale/fr';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from "react-toastify";
import { Constant } from "../../../Constant";
import Service from "../../../service";
import "./tree.css";

dayjs.locale('fr');


function Activite(props) {
  const {t} = props;
  var classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenInformation, setIsModalOpenInformation] = useState(false);
  const [isModalOpenMembres, setIsModalOpenMembres] = useState(false);
  var [information, setInformation] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var [response, setResponse] = useState([]);
  var [allAssemble, setAllAssemble] = useState([]);
  var [forUpdate, setForUpdate] = useState({});
  var [dateEtHeure, setDateEtHeure] = useState({});
  var [membreData, setMembreData] = useState({});
  var [responseMembre, setResponseMembre] = useState([]);
  var [paginationMembre, setPaginationMembre] = useState({page: 0, size: 50, totalElements: 0});
  var [recherhce, setRecherche] = useState({});
  const theme = useTheme();
  var [membreRecherche, setMembreRecherche] = useState({});
  var [pagination, setPagination] = useState({page: 0, size: 50, totalElements: 0});
  let search = "";
  const { RangePicker } = DatePicker;
  let formatDateFrançais = "DD/MM/YYYY"
  let formatDateFrançaisAvecHeure = "DD/MM/YYYY HH:mm:ss"
  let formatDateAnglais = "YYYY-MM-DD"


  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpenInformation(false);
    setIsModalOpenMembres(false);
  };

  useEffect(()=>{
    (()=>{
      afficher(pagination.page, pagination.size)
      getAllAssemble("&search=true", 0, 5000)
    })();
  }, [])

  const getAllAssemble = (searchFait, page, size) => {
    setIsLoading(true);
    Service.getAll(Constant.access+"/assemble", page, size, searchFait, true).then(
      async (response) => {
        setAllAssemble(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const afficher = async (page, size) => {
    search = "&search="
    if(recherhce.nom !== undefined && recherhce.nom.trim() !== ""){
      search = `${search}nom LIKE _1_${recherhce.nom}_2_ AND `
    }
    if(recherhce.lieu !== undefined && recherhce.lieu.trim() !== ""){
      search = `${search}lieu LIKE _1_${recherhce.lieu}_2_ AND `
    }
    if(recherhce.periode !== undefined && recherhce.periode.trim() !== ""){
      search = `${search}periode LIKE _1_${recherhce.periode}_2_ AND `
    }
    if(recherhce.periodenombre !== undefined && recherhce.periodenombre.trim() !== ""){
      search = `${search}periodenombre=${recherhce.periodenombre} AND `
    }
    if(recherhce.heuredebut !== undefined){
      search = `${search}heuredebut=CAST('${recherhce.heuredebut}' as TIME) AND `
    }
    if(recherhce.heurefin !== undefined){
      search = `${search}heurefin=CAST('${recherhce.heurefin}' as TIME) AND `
    }
    if(recherhce.assemble !== undefined && recherhce.assemble.trim() !== ""){
      search = `${search}assemble.id='${recherhce.assemble}' AND `
    }
    if(recherhce.datecreation !== undefined && recherhce.datecreation.length > 0 && recherhce.datecreation[1].trim() !== ""){
      let debut  = moment(recherhce.datecreation[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.datecreation[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}datecreation BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    if(recherhce.datedebut !== undefined && recherhce.datedebut.length > 0 && recherhce.datedebut[1].trim() !== ""){
      let debut  = moment(recherhce.datedebut[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.datedebut[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}datedebut BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    if(recherhce.datefin !== undefined && recherhce.datefin.length > 0 && recherhce.datefin[1].trim() !== ""){
      let debut  = moment(recherhce.datefin[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.datefin[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}datefin BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    getAll((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size)
  }

  const getAll = (searchFait, page, size) => {
    setIsLoading(true);
    searchFait = `${searchFait} ORDER BY datecreation DESC`
    Service.getAll(Constant.access+"/activites", page, size, searchFait, true).then(
      async (response) => {
        setPagination({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setResponse(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const onFinish = (values) => {
    values.datedebut =  dateEtHeure.datedebut
    values.heuredebut =  dateEtHeure.heuredebut
    values.heurefin =  dateEtHeure.heurefin
    setIsLoading(true);
    search =  `?assembleId=${values.assembleId}`
    if(forUpdate.id === undefined) {
      Service.addAll(Constant.access+"/activites", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          setDateEtHeure({})
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    } else {
      values.id = forUpdate.id

      Service.putAll(Constant.access+"/activites", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          setDateEtHeure({})
          handleCancel()
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    }
  };

  const columns = [
    {
      name: "nom",
      label: t("nom"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "lieu",
      label: t("lieu"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "periodenombre",
      label: t("periodeRepetion"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "periode",
      label: t("periode"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "datedebut",
      label: t("datedebut"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      name: "heuredebut",
      label: t("heuredebut"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "datefin",
      label: t("datefin"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      name: "heurefin",
      label: t("heurefin"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "assemble.nom",
      label: t("Assemblees"),
      options: {
        filter: false,
        sort: false,
       }
    },
    {
      name: "information",
      label: t("information"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <IconButton style={{color: 'blue', fontSize: 15}} onClick={(e)=>{setInformation(value); setIsModalOpenInformation(true)}}> <RemoveRedEye /> Voir </IconButton>;
        }
       }
    },
    {
      name: "fraisparticipation",
      label: t("fraisParticipation"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value!== null ? value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " FCFA": null
        }
       }
    },
    {
      name: "clepaiement",
      label: t("clesPaiement"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "clesms",
      label: t("clesSms"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "expediteur",
      label: t("expediteursms"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "messageconfirmation",
      label: t("messageConfirmation"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "datecreation",
      label: t("dateajout"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      label: t("Action"),
      name: "id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
            <IconButton style={{color: 'blue', fontSize: 15}} onClick={(e)=> {membreAction(data)}}> <Group /> {t("Membres")} </IconButton>
            <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm(t("suppressionConfirm")))deleteAction(value)}}> <Delete /> {t("supprimer")} </IconButton>
            <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifierAction(data)}}> <Edit /> {t("modifier")} </IconButton>
          </div>;
        }
      }
    }
  ];

  const membreAction = (data) => {
    setMembreData(data)
    setIsModalOpenMembres(true)
  }

  const deleteAction = (id) => {
    setIsLoading(true);
    Service.deleteAll(Constant.access+"/activites", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const modifierAction = (data) => {
    let values = data;
    values.dateTime = [
      dayjs(moment(data.datedebut + ` ${data.heuredebut}`).format(formatDateFrançaisAvecHeure), formatDateFrançaisAvecHeure),
      dayjs(moment(data.datefin + ` ${data.heurefin}`).format(formatDateFrançaisAvecHeure), formatDateFrançaisAvecHeure)
    ]
    setForUpdate(values)
    setDateEtHeure({
      ...dateEtHeure,
      datedebut: data.datedebut,
      heuredebut: data.heuredebut,
      heurefin: data.heurefin,
    })
    form.setFields([
      {name: "nom", value: data.nom},
      {name: "lieu", value: data.lieu},
      {name: "datefin", value: dayjs(moment(data.datefin).format(formatDateFrançais), formatDateFrançais)},
      {name: "periodenombre", value: data.periodenombre},
      {name: "periode", value: data.periode},
      {name: "assembleId", value: data.assemble.id},
      {name: "information", value: data.information},
      {name: "fraisparticipation", value: data.fraisparticipation},
      {name: "clepaiement", value: data.clepaiement},
      {name: "clesms", value: data.clesms},
      {name: "expediteur", value: data.expediteur},
      {name: "messageconfirmation", value: data.messageconfirmation},
    ])
    showModal()
  }

  const dateChoose = (dates, dateStrings) => {
    if (dates) {
      setDateEtHeure({
        datedebut: dates[0],
        heuredebut: new Date(dates[0]).toLocaleTimeString(),
        heurefin: new Date(dates[1]).toLocaleTimeString()
      })
      form.setFieldValue("datefin", dates[1])
    }
  }


  const afficherMembres = async (page, size) => {
    let search = "&search="
    if(membreRecherche.prenom !== undefined && membreRecherche.prenom.trim() !== ""){
      search = `${search}prenom LIKE _1_${membreRecherche.prenom}_2_ AND `
    }
    if(membreRecherche.nom !== undefined && membreRecherche.nom.trim() !== ""){
      search = `${search}nom LIKE _1_${membreRecherche.nom}_2_ AND `
    }
    if(membreRecherche.genre !== undefined && membreRecherche.genre.trim() !== ""){
      search = `${search}genre LIKE _1_${membreRecherche.genre}_2_ AND `
    }
    if(membreRecherche.telephone !== undefined && membreRecherche.telephone.trim() !== ""){
      search = `${search}telephone LIKE _1_${membreRecherche.telephone}_2_ AND `
    }
    if(membreRecherche.dateajout !== undefined && membreRecherche.dateajout.length > 0 && membreRecherche.dateajout[1].trim() !== ""){
      let debut  = moment(membreRecherche.dateajout[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(membreRecherche.dateajout[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}dateajout BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    search = `${search}activite.id= '${membreData.id}' AND payer= 'Validé' AND `
    getAllMembres((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size)
  }


  const getAllMembres = (searchFait, page, size) => {
    setIsLoading(true);
    searchFait = `${searchFait} ORDER BY dateajout,heureajout DESC`
    Service.getAll(Constant.access+"/PaiementMembres", page, size, searchFait, true).then(
      async (response) => {
          setPaginationMembre({
            page: page,
            size: size,
            totalElements: response.data.totalElements
          })
          setResponseMembre(response.data.data)
          setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }


  const columns2 = [
    {
      name: "montant",
      label: t("montant"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value!== null ? value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " FCFA": null
        }
       }
    },
    {
      name: "prenom",
      label: t("prenom"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "nom",
      label: t("nomP"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "genre",
      label: t("genre"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "telephone",
      label: t("phone"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "membres",
      label: t("Membres"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value !== null ? t("oui") : t("non")
        }
       }
    },
    {
      name: "dateAjout",
      label: t("dateajout"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      name: "heureajout",
      label: t("heureAjout"),
      options: {
        filter: true,
        sort: true,
       }
    },
  ];

  const calcul = () => {
    let d = 0
    responseMembre.forEach((element, index) => {
      d = d + element.montant
      if((index + 1) === responseMembre.length) {
        return d
      }
    });
    return d
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{width: '50%'}}
      />
      <PageTitle title={t("Activites")} button={
        <div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={(e)=>{setForUpdate({}); showModal()}}
          >
              {t("newActivites")}
          </Button>
        </div>
      }/>

        <div>
          <Row gutter={16} style={{marginBottom: 20}}>
              <Col className="gutter-row" xxl={4}>
                <label>{t("nom")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, nom: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("lieu")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, lieu: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("datedebut")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, datedebut: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("heuredebut")}</label>
                <br/>
                  <TimePicker style={{width: "100%"}} onChange={(time, timeString)=> setRecherche({...recherhce, heuredebut: timeString})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("datefin")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, datefin: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("heurefin")}</label>
                <br/>
                <TimePicker style={{width: "100%"}} onChange={(time, timeString)=> setRecherche({...recherhce, heurefin: timeString})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("periode")}</label>
                <br/>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", minWidth: 200, zIndex: 1}}
                    onChange={(e)=>setRecherche({...recherhce, periode: e})}
                    options={[
                      { "value": Constant.Jours, "label": t(Constant.Jours) },
                      { "value": Constant.Semaines, "label": t(Constant.Semaines) },
                      { "value": Constant.Mois, "label": t(Constant.Mois) },
                      { "value": Constant.Années, "label": t(Constant.Années) }
                    ]}
                  />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("periodeRepetion")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, periodenombre: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Assemblees")}</label>
                <br/>
                  <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, assemble: e})}
                        options=
                        {
                          allAssemble.length > 0 && allAssemble.map((element) => {
                            return { "value": element.id, "label": `${element.nom}` }
                          })
                        }
                      />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("dateajout")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, datecreation: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <br/>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={(e)=>{afficher(pagination.page, pagination.size)}}
                >
                    {t("chercher")}
                </Button>
              </Col>
          </Row>
          {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title={<h2>{`${t("total")}: ${pagination.totalElements}`}</h2>}
            data={response}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: true,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: pagination.size,
              count: pagination.totalElements,
              // textLabels: Constant.textLabels,
              selectableRows: "none",
              onChangePage(currentPage) {
                afficher(currentPage, pagination.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                afficher(0, numberOfRows)
              },
            }}
          />
        </div>
{/* Information */}
      <Modal zIndex={1200} width={1000} title={<p style={{borderBottom: "1px solid black"}}>{t("information")}</p>} open={isModalOpenInformation} footer={null} onCancel={handleCancel} >
        <div dangerouslySetInnerHTML={{ __html: information }} />
      </Modal>

{/* Activité */}
      <Modal zIndex={1200} width={1000} title={<p style={{borderBottom: "1px solid black"}}>{t("newActivites")}</p>} open={isModalOpen} footer={null} onCancel={handleCancel} >
        <div>
          <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              // style={{
              //   maxWidth: 600,
              // }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Row gutter={24}>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                    label={t("nom")}
                    name="nom"
                    rules={[
                      {
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                    label={t("lieu")}
                    name="lieu"
                    rules={[
                      {
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <br/>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                  <Form.Item
                    label={t("dateTime")}
                    name="datefin"
                    rules={[
                      {
                        type: 'object',
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                    >
                    <ConfigProvider locale={locale}>
                      <RangePicker defaultValue={forUpdate.dateTime !== undefined ? forUpdate.dateTime : null} showTime format={formatDateFrançaisAvecHeure} onChange={dateChoose} placeholder={[t("du"), t("au")]}/>
                    </ConfigProvider>
                  </Form.Item>
                </Col>
                <br/>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                  <Form.Item label={t("Chaque")}>
                    <Space.Compact>
                      <Form.Item
                        name="periodenombre"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input type="number" placeholder={t("periodeRepetion")}/>
                      </Form.Item>
                      <br/>
                      <Form.Item
                        name="periode"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            style={{ width: 150, zIndex: 1}}
                            placeholder={t("periode")}
                            options=
                            {
                              [
                                { "value": Constant.Jours, "label": t(Constant.Jours) },
                                { "value": Constant.Semaines, "label": t(Constant.Semaines) },
                                { "value": Constant.Mois, "label": t(Constant.Mois) },
                                { "value": Constant.Années, "label": t(Constant.Années) }
                              ]
                            }
                          />
                      </Form.Item>
                    </Space.Compact>
                  </Form.Item>
                </Col>
                <br/>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                  <Form.Item
                      label={t("Assemblees")}
                      name="assembleId"
                      rules={[
                        {
                          required: true,
                          message: t("champObligatoire"),
                        },
                      ]}
                    >
                      <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options=
                          {
                            allAssemble.length > 0 && allAssemble.map((element) => {
                              return { "value": element.id, "label": `${element.nom}` }
                            })
                          }
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={24} style={{marginBottom: 10, backgroundColor: "rgba(0, 255, 0, 0.3)"}}>
                  <h4 style={{textAlign: 'center'}}>{t("fraisParticipation")}</h4>
                  <Row gutter={24}>
                    <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                        <Form.Item
                        label={t("montant")}
                        name="fraisparticipation"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                        >
                        <Input type="number"/>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                        <Form.Item
                        label={t("clesPaiement")}
                        name="clepaiement"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                        labelCol={{
                          span: 16,
                        }}
                        wrapperCol={{
                          span: 8,
                        }}
                        >
                        <Input/>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col className="gutter-row" span={24} style={{marginBottom: 10, backgroundColor: "rgba(0, 255, 0, 0.3)"}}>
                  <h4 style={{textAlign: 'center'}}>{t("messageConfirmation")}</h4>
                  <Row gutter={24}>
                    <Col className="gutter-row" span={8} style={{marginBottom: 10}}>
                        <Form.Item
                        label={t("clesSms")}
                        name="clesms"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        >
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8} style={{marginBottom: 10}}>
                        <Form.Item
                          label={t("expediteursms")}
                          name="expediteur"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                          labelCol={{
                            span: 24,
                          }}
                          wrapperCol={{
                            span: 24,
                          }}
                        >
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8} style={{marginBottom: 10}}>
                        <Form.Item
                        label={t("SMS")}
                        name="messageconfirmation"
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                        >
                        <Input.TextArea maxLength={155}/>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Form.Item
                label={t("information")}
                name="information"
                rules={[
                  {
                    required: false,
                  },
                ]}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
              >
                <CKEditor
                    editor={ ClassicEditor }
                    data={(forUpdate.information !== null && forUpdate.information !== undefined) ? forUpdate.information : ""}
                    onChange={ ( event, editor ) => {
                      form.setFieldValue("information", editor.getData())
                    }}
                />
              </Form.Item>
              <br/>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                    {forUpdate.id !== undefined ? t("modifier") : t("ajouter")}
                  </Button2>
                )}
                <Button2 onClick={handleCancel} type="primary" danger>
                  {t("annuler")}
                </Button2>
              </Form.Item>

            </Form>
        </div>
      </Modal>

{/* Membres */}
      <Modal zIndex={1200} width={"80%"} title={<p style={{borderBottom: "1px solid black"}}>{`${t("Membres")} (${membreData.nom})`}</p>} open={isModalOpenMembres} footer={null} onCancel={handleCancel} >
          <div>
            <Row gutter={16} style={{marginBottom: 20}}>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("prenom")}</label>
                  <br/>
                  <Input onChange={(e)=> setMembreRecherche({...recherhce, membreRecherche: e.target.value})}/>
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("nom")}</label>
                  <br/>
                  <Input onChange={(e)=> setMembreRecherche({...membreRecherche, nom: e.target.value})}/>
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("genre")}</label>
                  <br/>
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1, minWidth: 100}}
                      onChange={(e)=>setMembreRecherche({...membreRecherche, genre: e})}
                      options={[
                        { "value": Constant.homme, "label": t(Constant.homme) },
                        { "value": Constant.femme, "label": t(Constant.femme) },
                      ]}
                    />
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("phone")}</label>
                  <br/>
                  <PhoneInput
                      onChange={(e)=> setMembreRecherche({...membreRecherche, telephone: e})}
                      country={"ml"}
                      inputProps={{
                          name: 'telephone',
                          required: true,
                          autoFocus: true,
                        }}
                        inputStyle={{width: "100%", minWidth: 100, height:30}}
                  />
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("dateajout")}</label>
                  <br/>
                  <ConfigProvider locale={locale}>
                    <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setMembreRecherche({...membreRecherche, dateajout: datesString})} placeholder={[t("du"), t("au")]}/>
                  </ConfigProvider>
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <Button2 onClick={(e)=>{afficherMembres(paginationMembre.page, paginationMembre.size)}} style={{backgroundColor: 'rgba(0,0,255, 0.6)', color: 'white', marginTop: 20, fontSize: 15}}>
                    <Search style={{height: 20, width: 20}}/> {t("afficher")}
                  </Button2>
                </Col>
              </Row>

              {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
              <MUIDataTable
                title={
                  <>
                    <h2>{`${t("total")}: ${paginationMembre.totalElements}`}</h2>
                    <h2>{t("montant")}: {(responseMembre.length > 0) && calcul().toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} FCFA</h2>
                  </>
                }
                data={responseMembre}
                columns={columns2}
                options={{
                  draggableColumns: { enabled: true },
                  enableNestedDataAccess: '.',
                  filterType: "multiselect",
                  selectableRows: "none",
                  jumpToPage: true,
                  serverSide: true,
                  rowsPerPageOptions:[50, 100, 500, 1000],
                  rowsPerPage: paginationMembre.size,
                  count: paginationMembre.totalElements,
                  onChangePage(currentPage) {
                    afficherMembres(currentPage, paginationMembre.size)
                  },
                  onChangeRowsPerPage(numberOfRows) {
                    afficherMembres(0, numberOfRows)
                  },
                }}
              />
          </div>
      </Modal>
    </div>
  );
}
export default withTranslation()(Activite)