import React, { useEffect, useState } from "react";

// styles
import { Button, CircularProgress, IconButton, LinearProgress } from "@material-ui/core";
import { Button as Button2, Col, DatePicker, Divider, Form, Input, Row, Select, Tag } from "antd";
import dayjs from 'dayjs';
import { withTranslation } from "react-i18next";
import PageTitle from "../../../components/PageTitle/PageTitle";
import useStyles from "./styles";

import { Cancel, Check, Delete, Edit } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import 'dayjs/locale/fr';
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from "react-toastify";
import { Constant } from "../../../Constant";
import Service from "../../../service";
import "./tree.css";

dayjs.locale('fr');


function BetiwQuiz(props) {
  const {t} = props;
  var classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenInformation, setIsModalOpenInformation] = useState(false);
  var [information, setInformation] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var [response, setResponse] = useState([]);
  var [allDepartement, setDepartement] = useState([]);
  var [allAssemble, setAllAssemble] = useState([]);
  var [allActivite, setAllActivite] = useState([]);
  var [allProjet, setAllProjet] = useState([]);
  var [forUpdate, setForUpdate] = useState({});
  var [dateEtHeure, setDateEtHeure] = useState({});
  var [orgChart, setOrgChart] = useState({});
  var [recherhce, setRecherche] = useState({});
  const theme = useTheme();
  var [treeParam, setTreeParam] = useState({
    orientation: "vertical",
    chemin: "diagonal",
    espace: 2,
  });
  var [pagination, setPagination] = useState({page: 0, size: 50, totalElements: 0});
  let search = "";
  const { RangePicker } = DatePicker;
  let formatDateFrançais = "DD/MM/YYYY"
  let formatDateFrançaisAvecHeure = "DD/MM/YYYY HH:mm:ss"
  let formatDateAnglais = "YYYY-MM-DD"


  const [form] = Form.useForm();
  
  useEffect(()=>{
    (()=>{
      afficher(pagination.page, pagination.size)
    })();
  }, [])

  const afficher = async (page, size) => {
    search = "&search="
    if(recherhce.niveau !== undefined && recherhce.niveau !== null && recherhce.niveau !== ""){
      search = `${search}t.niveau= ${recherhce.niveau} AND `
    }
    if(recherhce.active !== undefined && recherhce.active !== null){
      search = `${search}t.active= ${recherhce.active} AND `
    }
    getAll((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size)
  }

  const getAll = (searchFait, page, size) => {
    setIsLoading(true);
    searchFait = `${searchFait}`
    Service.getAll(Constant.anonyme+"/quiz", page, size, searchFait, false).then(
      async (response) => {
        setPagination({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setResponse(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const onFinish = (values) => {
    let question = {
      ml: values.mlquestion,
      fr: values.frquestion,
      en: values.enquestion,
    };
    let reponses = {
      ml: [],
      fr: [],
      en: [],
    };

    [1,2,3,4].forEach(element => {
      if(values[`mlreponse${element}`] !== undefined && values[`mlreponse${element}`] !== null && values[`mlreponse${element}`] !== "") {
        reponses.ml.push({reponse: values[`mlreponse${element}`], vrai: values.mlvrai===element ? true : false})
      }
      if(values[`frreponse${element}`] !== undefined && values[`frreponse${element}`] !== null && values[`frreponse${element}`] !== "") {
        reponses.fr.push({reponse: values[`frreponse${element}`], vrai: values.frvrai===element ? true : false})
      }
      if(values[`enreponse${element}`] !== undefined && values[`enreponse${element}`] !== null && values[`enreponse${element}`] !== "") {
        reponses.en.push({reponse: values[`enreponse${element}`], vrai: values.envrai===element ? true : false})
      }
    });

    let data = {
      chapitre: parseInt(values.chapitre),
      niveau: parseInt(values.niveau),
      question: JSON.stringify(question),
      reponses: JSON.stringify(reponses),
    }
    setIsLoading(true);
    search =  ``
    if(forUpdate.id === undefined) {
      Service.addAll(Constant.access+"/quiz", search, data, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          // form.resetFields();
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    } else {
      data.id = forUpdate.id
      Service.putAll(Constant.access+"/quiz", search, data, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          // form.resetFields();
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    }
  };

  const columns = [
    {
      name: "niveau",
      label: t("niveau"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "question",
      label: t("question"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = JSON.parse(value)
          return <Row gutter={16} >
            <Col className="gutter-row" xxl={24}>
              <strong style={{textDecoration: 'underline'}}>{t("Bambara")}</strong><br/>
              {data.ml}
            </Col>
            <Divider/>
            <Col className="gutter-row" xxl={24}>
              <strong style={{textDecoration: 'underline'}}>{t("Français")}</strong><br/>
              {data.fr}
            </Col>
            <Divider/>
            <Col className="gutter-row" xxl={24}>
              <strong style={{textDecoration: 'underline'}}>{t("Anglais")}</strong><br/>
              {data.en}
            </Col>
          </Row>
        }
       }
    },
    {
      name: "reponses",
      label: t("reponse"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = JSON.parse(value)
          return <Row gutter={16} >
            <Col className="gutter-row" xxl={24}>
              <strong style={{textDecoration: 'underline'}}>{t("Bambara")}</strong><br/>
              <ol>
                {data.ml.map((e, index)=> {
                  return <li key={index+'a'} style={{backgroundColor: e.vrai && "green"}}>{e.reponse}</li>
                })}
              </ol>
            </Col>
            <Col className="gutter-row" xxl={24}>
              <strong style={{textDecoration: 'underline'}}>{t("Français")}</strong><br/>
              <ol>
                {data.fr.map((e, index)=> {
                  return <li key={index+'b'} style={{backgroundColor: e.vrai && "green"}}>{e.reponse}</li>
                })}
              </ol>
            </Col>
            <Col className="gutter-row" xxl={24}>
              <strong style={{textDecoration: 'underline'}}>{t("Anglais")}</strong><br/>
              <ol>
                {data.en.map((e, index)=> {
                  return <li key={index+'c'} style={{backgroundColor: e.vrai && "green"}}>{e.reponse}</li>
                })}
              </ol>
            </Col>
          </Row>
        }
       }
    },
    {
      name: "active",
      label: t("Statut"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <Tag color="success">{t("active")}</Tag> : <Tag color="error">{t("desactive")}</Tag>;
        }
       }
    },
    {
      label: t("Action"),
      name: "id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
            <IconButton style={{color: data.active ? 'red' : 'green', fontSize: 15}} onClick={(e)=> {changeStatus(value)}}> {data.active ? <span><Cancel /> {t("desactiver")}</span> : <span><Check /> {t("activer")}</span>} </IconButton>
            <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm(t("suppressionConfirm")))deleteAction(value)}}> <Delete /> {t("supprimer")} </IconButton>
            <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifierAction(data)}}> <Edit /> {t("modifier")} </IconButton>
          </div>;
        }
      }
    }
  ];


  const changeStatus = (id) => {
    setIsLoading(true);
    Service.changeStatutAll(Constant.access+"/changeQuizStatut", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const deleteAction = (id) => {
    setIsLoading(true);
    Service.deleteAll(Constant.access+"/quiz", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const modifierAction = (data) => {
    let questions = JSON.parse(data.question)
    let reponses = JSON.parse(data.reponses)
    let formData = [
      {name: "niveau", value: data.niveau},
      {name: "enquestion", value: questions.en},
      {name: "frquestion", value: questions.fr},
      {name: "mlquestion", value: questions.ml},
    ]
    reponses.ml.forEach((element, index) => {
      formData.push(
        {name: "mlreponse"+(index+1), value: element.reponse},
      )
      if(element.vrai) {
        formData.push(
          {name: "mlvrai", value: (index+1)},
        ) 
      }
    });
    reponses.fr.forEach((element, index) => {
      formData.push(
        {name: "frreponse"+(index+1), value: element.reponse},
      )
      if(element.vrai) {
        formData.push(
          {name: "frvrai", value: (index+1)},
        ) 
      }
    });
    reponses.en.forEach((element, index) => {
      formData.push(
        {name: "enreponse"+(index+1), value: element.reponse},
      )
      if(element.vrai) {
        formData.push(
          {name: "envrai", value: (index+1)},
        ) 
      }
    });
    setForUpdate(data)
    form.setFields(formData)
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{width: '50%'}}
      />
      <PageTitle title={t("BetiwQuiz")} />
      <Row gutter={16} >
        <Col className="gutter-row" span={12}>
          <div style={{backgroundColor: 'rgba(255, 255, 255)', padding: 20, borderRadius: 20, boxShadow: "1px 2px 20px 2px black"}}>
            <Form
                name="basic"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                <Row gutter={24}>
                  <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                      <Form.Item
                      label={t("niveau")}
                      name="niveau"
                      rules={[
                        {
                          required: true,
                          message: t("champObligatoire"),
                        },
                      ]}
                    >
                      <Input type="number"/>
                    </Form.Item>
                  </Col>
{/* Bambara */}
                  <Col className="gutter-row" span={8} style={{marginBottom: 10}}>
                    <Row>
                      <Divider>{t("Bambara")}</Divider>
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("question")}
                          name="mlquestion"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                          >
                          <Input.TextArea/>
                        </Form.Item>
                      </Col>
          {/* Reponse 1 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 1"}
                          name="mlreponse1"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
          {/* Reponse 2 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 2"}
                          name="mlreponse2"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
          {/* Reponse 3 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 3"}
                          name="mlreponse3"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
          {/* Reponse 4 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 4"}
                          name="mlreponse4"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
          {/* Reponse Vraie */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                        <Form.Item
                          label={t("vrai")}
                          name="mlvrai"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Select
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              style={{ width: "100%", zIndex: 1}}
                              options=
                              {
                                [
                                  { "value": 1, "label": `${t("reponse")} 1` },
                                  { "value": 2, "label": `${t("reponse")} 2` },
                                  { "value": 3, "label": `${t("reponse")} 3` },
                                  { "value": 4, "label": `${t("reponse")} 4` },
                                ]
                              }
                            />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
{/* Français */}
                  <Col className="gutter-row" span={8} style={{marginBottom: 10}}>
                    <Row>
                      <Divider>{t("Français")}</Divider>
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("question")}
                          name="frquestion"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                          >
                          <Input.TextArea/>
                        </Form.Item>
                      </Col>
          {/* Reponse 1 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 1"}
                          name="frreponse1"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
                      
          {/* Reponse 2 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 2"}
                          name="frreponse2"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
          {/* Reponse 3 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 3"}
                          name="frreponse3"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
          {/* Reponse 4 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 4"}
                          name="frreponse4"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
          {/* Reponse Vraie */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                        <Form.Item
                          label={t("vrai")}
                          name="frvrai"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Select
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              style={{ width: "100%", zIndex: 1}}
                              options=
                              {
                                [
                                  { "value": 1, "label": `${t("reponse")} 1` },
                                  { "value": 2, "label": `${t("reponse")} 2` },
                                  { "value": 3, "label": `${t("reponse")} 3` },
                                  { "value": 4, "label": `${t("reponse")} 4` },
                                ]
                              }
                            />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
{/* Anglais */}
                  <Col className="gutter-row" span={8} style={{marginBottom: 10}}>
                    <Row>
                      <Divider>{t("Anglais")}</Divider>
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("question")}
                          name="enquestion"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                          >
                          <Input.TextArea/>
                        </Form.Item>
                      </Col>
          {/* Reponse 1 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 1"}
                          name="enreponse1"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
          {/* Reponse 2 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 2"}
                          name="enreponse2"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
          {/* Reponse 3 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 3"}
                          name="enreponse3"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
          {/* Reponse 4 */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                          <Form.Item
                          label={t("reponse") +" 4"}
                          name="enreponse4"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input/>
                        </Form.Item>
                      </Col>
          {/* Reponse Vraie */}
                      <Col className="gutter-row" span={24} style={{marginBottom: 10}}>
                        <Form.Item
                          label={t("vrai")}
                          name="envrai"
                          rules={[
                            {
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Select
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              style={{ width: "100%", zIndex: 1}}
                              options=
                              {
                                [
                                  { "value": 1, "label": `${t("reponse")} 1` },
                                  { "value": 2, "label": `${t("reponse")} 2` },
                                  { "value": 3, "label": `${t("reponse")} 3` },
                                  { "value": 4, "label": `${t("reponse")} 4` },
                                ]
                              }
                            />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br/>
                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                      {forUpdate.id !== undefined ? t("modifier") : t("ajouter")}
                    </Button2>
                  )}
                </Form.Item>

              </Form>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div>
            <Row gutter={16} style={{marginBottom: 20}}>
                <Col className="gutter-row" xxl={6}>
                  <label>{t("niveau")}</label>
                  <br/>
                  <Input type="number" onChange={(e)=> setRecherche({...recherhce, niveau: e.target.value})}/>
                </Col>
                <Col className="gutter-row" xxl={6}>
                  <label>{t("Statut")}</label>
                  <br/>
                  <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 100, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, active: e})}
                        options=
                        {
                          [
                            { "value": true, "label": `${t("active")}` },
                            { "value": false, "label": `${t("desactive")}` },
                          ]
                        }
                      />
                </Col>
                <Col className="gutter-row" xxl={6}>
                  <br/>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={(e)=>{afficher(pagination.page, pagination.size)}}
                  >
                      {t("chercher")}
                  </Button>
                </Col>
            </Row>
            {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
            <MUIDataTable
              title={<h2>{`${t("total")}: ${pagination.totalElements}`}</h2>}
              data={response}
              columns={columns}
              options={{
                draggableColumns: { enabled: true },
                enableNestedDataAccess: '.',
                filterType: "multiselect",
                jumpToPage: true,
                serverSide: true,
                rowsPerPageOptions:[50, 100, 500, 1000],
                rowsPerPage: pagination.size,
                count: pagination.totalElements,
                // textLabels: Constant.textLabels,
                selectableRows: "none",
                onChangePage(currentPage) {
                  afficher(currentPage, pagination.size)
                },
                onChangeRowsPerPage(numberOfRows) {
                  afficher(0, numberOfRows)
                },
              }}
            />
          </div>
        </Col>
      </Row>

    </div>
  );
}
export default withTranslation()(BetiwQuiz)