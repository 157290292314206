import classnames from "classnames";
import React from "react";
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

//icons

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Charts from "../../pages/charts";
import Dashboard from "../../pages/dashboard";
import Icons from "../../pages/icons";
import Maps from "../../pages/maps";
import Notifications from "../../pages/notifications";
import Tables from "../../pages/tables";
import Typography from "../../pages/typography";

// context
import { useLayoutState } from "../../context/LayoutContext";
import Activite from '../../pages/pierre/activite/Activite';
import Assemblees from '../../pages/pierre/assemblees/Assemblees';
import BetiwEnseignement from "../../pages/pierre/betiwEnseignement/BetiwEnseignement";
import BetiwQuiz from "../../pages/pierre/betiwQuiz/BetiwQuiz";
import Comptabilite from '../../pages/pierre/comptabilite/Comptabilite';
import Culte from "../../pages/pierre/culte/Culte";
import Departement from '../../pages/pierre/departement/Departement';
import Mariage from '../../pages/pierre/mariage/Mariage';
import Membres from '../../pages/pierre/membres/Membres';
import Message from '../../pages/pierre/message/Message';
import Projet from '../../pages/pierre/projet/Projet';
import Footer from './Footer';

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/typography" component={Typography} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/notifications" component={Notifications} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
              {/* Par pierre */}
              <Route path="/app/Assemblées" component={Assemblees} />
              <Route path="/app/Départements" component={Departement} />
              <Route path="/app/Membres" component={Membres} />
              <Route path="/app/Mariages" component={Mariage} />
              <Route path="/app/Activités" component={Activite} />
              <Route path="/app/Projets" component={Projet} />
              <Route path="/app/Comptabilités" component={Comptabilite} />
              <Route path="/app/SMS" component={Message} />
              <Route path="/app/BetiwQuiz" component={BetiwQuiz} />
              <Route path="/app/BetiwEnseignement" component={BetiwEnseignement} />
              <Route path="/app/Culte" component={Culte} />
            </Switch>
            <Footer/>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
