import React, { useEffect, useState } from "react";

// styles
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, CircularProgress, IconButton, LinearProgress } from "@material-ui/core";
import { Button as Button2, Col, ConfigProvider, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import locale from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import { withTranslation } from "react-i18next";
import PageTitle from "../../../components/PageTitle/PageTitle";
import useStyles from "./styles";

import { Delete, Edit, RemoveRedEye } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import 'dayjs/locale/fr';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from "react-toastify";
import { Constant } from "../../../Constant";
import Service from "../../../service";
import "./tree.css";

dayjs.locale('fr');


function Culte(props) {
  const {t} = props;
  var classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenInformation, setIsModalOpenInformation] = useState(false);
  const [isModalOpenMembres, setIsModalOpenMembres] = useState(false);
  var [information, setInformation] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var [response, setResponse] = useState([]);
  var [allAssemble, setAllAssemble] = useState([]);
  var [forUpdate, setForUpdate] = useState({});
  var [dateEtHeure, setDateEtHeure] = useState({});
  var [membreData, setMembreData] = useState({});
  var [responseMembre, setResponseMembre] = useState([]);
  var [paginationMembre, setPaginationMembre] = useState({page: 0, size: 50, totalElements: 0});
  var [recherhce, setRecherche] = useState({});
  const theme = useTheme();
  var [membreRecherche, setMembreRecherche] = useState({});
  var [pagination, setPagination] = useState({page: 0, size: 50, totalElements: 0});
  let search = "";
  const { RangePicker } = DatePicker;
  let formatDateFrançais = "DD/MM/YYYY"
  let formatDateFrançaisAvecHeure = "DD/MM/YYYY HH:mm:ss"
  let formatDateAnglais = "YYYY-MM-DD"


  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpenInformation(false);
    setIsModalOpenMembres(false);
  };

  useEffect(()=>{
    (()=>{
      afficher(pagination.page, pagination.size)
      getAllAssemble("&search=true", 0, 5000)
    })();
  }, [])

  const getAllAssemble = (searchFait, page, size) => {
    setIsLoading(true);
    Service.getAll(Constant.access+"/assemble", page, size, searchFait, true).then(
      async (response) => {
        setAllAssemble(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const afficher = async (page, size) => {
    search = "&search="
    if(recherhce.theme !== undefined && recherhce.theme.trim() !== ""){
      search = `${search}theme LIKE _1_${recherhce.theme}_2_ AND `
    }
    if(recherhce.orateur !== undefined && recherhce.orateur.trim() !== ""){
      search = `${search}orateur LIKE _1_${recherhce.orateur}_2_ AND `
    }
    if(recherhce.culte !== undefined && recherhce.culte.trim() !== ""){
      search = `${search}culte LIKE _1_${recherhce.culte}_2_ AND `
    }
    if(recherhce.assemble !== undefined && recherhce.assemble.trim() !== ""){
      search = `${search}assemble.id='${recherhce.assemble}' AND `
    }
    if(recherhce.dateajout !== undefined && recherhce.dateajout.length > 0 && recherhce.dateajout[1].trim() !== ""){
      let debut  = moment(recherhce.dateajout[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.dateajout[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}dateajout BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    getAll((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size)
  }

  const getAll = (searchFait, page, size) => {
    setIsLoading(true);
    searchFait = `${searchFait} ORDER BY dateajout DESC`
    Service.getAll(Constant.access+"/cultes", page, size, searchFait, true).then(
      async (response) => {
        setPagination({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setResponse(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const onFinish = (values) => {
    setIsLoading(true);
    search =  `?assembleId=${values.assembleId}`
    if(forUpdate.id === undefined) {
      Service.addAll(Constant.access+"/cultes", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          setDateEtHeure({})
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    } else {
      values.id = forUpdate.id
      Service.putAll(Constant.access+"/cultes", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          setDateEtHeure({})
          handleCancel()
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    }
  };

  const columns = [
    {
      name: "assemble.nom",
      label: t("Assemblees"),
      options: {
        filter: false,
        sort: false,
       }
    },
    {
      name: "culte",
      label: t("Culte"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "theme",
      label: t("theme"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "orateur",
      label: t("orateur"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "homme",
      label: t("Homme"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "femme",
      label: t("Femme"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "jeunehomme",
      label: t("jeuneHomme"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "jeunefemme",
      label: t("jeuneFemme"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "enfanthomme",
      label: t("enfantHomme"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "enfantfemme",
      label: t("enfantFemme"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "information",
      label: t("information"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <IconButton style={{color: 'blue', fontSize: 15}} onClick={(e)=>{setInformation(value); setIsModalOpenInformation(true)}}> <RemoveRedEye /> Voir </IconButton>;
        }
       }
    },
    {
      name: "dateajout",
      label: t("dateajout"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      label: t("Action"),
      name: "id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
            <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm(t("suppressionConfirm")))deleteAction(value)}}> <Delete /> {t("supprimer")} </IconButton>
            <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifierAction(data)}}> <Edit /> {t("modifier")} </IconButton>
          </div>;
        }
      }
    }
  ];

  const deleteAction = (id) => {
    setIsLoading(true);
    Service.deleteAll(Constant.access+"/cultes", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const modifierAction = (data) => {
    setForUpdate(data)
    form.setFields([
      {name: "theme", value: data.theme},
      {name: "orateur", value: data.orateur},
      {name: "culte", value: data.culte},
      {name: "assembleId", value: data.assemble.id},
      {name: "information", value: data.information},
      {name: "homme", value: data.homme},
      {name: "femme", value: data.femme},
      {name: "jeunehomme", value: data.jeunehomme},
      {name: "jeunefemme", value: data.jeunefemme},
      {name: "enfanthomme", value: data.enfanthomme},
      {name: "enfantfemme", value: data.enfantfemme},
    ])
    showModal()
  }

  const calcul = (type) => {
    let d = 0
    response.forEach((element, index) => {
      if(type === Constant.homme) {
        d = d + element.homme
      } else if(type === Constant.femme) {
        d = d + element.femme
      } else if(type === Constant.jeuneHomme) {
        d = d + element.jeunehomme
      } else if(type === Constant.jeuneFemme) {
        d = d + element.jeunefemme
      } else if(type === Constant.enfantHomme) {
        d = d + element.enfanthomme
      } else if(type === Constant.enfantFemme) {
        d = d + element.enfantfemme
      }
      if((index + 1) === response.length) {
        return d
      }
    });
    return d
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{width: '50%'}}
      />
      <PageTitle title={t("Culte")} button={
        <div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={(e)=>{setForUpdate({}); showModal()}}
          >
              {t("newCulte")}
          </Button>
        </div>
      }/>

        <div>
          <Row gutter={16} style={{marginBottom: 20}}>
              <Col className="gutter-row" xxl={4}>
                <label>{t("theme")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, theme: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("orateur")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, orateur: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Culte")}</label>
                <br/>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", minWidth: 200, zIndex: 1}}
                    onChange={(e)=>setRecherche({...recherhce, culte: e})}
                    options={[
                      { "value": Constant.Bambara, "label": t(Constant.Bambara) },
                      { "value": Constant.Français, "label": t(Constant.Français) },
                      { "value": Constant.Anglais, "label": t(Constant.Anglais) },
                      { "value": Constant.Bilingue, "label": t(Constant.Bilingue) },
                    ]}
                  />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Assemblees")}</label>
                <br/>
                  <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, assemble: e})}
                        options=
                        {
                          allAssemble.length > 0 && allAssemble.map((element) => {
                            return { "value": element.id, "label": `${element.nom}` }
                          })
                        }
                      />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("dateajout")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, dateajout: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <br/>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={(e)=>{afficher(pagination.page, pagination.size)}}
                >
                    {t("chercher")}
                </Button>
              </Col>
          </Row>
          {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title={<>
                <h2>{`${t("total")}: ${pagination.totalElements}`}</h2>
                <strong style={{color: 'green'}}>{t("Homme")}:</strong> {(response.length > 0) && calcul(Constant.homme)}
                <br/>
                <strong style={{color: 'red'}}>{t("Femme")}:</strong> {(response.length > 0) && calcul(Constant.femme)}
                <br/>
                <strong style={{color: 'green'}}>{t("jeuneHomme")}:</strong> {(response.length > 0) && calcul(Constant.jeuneHomme)}
                <br/>
                <strong style={{color: 'red'}}>{t("jeuneFemme")}:</strong> {(response.length > 0) && calcul(Constant.jeuneFemme)}
                <br/>
                <strong style={{color: 'green'}}>{t("enfantHomme")}:</strong> {(response.length > 0) && calcul(Constant.enfantHomme)}
                <br/>
                <strong style={{color: 'red'}}>{t("enfantFemme")}:</strong> {(response.length > 0) && calcul(Constant.enfantFemme)}
              </>
              }
            data={response}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: true,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: pagination.size,
              count: pagination.totalElements,
              // textLabels: Constant.textLabels,
              selectableRows: "none",
              onChangePage(currentPage) {
                afficher(currentPage, pagination.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                afficher(0, numberOfRows)
              },
            }}
          />
        </div>
{/* Information */}
      <Modal zIndex={1200} width={1000} title={<p style={{borderBottom: "1px solid black"}}>{t("information")}</p>} open={isModalOpenInformation} footer={null} onCancel={handleCancel} >
        <div dangerouslySetInnerHTML={{ __html: information }} />
      </Modal>

{/* Culte */}
      <Modal zIndex={1200} width={1000} title={<p style={{borderBottom: "1px solid black"}}>{t("newCulte")}</p>} open={isModalOpen} footer={null} onCancel={handleCancel} >
        <div>
          <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              // style={{
              //   maxWidth: 600,
              // }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Row gutter={24}>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                  <Form.Item
                      label={t("Assemblees")}
                      name="assembleId"
                      rules={[
                        {
                          required: true,
                          message: t("champObligatoire"),
                        },
                      ]}
                    >
                      <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options=
                          {
                            allAssemble.length > 0 && allAssemble.map((element) => {
                              return { "value": element.id, "label": `${element.nom}` }
                            })
                          }
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                        label={t("Culte")}
                        name="culte"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options={[
                            { "value": Constant.Bambara, "label": t(Constant.Bambara) },
                            { "value": Constant.Français, "label": t(Constant.Français) },
                            { "value": Constant.Anglais, "label": t(Constant.Anglais) },
                            { "value": Constant.Bilingue, "label": t(Constant.Bilingue) },
                          ]}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                    label={t("theme")}
                    name="theme"
                    rules={[
                      {
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                    label={t("orateur")}
                    name="orateur"
                    rules={[
                      {
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                    label={t("Homme")}
                    name="homme"
                    rules={[
                      {
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                    label={t("Femme")}
                    name="femme"
                    rules={[
                      {
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                    label={t("jeuneHomme")}
                    name="jeunehomme"
                    rules={[
                      {
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                    label={t("jeuneFemme")}
                    name="jeunefemme"
                    rules={[
                      {
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                    label={t("enfantHomme")}
                    name="enfanthomme"
                    rules={[
                      {
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                    label={t("enfantFemme")}
                    name="enfantfemme"
                    rules={[
                      {
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={t("information")}
                name="information"
                rules={[
                  {
                    required: false,
                  },
                ]}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
              >
                <CKEditor
                    editor={ ClassicEditor }
                    data={(forUpdate.information !== null && forUpdate.information !== undefined) ? forUpdate.information : ""}
                    onChange={ ( event, editor ) => {
                      form.setFieldValue("information", editor.getData())
                    }}
                />
              </Form.Item>
              <br/>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                    {forUpdate.id !== undefined ? t("modifier") : t("ajouter")}
                  </Button2>
                )}
                <Button2 onClick={handleCancel} type="primary" danger>
                  {t("annuler")}
                </Button2>
              </Form.Item>

            </Form>
        </div>
      </Modal>
    </div>
  );
}
export default withTranslation()(Culte)