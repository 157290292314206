export const ml = {
    connexion: "Sɛgɛsɛgɛli",
    jean1721: "Yuhana 17:21",
    jean1721verset: "Walisa u bɛɛ ka kɛ kelen ye, ne Fa, i ko e bɛ ne la ani ne bɛ i la cogo min na, u fana ka kɛ an na, walisa diɲɛ ka da a la ko i ye ne ci.",
    username: "Sɛgɛsɛgɛli tɔgɔ",
    password: "Sɛgɛsɛgɛli gundo",
    seconnecter: "Ka don",
    motdepasseoublier: "I ɲinan na gundo kɔ wa ?",
    changermotdepasse: "Ka gundo fale",
    ancienpassword: "Gundo kɔrɔ",
    nouveaupassword: "Gundo kura",
    confirmationpassword: "Segin gundo kura kan",
    motDePasseConfirmationDifferent: "Gundo kura ni a sɛgɛsɛgɛli tɛ kɛlɛn ye",
    accesRefuse: "Son ma kɛ i ka ko ɲinita ma. I ka kunnafoniw sɛgɛsɛgɛ ka sifilɛ ani i ka reso",
    dashboard: "Kunafoni so ba",
    Assemblees: "Djɛ kulu",
    Departements: "Baarada",
    Membres: "Mogow",
    Mariages: "Furuw",
    Activites: "Ko bolodalenw",
    newActivites: "Ka ko kura boloda",
    Projets: "Ko bontaw",
    newProjets: "Ko bonta kura",
    Comptabilites: "Wari kow",
    SMS: "Kunnafoni",
    Statistiques: "Djabiw ɲinili",
    newAssemble: "Djɛ kulu kura",
    champObligatoire: "Ni sɛnbin yɔrɔ ye diagoya ye",
    logo: "Dja",
    nom: "Tɔgɔ",
    adresse: "Sɔrɔ yɔrɔ",
    Datecreation: "A sigi don",
    dateajout: "Farakan don",
    longitude: "kundama fɔlɔ",
    latitude: "kundama filana",
    information: "Kunafoni wɛrɛw",
    ajouter: "Ka fara tɔw kan",
    modifier: "Ka yɛlɛma don a la",
    supprimer: "Ka a bɔ a la",
    annuler: "Ka a dabila",
    ajoutSuccess: "A farala tɔw kan",
    envoiSuccess: "Kunnafoniw tara",
    modificationSuccess: "Yɛlɛma kɛra",
    suppressionSuccess: "A bɔra ye",
    erreurServeur: "Gɛlɛya dɔ sɔrɔla bara kɛ tuma la. I be se ka an kunnafoniya ni gɛlɛya ma ta.",
    Statut: "Tiogoya",
    active: "Bɛse ka bara kɛ",
    desactive: "Tɛse ka bara kɛ",
    activer: "A ka bara kɛ",
    desactiver: "Ka bali bara la",
    suppressionConfirm: "I ka ko kɛta sɛbɛntiya ?",
    Action: "Ko kɛ ta",
    lierA: "A tugulen bɛ ni la",
    Schema: "Dja",
    table: "Tablo",
    parent: "Fa",
    enfant: "Den",
    chercher: "Ka ɲinita kɛ",
    tout: "A bɛ",
    du: "Daminɛ",
    au: "laban",
    de: "Ɲɛgɛn kaɲɛ",
    a: "ka tabila",
    orientation: "Kɛ tiogo",
    Chemin: "Sira",
    Espace: "Danfara",
    newDepartements: "Baarada coura",
    newMembres: "Mɔgɔ coura",
    nouveauMembres: "Mɔgɔ couraw",
    ancienMembres: "Mɔgɔ kɔrɔw",
    newMariages: "Furu siri coura",
    InformationsPersonnels: "A tigi kunafoni",
    InformationsSurLaFoi: "Danaya kunafoni",
    prenom: "Tɔgɔ",
    nomP: "Diamu",
    datenaissance: "Bange don",
    lieunaissance: "Bange yɔrɔ",
    lieu: "Kɛ yɔrɔ",
    dateTime: "Don ni wagati",
    matrimonial: "Furu kow",
    celibataire: "A ma furu",
    marie: "A furu la",
    veuve: "A djɛ ɲɔgɔn bana",
    divorcee: "A ye furu sa kɛ",
    ethnie: "Siya",
    Profession: "Barada",
    Nationalite: "Bange diamana",
    arrivee: "Nali don",
    provenance: "Bɔra djɛ kulu min la",
    dateconversion: "Tubili don",
    datemariage: "Furu don",
    lieuconversion: "Tubili yɔrɔ",
    datebapteme: "Batiseli don",
    lieubapteme: "Batiseli yɔrɔ",
    datebaptemeenfant: "Demisɛn ma batiseli don",
    auteurbaptemeenfant: "Demisɛn ma batiseli baga",
    roleEglise: "Diɔ yɔrɔ djɛ kulu kono",
    Culte: "Batoli",
    Bambara: "Bamanakan",
    Français: "Nazarakan",
    Anglais: "Angilɛkan",
    Bilingue: "Kan tiama",
    statutactuelle: "Tiogoya djɛ kulu kono",
    Present: "A bɛ yan",
    deces: "A sara",
    depart: "A tara",
    Autre: "Dɔ wɛrɛ",
    Observation: "Diate minɛw",
    genre: "Tiɛ wala muso",
    newCulte: "Batoli coura",
    Homme: "Tiɛ",
    Femme: "Muso",
    jeuneHomme: "Kamalen",
    jeuneFemme: "Nbogotigi",
    enfantHomme: "Den tiɛ",
    enfantFemme: "Den muso",
    photo: "Dia",
    phone: "Telefɔni",
    emailNoValid: "Ni adrɛsi ma ɲi",
    email: "Adrɛsi",
    pere: "Fa",
    mere: "Ba",
    afficher: "Ka djira",
    cacher: "Ka dogo",
    periode: "Kɛ wagati",
    periodeRepetion: "Kɛ wagati hakɛya",
    Jours: "Donw",
    Semaines: "Dɔgɔkunw",
    Mois: "Kalow",
    Années: "Sanw",
    Chaque: "Tuma bɛ",
    datedebut: "Don daminɛ",
    datefin: "Don laban",
    heuredebut: "Wagati daminɛ",
    heurefin: "Wagati laban",
    heureAjout: "Farakan wagati",
    montant: "Sɔngɔn",
    newAdd: "Ka kura don",
    Recharge: "Ka dɔ don",
    Retrait: "Ka dɔ bɔ",
    rowSelect: "Ɲɛna tɔmɔna",
    authentification: "Doni yamaruyaw",
    roleGestion: "Yamaruyaw ɲinabɔ yɔrɔ",
    role: "Yamaruyaw",
    fraisParticipation: "Wari sarata",
    clesSms: "SMS klé sevob.com sanfɛ",
    expediteursms: "Kunnafoni tji ba sevob.com sanfɛ",
    clesPaiement: "Wari sara klé sevob.com sanfɛ",
    messageConfirmation: "Kunnafoni wari sarali kofɛ",
    destinateur: "Sɔrɔ baw",
    send: "Ka u tji",
    Reste: "A tɔ",
    sedeconnecter: "Ka bɔ",
    total: "Bɛ",
    queEstVotreAssemble: "I bɛ djɛ kulu djumɛ la ?",
    gratuit: "fufu",
    payerMaintenant: "Ka wari sara sisan",
    contibuerMaintenant: "Ka dɛmɛ kɛ sisan",
    inscrivezvousMaintenant: "Ka tɔgɔw sɛbɛn sisan",
    personneDansTableau: "Mɔgɔ dɔ don tablo kɔnɔ",
    oui: "Awɔ",
    non: "Ayi",
    administrateur: "Kuntigiya",
    BetiwQuiz: "Betiw ɲinikaliw",
    BetiwEnseignement: "Betiw Kalanw",
    chapitre: "Sura",
    niveau: "Yɔrɔ",
    question: "Ɲinikali",
    reponse: "Diabili",
    vrai: "Jabili ɲuman",
    theme: "Kuma kun",
    contenu: "Kalan sɛbɛne",
    audio: "Kalan kumakan",
    orateur: "Wajulikɛla",
}