export const fr = {
    connexion: "Connexion",
    jean1721: "Jean 17:21",
    jean1721verset: "Je prie pour que tous soient un. Père, qu'ils soient unis à nous, comme toi tu es uni à moi et moi à toi. Qu'ils soient un pour que le monde croie que tu m'as envoyé.",
    username: "Nom d'utilisateur",
    password: "Mot de passe",
    ancienpassword: "Ancien mot de passe",
    nouveaupassword: "Nouveau mot de passe",
    motDePasseConfirmationDifferent: "Le nouveau mot de passe et sa confirmation sont différents",
    confirmationpassword: "Saisissez encore le nouveau mot de passe",
    seconnecter: "Se connecter",
    sedeconnecter: "Se déconnecter",
    motdepasseoublier: "Mot de passe oublié ?",
    changermotdepasse: "Changer mon mot de passe",
    accesRefuse: "Votre demande d'accès a été refusée. Veuillez vérifier vos informations et votre connexion.",
    dashboard: "Tableau de bord",
    Assemblees: "Assemblées",
    Departements: "Départements",
    Membres: "Membres",
    Mariages: "Mariages",
    Activites: "Activités",
    newActivites: "Nouvelle activité",
    Projets: "Projets",
    newProjets: "Nouveau projet",
    Comptabilites: "Comptabilités",
    SMS: "Messages",
    Statistiques: "Statistiques",
    newAssemble: "Nouvelle Assemblée",
    champObligatoire: "Ce champs est obligatoire",
    logo: "Logo",
    nom: "Nom",
    adresse: "Adresse",
    Datecreation: "Date de création",
    dateajout: "Date d'ajout",
    dateTime: "Date et heure",
    longitude: "Longitude",
    latitude: "Latitude",
    information: "Autres informations",
    ajouter: "Ajouter",
    modifier: "Modifier",
    supprimer: "Supprimer",
    annuler: "Annuler",
    ajoutSuccess: "Ajout effectué avec succès",
    envoiSuccess: "Envoi effectué avec succès",
    modificationSuccess: "Modification effectuée avec succès",
    suppressionSuccess: "Suppression effectué avec succès",
    erreurServeur: "Le serveur a rencontré une erreur. Si l'erreur persiste, veuillez contacter l'administrateur.",
    Statut: "Statut",
    active: "Activé",
    desactive: "Désactivé",
    activer: "Activer",
    desactiver: "Désactiver",
    suppressionConfirm: "Etes-vous sûr de votre action ?",
    Action: "Action",
    lierA: "Lié a",
    Schema: "Schéma",
    table: "Table",
    parent: "Parent",
    enfant: "Enfant",
    chercher: "Chercher",
    tout: "Toutes",
    du: "Du",
    au: "au",
    de: "De",
    a: "à",
    orientation: "Orientation",
    Chemin: "Chemin",
    Espace: "Espace",
    newDepartements: "Nouveau département",
    newMembres: "Nouveau membre",
    nouveauMembres: "Nouveaux membres",
    ancienMembres: "Anciens membres",
    newMariages: "Nouveau mariage",
    InformationsPersonnels: "Informations personnels",
    InformationsSurLaFoi: "Informations sur la foi",
    prenom: "Prénom",
    nomP: "Nom",
    datenaissance: "Date de naissance",
    datemariage: "Date du mariage",
    lieunaissance: "Lieu de naissance",
    lieu: "Lieu",
    matrimonial: "Situation matrimoniale",
    celibataire: "Célibataire",
    marie: "Marié(e)",
    veuve: "Veuve",
    divorcee: "Divorcée",
    ethnie: "Ethnie",
    Profession: "Profession",
    Nationalite: "Nationalité",
    arrivee: "Date d'arrivée",
    provenance: "Assemblée de provenance",
    dateconversion: "Date de conversion",
    lieuconversion: "Lieu de conversion",
    datebapteme: "Date du baptême",
    lieubapteme: "Lieu du baptême",
    datebaptemeenfant: "Date du baptême étant enfant",
    auteurbaptemeenfant: "Auteur du baptême étant enfant",
    roleEglise: "Rôle dans l'assemblée",
    roleGestion: "Gestion des rôles",
    Culte: "Culte",
    Bambara: "Bamanankan",
    Français: "Français",
    Anglais: "Anglais",
    Bilingue: "Bilingue",
    statutactuelle: "Statut dans l'assemblée",
    Present: "Présent",
    deces: "Décès",
    depart: "Départ",
    Autre: "Autre",
    Observation: "Observation",
    genre: "Genre",
    Homme: "Homme",
    Femme: "Femme",
    jeuneHomme: "Jeune Homme",
    jeuneFemme: "Jeune Femme",
    enfantHomme: "Garçon",
    enfantFemme: "Fille",
    photo: "Photo",
    phone: "Téléphone",
    emailNoValid: "Cet email n'est pas valide",
    email: "Email",
    pere: "Père",
    mere: "Mère",
    afficher: "Afficher",
    cacher: "Cacher",
    periode: "Période",
    periodeRepetion: "Nombre de Période",
    Jours: "Jours",
    Semaines: "Semaines",
    Mois: "Mois",
    Années: "Années",
    Chaque: "Chaque",
    datedebut: "Date du début",
    datefin: "Date de fin",
    heuredebut: "Heure du début",
    heurefin: "Heure de fin",
    heureAjout: "Heure d'ajout",
    montant: "Montant",
    newAdd: "Nouvel ajout",
    newCulte: "Nouveau culte",
    Recharge: "Recharge",
    Retrait: "Retrait",
    Reste: "Reste",
    rowSelect: "Lignes sélectionnées",
    authentification: "Authentification",
    role: "Rôle",
    fraisParticipation: "Frais de participation",
    clesSms: "Clé SMS sur sevob.com",
    expediteursms: "Expéditeur valide sur sevob.com",
    clesPaiement: "Clé de paiement sur sevob.com",
    messageConfirmation: "Message de confirmation",
    destinateur: "Destinateurs",
    send: "Envoyer",
    total: "Total",
    queEstVotreAssemble: "Quelle est votre assemblée ?",
    gratuit: "Gratuit",
    payerMaintenant: "Payer maintenant",
    inscrivezvousMaintenant: "Inscrivez-vous maintenant",
    contibuerMaintenant: "Contribuer maintenant",
    personneDansTableau: "Veuillez ajouter des personnes dans le tableau",
    oui: "Oui",
    non: "Non",
    administrateur: "Administrateur",
    BetiwQuiz: "Betiw Questions",
    BetiwEnseignement: "Betiw Enseignements",
    chapitre: "Chapitre",
    niveau: "Niveau",
    question: "Question",
    reponse: "Réponse",
    vrai: "Bonne réponse",
    theme: "Thème",
    contenu: "Contenu",
    audio: "Audio",
    orateur: "Prédicateur",
}