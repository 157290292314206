import React, { useEffect, useState } from "react";

// styles
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, CircularProgress, IconButton, LinearProgress } from "@material-ui/core";
import { Button as Button2, Col, ConfigProvider, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import locale from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import { withTranslation } from "react-i18next";
import PageTitle from "../../../components/PageTitle/PageTitle";
import useStyles from "./styles";

import { Delete, Edit, RemoveRedEye } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import 'dayjs/locale/fr';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from "react-toastify";
import { Constant } from "../../../Constant";
import Service from "../../../service";
import "./tree.css";

dayjs.locale('fr');


function Comptabilite(props) {
  const {t} = props;
  var classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenInformation, setIsModalOpenInformation] = useState(false);
  var [information, setInformation] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var [response, setResponse] = useState([]);
  var [allDepartement, setDepartement] = useState([]);
  var [forUpdate, setForUpdate] = useState({});
  var [dateEtHeure, setDateEtHeure] = useState({});
  var [orgChart, setOrgChart] = useState({});
  var [recherhce, setRecherche] = useState({});
  const theme = useTheme();
  var [treeParam, setTreeParam] = useState({
    orientation: "vertical",
    chemin: "diagonal",
    espace: 2,
  });
  var [pagination, setPagination] = useState({page: 0, size: 50, totalElements: 0});
  let search = "";
  const { RangePicker } = DatePicker;
  let formatDateFrançais = "DD/MM/YYYY"
  let formatDateFrançaisAvecHeure = "DD/MM/YYYY HH:mm:ss"
  let formatDateAnglais = "YYYY-MM-DD"


  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpenInformation(false);
  };

  useEffect(()=>{
    (()=>{
      afficher(pagination.page, pagination.size)
      getDepartement("&search=true", 0, 5000)
    })();
  }, [])

  const getDepartement = (searchFait, page, size) => {
    setIsLoading(true);
    Service.getAll(Constant.access+"/departements", page, size, searchFait, true).then(
      async (response) => {
        setDepartement(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const afficher = async (page, size) => {
    search = "&search="
    if(recherhce.montant !== undefined && recherhce.montant.trim() !== ""){
      search = `${search}montant= ${recherhce.montant} AND `
    }
    if(recherhce.type !== undefined && recherhce.type.trim() !== ""){
      search = `${search}type LIKE _1_${recherhce.type}_2_ AND `
    }
    if(recherhce.departement !== undefined && recherhce.departement.trim() !== ""){
      search = `${search}departement.id= '${recherhce.departement}' AND `
    }
    if(recherhce.information !== undefined && recherhce.information.trim() !== ""){
      search = `${search}information LIKE _1_${recherhce.information}_2_ AND `
    }
    if(recherhce.datecreation !== undefined && recherhce.datecreation.length > 0 && recherhce.datecreation[1].trim() !== ""){
      let debut  = moment(recherhce.datecreation[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.datecreation[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}dateajout BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    
    getAll((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size)
  }

  const getAll = (searchFait, page, size) => {
    setIsLoading(true);
    searchFait = `${searchFait} ORDER BY dateajout,Heureajout DESC`
    Service.getAll(Constant.access+"/comptabilite", page, size, searchFait, true).then(
      async (response) => {
        setPagination({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setResponse(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const onFinish = (values) => {
    setIsLoading(true);
    values.departement = {
      id: values.departement
    }
    search =  ``
    if(forUpdate.id === undefined) {
      Service.addAll(Constant.access+"/comptabilite", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          setDateEtHeure({})
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    } else {
      values.id = forUpdate.id
      Service.putAll(Constant.access+"/comptabilite", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          setDateEtHeure({})
          handleCancel()
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    }
  };

  const columns = [
    {
      name: "type",
      label: t("Action"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "montant",
      label: t("montant"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        }
       }
    },
    {
      name: "departement.nom",
      label: t("Departements"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "information",
      label: t("information"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <IconButton style={{color: 'blue', fontSize: 15}} onClick={(e)=>{setInformation(value); setIsModalOpenInformation(true)}}> <RemoveRedEye /> Voir </IconButton>;
        }
       }
    },
    {
      name: "dateajout",
      label: t("dateajout"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return moment(value).format(formatDateFrançais) + " à " + data.heureajout
        }
       }
    },
    {
      name: "auth.username",
      label: t("username"),
      options: {
        filter: true,
        sort: true
       }
    },
    {
      label: t("Action"),
      name: "id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
            <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm(t("suppressionConfirm")))deleteAction(value)}}> <Delete /> {t("supprimer")} </IconButton>
            <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifierAction(data)}}> <Edit /> {t("modifier")} </IconButton>
          </div>;
        }
      }
    }
  ];

  const deleteAction = (id) => {
    setIsLoading(true);
    Service.deleteAll(Constant.access+"/comptabilite", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const modifierAction = (data) => {
    setForUpdate(data)
    form.setFields([
      {name: "type", value: data.type},
      {name: "montant", value: data.montant},
      {name: "information", value: data.information},
      {name: "departement", value: data.departement.id},
    ])
    showModal()
  }

  const calcul = (type) => {
    let result = response.filter((a)=> a.type === type)
    if(result.length === 0) {
      return 0
    }
    let data = result.reduce((a,b)=>a.montant + b.montant)
    return typeof(data) === "number" ? data : data.montant
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{width: '50%'}}
      />
      <PageTitle title={t("Comptabilites")} button={
        <div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={(e)=>{setForUpdate({}); showModal()}}
          >
              {t("newAdd")}
          </Button>
        </div>
      }/>

        <div>
          <Row gutter={16} style={{marginBottom: 20}}>
              <Col className="gutter-row" xxl={4}>
                <label>{t("montant")}</label>
                <br/>
                <Input type="number" onChange={(e)=> setRecherche({...recherhce, montant: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Action")}</label>
                <br/>
                <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", minWidth: 200, zIndex: 1}}
                          onChange={(e)=>setRecherche({...recherhce, type: e})}
                          options=
                          {
                            [
                              { "value": Constant.Recharge, "label":  t(Constant.Recharge)},
                              { "value": Constant.Retrait, "label":  t(Constant.Retrait)},
                            ]
                          }
                        />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Departements")}</label>
                <br/>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", minWidth: 200, zIndex: 1}}
                    onChange={(e)=>setRecherche({...recherhce, departement: e})}
                    options=
                    {
                      allDepartement.length > 0 && allDepartement.map((element) => {
                        return { "value": element.id, "label": `${element.nom}` }
                      })
                    }
                  />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("information")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, information: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("dateajout")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, datecreation: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <br/>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={(e)=>{afficher(pagination.page, pagination.size)}}
                >
                    {t("chercher")}
                </Button>
              </Col>
          </Row>
          {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title={
              <>
                <h2>{`${t("total")}: ${pagination.totalElements}`}</h2>
                <p>
                  {(recherhce.type !== Constant.Retrait && calcul(Constant.Recharge) > 0) &&
                    <>
                      <strong style={{color: 'green'}}>{t("Recharge")}:</strong> {(response.length > 0) && calcul(Constant.Recharge).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} FCFA
                      <br/>
                    </>
                  }
                  {(recherhce.type !== Constant.Recharge && calcul(Constant.Retrait) > 0) &&
                    <>
                      <strong style={{color: 'red'}}>{t("Retrait")}:</strong> {response.length > 0 && calcul(Constant.Retrait).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} FCFA
                      <br/>
                    </>
                  }
                  {(recherhce.type === undefined && (calcul(Constant.Recharge) - calcul(Constant.Retrait)) > 0) &&
                    <>
                      <strong>{t("Reste")}:</strong> {response.length > 0 && (calcul(Constant.Recharge) - calcul(Constant.Retrait)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} FCFA
                    </>
                  }
                </p>
              </>
            }
            data={response}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: true,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: pagination.size,
              count: pagination.totalElements,
              // textLabels: Constant.textLabels,
              selectableRows: "none",
              onChangePage(currentPage) {
                afficher(currentPage, pagination.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                afficher(0, numberOfRows)
              },
            }}
          />
        </div>
{/* Information */}
      <Modal zIndex={1200} width={1000} title={<p style={{borderBottom: "1px solid black"}}>{t("information")}</p>} open={isModalOpenInformation} footer={null} onCancel={handleCancel} >
        <div dangerouslySetInnerHTML={{ __html: information }} />
      </Modal>

{/* Activité */}
      <Modal zIndex={1200} width={1000} title={<p style={{borderBottom: "1px solid black"}}>{t("newProjets")}</p>} open={isModalOpen} footer={null} onCancel={handleCancel} >
        <div>
          <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Row gutter={24}>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                    label={t("montant")}
                    name="montant"
                    rules={[
                      {
                        required: true,
                        message: t("champObligatoire"),
                      },
                    ]}
                  >
                    <Input type="number"/>
                  </Form.Item>
                </Col>
                <br/>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                <Form.Item
                      label={t("Action")}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: t("champObligatoire"),
                        },
                      ]}
                    >
                      <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options=
                          {
                            [
                              { "value": Constant.Recharge, "label":  t(Constant.Recharge)},
                              { "value": Constant.Retrait, "label":  t(Constant.Retrait)},
                            ]
                          }
                        />
                    </Form.Item>
                </Col>
                <br/>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                  <Form.Item
                      label={t("lierA")}
                      name="departement"
                      rules={[
                        {
                          required: true,
                          message: t("champObligatoire"),
                        },
                      ]}
                    >
                      <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options=
                          {
                            allDepartement.length > 0 && allDepartement.map((element) => {
                              return { "value": element.id, "label": `${element.nom}` }
                            })
                          }
                        />
                    </Form.Item>
                </Col>
                <br/>
                <Col className="gutter-row" span={12} style={{marginBottom: 10}}>
                    <Form.Item
                        label={t("information")}
                        name="information"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                        // labelCol={{
                        //   span: 24,
                        // }}
                        // wrapperCol={{
                        //   span: 24,
                        // }}
                      >
                        <CKEditor
                            editor={ ClassicEditor }
                            data={(forUpdate.information !== null && forUpdate.information !== undefined) ? forUpdate.information : ""}
                            onChange={ ( event, editor ) => {
                              form.setFieldValue("information", editor.getData())
                            }}
                        />
                      </Form.Item>
                </Col>
              </Row>
              
              <br/>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                    {forUpdate.id !== undefined ? t("modifier") : t("ajouter")}
                  </Button2>
                )}
                <Button2 onClick={handleCancel} type="primary" danger>
                  {t("annuler")}
                </Button2>
              </Form.Item>

            </Form>
        </div>
      </Modal>
    </div>
  );
}
export default withTranslation()(Comptabilite)