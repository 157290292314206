import React, { useEffect, useState } from "react";

// styles
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, CircularProgress, IconButton, LinearProgress } from "@material-ui/core";
import { Add, Cancel, Check, Delete, Edit, Group, RemoveRedEye, Search } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { Button as Button2, Col, ConfigProvider, DatePicker, Form, Input, Modal, Row, Select, Tag } from "antd";
import locale from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import ReactCountryFlagsSelect from "react-country-flags-select";
import Tree from 'react-d3-tree';
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from "react-toastify";
import { Constant } from "../../../Constant";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";
import useStyles from "./styles";
import "./tree.css";

dayjs.locale('fr');


function Departement(props) {
  const {t} = props;
  var classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenMembres, setIsModalOpenMembres] = useState(false);
  const [isModalOpenInformation, setIsModalOpenInformation] = useState(false);
  const [isModalOpenTree, setIsModalOpenTree] = useState(false);
  var [information, setInformation] = useState("");
  var [choix, setChoix] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var [response, setResponse] = useState([]);
  var [responseMembre, setResponseMembre] = useState([]);
  var [allAssemble, setAllAssemble] = useState([]);
  var [depMembre, setDepMembre] = useState({});
  var [forUpdate, setForUpdate] = useState({});
  var [orgChart, setOrgChart] = useState({});
  var [recherhce, setRecherche] = useState({});
  var [membreRecherche, setMembreRecherche] = useState({});
  const theme = useTheme();
  var [treeParam, setTreeParam] = useState({
    orientation: "vertical",
    chemin: "diagonal",
    espace: 2,
  });
  var [pagination, setPagination] = useState({page: 0, size: 50, totalElements: 0});
  var [paginationMembre, setPaginationMembre] = useState({page: 0, size: 50, totalElements: 0});
  let search = "";
  const { RangePicker } = DatePicker;
  let formatDateFrançais = "DD/MM/YYYY"
  let formatDateAnglais = "YYYY-MM-DD"


  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpenMembres(false);
    setIsModalOpenInformation(false);
  };

  useEffect(()=>{
    (()=>{
      afficher(pagination.page, pagination.size)
      getAllAssemble("&search=true", 0, 5000)
    })();
  }, [])

  const getAllAssemble = (searchFait, page, size) => {
    setIsLoading(true);
    Service.getAll(Constant.access+"/assemble", page, size, searchFait, true).then(
      async (response) => {
        setAllAssemble(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const afficher = async (page, size) => {
    search = "&search="
    if(recherhce.nom !== undefined && recherhce.nom.trim() !== ""){
      search = `${search}t.nom LIKE _1_${recherhce.nom}_2_ AND `
    }
    if(recherhce.assemble !== undefined && recherhce.assemble.trim() !== ""){
      search = `${search}t.assemble.id='${recherhce.assemble}' AND `
    }
    if(recherhce.active !== undefined && recherhce.active.trim() !== ""){
      search = `${search}t.active=${recherhce.active} AND `
    }
    if(recherhce.datecreation !== undefined && recherhce.datecreation.length > 0 && recherhce.datecreation[1].trim() !== ""){
      let debut  = moment(recherhce.datecreation[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.datecreation[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}t.Datecreation BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    if(recherhce.dateajout !== undefined && recherhce.dateajout.length > 0 && recherhce.dateajout[1].trim() !== ""){
      let debut  = moment(recherhce.dateajout[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.dateajout[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}t.Dateajout BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    getAll((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size)
    getDepartementsTree()
  }

  const getDepartementsTree = () => {
    setIsLoading(true);
    Service.allStatistique(Constant.access+"/departementsTree", "", {}, true).then(
      async (response) => {
        setOrgChart(response.data.data)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const getAll = (searchFait, page, size) => {
    setIsLoading(true);
     searchFait = `${searchFait} ORDER BY t.Dateajout DESC`
    Service.getAll(Constant.access+"/departements", page, size, searchFait, true).then(
      async (response) => {
        setPagination({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setResponse(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }
  const onFinish = (values) => {
    setIsLoading(true);
    search =  `?search=${(values.attach_departement && values.attach_departement.length > 0) !== undefined ? values.attach_departement : []}&assembleId=${values.assembleId}`
    if(forUpdate.id === undefined) {
      Service.addAll(Constant.access+"/departements", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    } else {
      values.id = forUpdate.id
      Service.putAll(Constant.access+"/departements", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          handleCancel()
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    }
  };

  const columns = [
    {
      name: "nom",
      label: t("nom"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "datecreation",
      label: t("Datecreation"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      name: "enfant",
      label: t("enfant"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>
            {value.length > 0 && value.map((element, index)=>{
              return <p key={index+"a"} style={{backgroundColor:"rgba(255, 0, 0, 0.1)"}}>{element.enfant.nom}</p>
            })}
          </div>
       }
      }
    },
    {
      name: "parent",
      label: t("parent"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>
            {value.length > 0 && value.map((element, index)=>{
              return <p key={index+"b"} style={{backgroundColor:"rgba(0, 255, 0, 0.1)"}}>{element.parent.nom}</p>
            })}
          </div>
        }
       }
    },
    {
      name: "assemble.nom",
      label: t("Assemblees"),
      options: {
        filter: false,
        sort: false,
       }
    },
    {
      name: "information",
      label: t("information"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <IconButton style={{color: 'blue', fontSize: 15}} onClick={(e)=>{setInformation(value); setIsModalOpenInformation(true)}}> <RemoveRedEye /> Voir </IconButton>;
        }
       }
    },
    {
      name: "active",
      label: t("Statut"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <Tag color="success">{t("active")}</Tag> : <Tag color="error">{t("desactive")}</Tag>;
        }
       }
    },
    {
      name: "dateAjout",
      label: t("dateajout"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      label: t("Action"),
      name: "id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
            <IconButton style={{color: 'blue', fontSize: 15}} onClick={(e)=> {gestionMembres(data)}}> <Group /> {t("Membres")} </IconButton>
            <IconButton style={{color: data.active ? 'red' : 'green', fontSize: 15}} onClick={(e)=> {changeStatus(value)}}> {data.active ? <span><Cancel /> {t("desactiver")}</span> : <span><Check /> {t("activer")}</span>} </IconButton>
            <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm(t("suppressionConfirm")))deleteAction(value)}}> <Delete /> {t("supprimer")} </IconButton>
            <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifierAction(data)}}> <Edit /> {t("modifier")} </IconButton>
          </div>;
        }
      }
    }
  ];

  const changeStatus = (id) => {
    setIsLoading(true);
    Service.changeStatutAll(Constant.access+"/changeADepartementsStatut", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const deleteAction = (id) => {
    setIsLoading(true);
    Service.deleteAll(Constant.access+"/departements", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const modifierAction = (data) => {
    setForUpdate(data)
    let attach_departement = []
    data.parent.forEach(element => {
      attach_departement.push(element.parent.id)
    });
    form.setFields([
      {name: "logo", value: data.logo},
      {name: "nom", value: data.nom},
      {name: "adresse", value: data.adresse},
      {name: "datecreation", value: dayjs(moment(forUpdate.datecreation).format(formatDateFrançais), formatDateFrançais)},
      {name: "longitude", value: data.longitude},
      {name: "latitude", value: data.latitude},
      {name: "information", value: data.information},
      {name: "assembleId", value: data.assemble.id},
      {name: "attach_departement", value: attach_departement},
    ])
    showModal()
  }

  const gestionMembres = (data) => {
    setDepMembre(data)
    setChoix("")
    setResponseMembre([])
    setMembreRecherche({})
    setIsModalOpenMembres(true)
  }

  const afficherMembres = async (page, size, choix) => {
    search = "&search="
    if(membreRecherche.prenom !== undefined && membreRecherche.prenom.trim() !== ""){
      search = `${search}prenom LIKE _1_${membreRecherche.prenom}_2_ AND `
    }
    if(membreRecherche.nom !== undefined && membreRecherche.nom.trim() !== ""){
      search = `${search}nom LIKE _1_${membreRecherche.nom}_2_ AND `
    }
    if(membreRecherche.genre !== undefined && membreRecherche.genre.trim() !== ""){
      search = `${search}genre LIKE _1_${membreRecherche.genre}_2_ AND `
    }
    if(membreRecherche.telephone !== undefined && membreRecherche.telephone.trim() !== ""){
      search = `${search}telephone LIKE _1_${membreRecherche.telephone}_2_ AND `
    }
    if(membreRecherche.adresse !== undefined && membreRecherche.adresse.trim() !== ""){
      search = `${search}adresse LIKE _1_${membreRecherche.adresse}_2_ AND `
    }
    if(membreRecherche.ethni !== undefined && membreRecherche.ethni.trim() !== ""){
      search = `${search}ethni LIKE _1_${membreRecherche.ethni}_2_ AND `
    }
    if(membreRecherche.nationalite !== undefined && membreRecherche.nationalite.trim() !== ""){
      search = `${search}nationalite LIKE _1_${membreRecherche.nationalite}_2_ AND `
    }
    if(membreRecherche.stuation_matrimoniale !== undefined && membreRecherche.stuation_matrimoniale.trim() !== ""){
      search = `${search}stuation_matrimoniale LIKE _1_${membreRecherche.stuation_matrimoniale}_2_ AND `
    }
    if(membreRecherche.profession !== undefined && membreRecherche.profession.trim() !== ""){
      search = `${search}profession LIKE _1_${membreRecherche.profession}_2_ AND `
    }
    if(membreRecherche.culte !== undefined && membreRecherche.culte.trim() !== ""){
      search = `${search}culte LIKE _1_${membreRecherche.culte}_2_ AND `
    }
    if(membreRecherche.statut_actuelle !== undefined && membreRecherche.statut_actuelle.trim() !== ""){
      search = `${search}statut_actuelle LIKE _1_${membreRecherche.statut_actuelle}_2_ AND `
    }
    if(membreRecherche.datenaissance !== undefined && membreRecherche.datenaissance.length > 0 && membreRecherche.datenaissance[1].trim() !== ""){
      let debut  = moment(membreRecherche.datenaissance[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(membreRecherche.datenaissance[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}date_naissance BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }

      search = `${search}t.assemble.id='${depMembre.assemble.id}' AND `

    if(choix === t("nouveauMembres")) {
      search = `${search} t.id not in (select a.membres.id from AttacheMembreDepartement a where a.departement.id = '${depMembre.id}') AND `
    } else {
      search = `${search} t.id in (select a.membres.id from AttacheMembreDepartement a where a.departement.id = '${depMembre.id}') AND `
    }

    getAllMembres((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size, choix)
  }

  const addAdminDepartement = (idMembre, idDepartement) => {
    setIsLoading(true);
    let values = {
      idMembre: idMembre,
      idDepartement: idDepartement,
    }
    Service.putAll(Constant.access+"/addAdminDepartement", "", values, true).then(
      async (response) => {
          toast.success(t(response.data.message))
          afficherMembres(paginationMembre.page, paginationMembre.size, choix)
          setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const getAllMembres = (searchFait, page, size, choix) => {
    setIsLoading(true);
    Service.getAll(Constant.access+"/membres", page, size, searchFait, true).then(
      async (response) => {
          setPaginationMembre({
            page: page,
            size: size,
            totalElements: response.data.totalElements
          })
          setResponseMembre(response.data.data)
          setChoix(choix)
          setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const changeMembre = (idMembres, choix) => {
    setIsLoading(true);
    search =  `?search=${(idMembres && idMembres.length > 0) !== undefined ? idMembres : []}&departementId=${depMembre.id}`
    if(choix === t("nouveauMembres")) {
      Service.addAll(Constant.access+"/departementMembres", search, {}, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          afficherMembres(paginationMembre.page, paginationMembre.size, choix)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    } else if(choix === t("ancienMembres")) {
      Service.putAll(Constant.access+"/departementMembres", search, {}, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          afficherMembres(paginationMembre.page, paginationMembre.size, choix)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    }
  }

  const columns2 = [
    {
      name: "prenom",
      label: t("prenom"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "nom",
      label: t("nomP"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "genre",
      label: t("genre"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "date_naissance",
      label: t("datenaissance"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      name: "lieu_naissance",
      label: t("lieunaissance"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "telephone",
      label: t("phone"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "email",
      label: t("email"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "adresse",
      label: t("adresse"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "ethni",
      label: t("ethnie"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "nationalite",
      label: t("Nationalite"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let val = JSON.parse(value);
          return val.label;
        }
       }
    },
    {
      name: "stuation_matrimoniale",
      label: t("matrimonial"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "profession",
      label: t("Profession"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "assemble.nom",
      label: t("newAssemble"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "culte",
      label: t("Culte"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "roledanseglise",
      label: t("roleEglise"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "statut_actuelle",
      label: t("statutactuelle"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "departementresponsable",
      label: t("administrateur"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value !== undefined && value !== null && value.includes(depMembre.id)) ? t("oui") : t("non");
        }
      }
    },
    {
      label: t("Action"),
      name: "id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
            <IconButton style={{color: (data.departementresponsable !== null && data.departementresponsable !== undefined && data.departementresponsable.includes(depMembre.id)) ? 'red' : 'green', fontSize: 15}} onClick={(e)=> {addAdminDepartement(value, depMembre.id)}}> {(data.departementresponsable !== null && data.departementresponsable !== undefined && data.departementresponsable.includes(depMembre.id)) ? <span><Delete /> {t("administrateur")}</span> : <span><Add /> {t("administrateur")}</span>} </IconButton>
          </div>;
        }
      }
    }
  ];

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{width: '50%'}}
      />
      <PageTitle title={t("Departements")} button={
        <div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={(e)=>{setForUpdate({}); showModal()}}
          >
              {t("newDepartements")}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={(e)=>{setIsModalOpenTree(true)}}
          >
              {t("Schema")}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={(e)=>{setIsModalOpenTree(false)}}
          >
              {t("table")}
          </Button>
        </div>
      }/>

      {!isModalOpenTree ?
        <div>
          <Row gutter={16} style={{marginBottom: 20}}>
              <Col className="gutter-row" xxl={4}>
                <label>{t("nom")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, nom: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Statut")}</label>
                <br/>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, active: e})}
                        options={[
                          { "value": "", "label":  t("tout")},
                          { "value": "true", "label":  t("active")},
                          { "value": "false", "label":  t("desactive")},
                        ]}
                      />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Assemblees")}</label>
                <br/>
                  <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, assemble: e})}
                        options=
                        {
                          allAssemble.length > 0 && allAssemble.map((element) => {
                            return { "value": element.id, "label": `${element.nom}` }
                          })
                        }
                      />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Datecreation")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, datecreation: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("dateajout")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, dateajout: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <br/>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={(e)=>{afficher(pagination.page, pagination.size)}}
                >
                    {t("chercher")}
                </Button>
              </Col>
          </Row>
          {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title={<h2>{`${t("total")}: ${pagination.totalElements}`}</h2>}
            data={response}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: true,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: pagination.size,
              count: pagination.totalElements,
              // textLabels: Constant.textLabels,
              selectableRows: "none",
              onChangePage(currentPage) {
                afficher(currentPage, pagination.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                afficher(0, numberOfRows)
              },
            }}
          />
        </div>
        :
        <div>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <label>{t("orientation")}</label>
                <br/>
                <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setTreeParam({...treeParam, orientation:  e})}
                      // defaultValue={(state.service.id !== "") && state.service.id}
                      options={[
                        {label: "vertical", value: "vertical"},
                        {label: "horizontal", value: "horizontal"}
                      ]}
                    />
              </Col>
              <Col className="gutter-row" span={6}>
                <label>{t("Chemin")}</label>
                <br/>
                <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setTreeParam({...treeParam, chemin:  e})}
                      // defaultValue={(state.service.id !== "") && state.service.id}
                      options={[
                        {label: "diagonal", value: "diagonal"},
                        {label: "elbow", value: "elbow"},
                        {label: "step", value: "step"},
                        {label: "straight", value: "straight"}
                      ]}
                    />
              </Col>
              <Col className="gutter-row" span={6}>
                <label>{t("Espace")}</label>
                <br/>
                <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setTreeParam({...treeParam, espace:  e})}
                      // defaultValue={(state.service.id !== "") && state.service.id}
                      options=
                        {
                          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((element) => {
                            return { "value": element, "label": `${element}` }
                          })
                        }
                    />
              </Col>
            </Row>
            <div id="treeWrapper" style={{ width: window.screen.width/1.2, height: window.screen.height}}>
              <Tree data={orgChart} 
                rootNodeClassName="node__root"
                branchNodeClassName="node__branch"
                leafNodeClassName="node__leaf"
                separation={{siblings: treeParam.espace, nonSiblings: 2}}
                orientation={treeParam.orientation}
                enableLegacyTransitions={true}
                centeringTransitionDuration={5000}
                pathFunc={treeParam.chemin}
                translate={{x: treeParam.orientation === "horizontal" ? 50 : 531, y: treeParam.orientation === "horizontal" ? 300: 50}}
                zoomable={true}
                draggable={true}
              />
            </div>
        </div>
      }
{/* Information */}
      <Modal zIndex={1200} width={"80%"} title={<p style={{borderBottom: "1px solid black"}}>{t("information")}</p>} open={isModalOpenInformation} footer={null} onCancel={handleCancel} >
        <div dangerouslySetInnerHTML={{ __html: information }} />
      </Modal>

{/* Departements */}
      <Modal zIndex={1200} title={<p style={{borderBottom: "1px solid black"}}>{t("newDepartements")}</p>} open={isModalOpen} footer={null} onCancel={handleCancel} >
        <div>
          <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label={t("nom")}
                name="nom"
                rules={[
                  {
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <br/>
              <Form.Item
                label={t("Datecreation")}
                name="datecreation"
                rules={[
                  {
                    type: 'object',
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
                >
                <ConfigProvider locale={locale}>
                    <DatePicker style={{width: '100%'}}
                      defaultValue={forUpdate.id !== undefined && dayjs(moment(forUpdate.datecreation).format(formatDateFrançais), formatDateFrançais)}
                      format={formatDateFrançais}
                      placeholder=""
                      onChange={ (date, dateString) => {
                        form.setFieldValue("datecreation", date)
                      }}
                    />
                </ConfigProvider>
              </Form.Item>
              <br/>
              <Form.Item
                label={t("Assemblees")}
                name="assembleId"
                rules={[
                  {
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
              >
                <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", zIndex: 1}}
                    options=
                    {
                      allAssemble.length > 0 && allAssemble.map((element) => {
                        return { "value": element.id, "label": `${element.nom}` }
                      })
                    }
                  />
              </Form.Item>
              <br/>
              <Form.Item
                label={t("lierA")}
                name="attach_departement"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", zIndex: 1}}
                    options=
                    {
                      response.length > 0 && response.map((element) => {
                        return { "value": element.id, "label": `${element.nom}` }
                      })
                    }
                  />
              </Form.Item>
              <br/>
              <Form.Item
                label={t("information")}
                name="information"
                rules={[
                  {
                    required: false,
                  },
                ]}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
              >
                <CKEditor
                    editor={ ClassicEditor }
                    data={(forUpdate.information !== null && forUpdate.information !== undefined) ? forUpdate.information : ""}
                    onChange={ ( event, editor ) => {
                      form.setFieldValue("information", editor.getData())
                    }}
                />
              </Form.Item>
              <br/>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                    {forUpdate.id !== undefined ? t("modifier") : t("ajouter")}
                  </Button2>
                )}
                <Button2 onClick={handleCancel} type="primary" danger>
                  {t("annuler")}
                </Button2>
              </Form.Item>

            </Form>
        </div>
      </Modal>

{/* Membres */}
      <Modal zIndex={1200} width={"80%"} title={<p style={{borderBottom: "1px solid black"}}>{`${t("Membres")} (${t("Departements")}: ${depMembre.nom})`}</p>} open={isModalOpenMembres} footer={null} onCancel={handleCancel} >
          <div>
            <Row gutter={16} style={{marginBottom: 20}}>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("prenom")}</label>
                  <br/>
                  <Input onChange={(e)=> setMembreRecherche({...recherhce, membreRecherche: e.target.value})}/>
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("nom")}</label>
                  <br/>
                  <Input onChange={(e)=> setMembreRecherche({...membreRecherche, nom: e.target.value})}/>
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("genre")}</label>
                  <br/>
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1, minWidth: 100}}
                      onChange={(e)=>setMembreRecherche({...membreRecherche, genre: e})}
                      options={[
                        { "value": Constant.homme, "label": t(Constant.homme) },
                        { "value": Constant.femme, "label": t(Constant.femme) },
                      ]}
                    />
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("datenaissance")}</label>
                  <br/>
                  <ConfigProvider locale={locale}>
                    <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setMembreRecherche({...membreRecherche, datenaissance: datesString})} placeholder={[t("du"), t("au")]}/>
                  </ConfigProvider>
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("phone")}</label>
                  <br/>
                  <PhoneInput
                      onChange={(e)=> setMembreRecherche({...membreRecherche, telephone: e})}
                      country={"ml"}
                      inputProps={{
                          name: 'telephone',
                          required: true,
                          autoFocus: true,
                        }}
                        inputStyle={{width: "100%", height:30}}
                  />
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("adresse")}</label>
                  <br/>
                  <Input onChange={(e)=> setMembreRecherche({...membreRecherche, adresse: e.target.value})}/>
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("ethnie")}</label>
                  <br/>
                  <Input onChange={(e)=> setMembreRecherche({...membreRecherche, ethni: e.target.value})}/>
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("Nationalite")}</label>
                  <br/>
                  <ReactCountryFlagsSelect fullWidth searchable 
                        searchPlaceholder=""
                          onSelect={(e)=> {
                            if(e !== null) {
                              setMembreRecherche({...membreRecherche, nationalite: e.label})
                            }
                          }} 
                        />
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("matrimonial")}</label>
                  <br/>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", zIndex: 1}}
                    onChange={(e)=>setMembreRecherche({...membreRecherche, stuation_matrimoniale: e})}
                    options={[
                      { "value": Constant.celibataire, "label": t(Constant.celibataire) },
                      { "value": Constant.marie, "label": t(Constant.marie) },
                      { "value": Constant.veuve, "label": t(Constant.veuve) },
                      { "value": Constant.divorcee, "label": t(Constant.divorcee) },
                    ]}
                  />
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("Profession")}</label>
                  <br/>
                  <Input onChange={(e)=> setMembreRecherche({...membreRecherche, profession: e.target.value})}/>
                </Col>
                <Col className="gutter-row" xxl={3}>
                  <label>{t("Culte")}</label>
                  <br/>
                  <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1, minWidth: 150}}
                      onChange={(e)=>setMembreRecherche({...membreRecherche, culte: e})}
                      options={[
                        { "value": Constant.Bambara, "label": t(Constant.Bambara) },
                        { "value": Constant.Français, "label": t(Constant.Français) },
                        { "value": Constant.Anglais, "label": t(Constant.Anglais) },
                        { "value": Constant.Bilingue, "label": t(Constant.Bilingue) },
                      ]}
                    />
                </Col>
                <Col className="gutter-row" xxl={4}>
                  <label>{t("statutactuelle")}</label>
                  <br/>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", zIndex: 1}}
                    onChange={(e)=>setMembreRecherche({...membreRecherche, statut_actuelle: e})}
                    options={[
                      { "value": Constant.Present, "label": t(Constant.Present) },
                      { "value": Constant.deces, "label": t(Constant.deces) },
                      { "value": Constant.depart, "label": t(Constant.depart) },
                      { "value": Constant.Autre, "label": t(Constant.Autre) },
                    ]}
                  />
                </Col>
                <Col className="gutter-row" xxl={4}>
                  <Button2 onClick={(e)=>{afficherMembres(paginationMembre.page, paginationMembre.size, t("nouveauMembres"))}} style={{backgroundColor: 'rgba(0,0,255, 0.6)', color: 'white', marginTop: 20, fontSize: 15}}>
                    <Search style={{height: 20, width: 20}}/> {t("nouveauMembres")}
                  </Button2>
                </Col>
                <Col className="gutter-row" xxl={4}>
                  <Button2 onClick={(e)=>{afficherMembres(paginationMembre.page, paginationMembre.size, t("ancienMembres"))}} style={{backgroundColor: 'rgba(0,0,255, 0.6)', color: 'white', marginTop: 20, fontSize: 15}}>
                    <Search style={{height: 20, width: 20}}/> {t("ancienMembres")}
                  </Button2>
                </Col>
              </Row>

              {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
              <MUIDataTable
                title={<h2>{choix}</h2>}
                data={responseMembre}
                columns={columns2}
                options={{
                  draggableColumns: { enabled: true },
                  enableNestedDataAccess: '.',
                  filterType: "multiselect",
                  selectableRows: "multiple",
                  selectableRowsHeader: true,
                  jumpToPage: true,
                  serverSide: true,
                  rowsPerPageOptions:[50, 100, 500, 1000],
                  rowsPerPage: paginationMembre.size,
                  count: paginationMembre.totalElements,
                  customToolbarSelect: (selectedRows) => 
                  {
                    let idMembres = []
                    selectedRows.data.forEach((element, index) => {
                      if(responseMembre[element.dataIndex] !== undefined) {
                        idMembres.push(responseMembre[element.dataIndex].id)
                      }
                    });
                    return <span style={{backgroundColor: choix === t("nouveauMembres") ? "rgba(0, 255, 0, 0.4)" : "rgba(255, 0, 0, 0.4)", marginRight: 10}}>
                      <IconButton size="small" className={classes.iconButton} onClick={(e)=>changeMembre(idMembres, choix)}>
                        {choix === t("nouveauMembres") 
                        ?
                          <><Add /> {t("ajouter")}</>
                        :
                          <><Delete /> {t("supprimer")}</>
                      }
                      </IconButton>
                    </span>
                  },
                  textLabels: {
                    selectedRows: {
                      text: t("rowSelect"),
                    },
                  },
                  onChangePage(currentPage) {
                    afficherMembres(currentPage, paginationMembre.size)
                  },
                  onChangeRowsPerPage(numberOfRows) {
                    afficherMembres(0, numberOfRows)
                  },
                }}
              />
          </div>
      </Modal>
    </div>
  );
}
export default withTranslation()(Departement)