export const Constant = {
    appname: "ekklesia.",
    fr: "fr",
    ml: "ml",
    en: "en",
    anonyme: "/anonyme",
    access: "/access",
    uuidInit: "00000000-0000-0000-0000-000000000000",
    couleurSpinnerLine: "primary",

    ROLE_USERS: "ROLE_USERS",
    ROLE_DEPARTEMENT: "ROLE_DEPARTEMENT",
    ROLE_ASSEMBLE: "ROLE_ASSEMBLE",
    ROLE_BETIW_ENSEIGNEMENT_FR: "ROLE_BETIW_ENSEIGNEMENT_FR",
    ROLE_BETIW_ENSEIGNEMENT_ML: "ROLE_BETIW_ENSEIGNEMENT_ML",
    ROLE_BETIW_ENSEIGNEMENT_EN: "ROLE_BETIW_ENSEIGNEMENT_EN",
    ROLE_BETIW_QUIZ: "ROLE_BETIW_QUIZ",
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_MEMBRES: "ROLE_MEMBRES",
    ROLE_MARIAGE: "ROLE_MARIAGE",
    ROLE_ACTIVITE: "ROLE_ACTIVITE",
    ROLE_RESPONSABLE_DEPARTEMENT: "ROLE_RESPONSABLE_DEPARTEMENT",
    ROLE_RESPONSABLE_ADJOINT_DEPARTEMENT: "ROLE_RESPONSABLE_ADJOINT_DEPARTEMENT",
    ROLE_PROJET_DEPARTEMENT: "ROLE_PROJET_DEPARTEMENT",
    ROLE_COMPTABLE_DEPARTEMENT: "ROLE_COMPTABLE_DEPARTEMENT",
    ROLE_COMPTABLE_ADJOINT_DEPARTEMENT: "ROLE_COMPTABLE_ADJOINT_DEPARTEMENT",
    ROLE_SMS: "ROLE_SMS",
    ROLE_CULTE: "ROLE_CULTE",
    ROLE_STATISTIQUE_ASSEMBLE: "ROLE_STATISTIQUE_ASSEMBLE",
    ROLE_STATISTIQUE_DEPARTEMENT: "ROLE_STATISTIQUE_DEPARTEMENT",
    ROLE_RESPONSABLE_ASSEMBLE: "ROLE_RESPONSABLE_ASSEMBLE",
    ROLE_RESPONSABLE_ADJOINT_ASSEMBLE: "ROLE_RESPONSABLE_ADJOINT_ASSEMBLE",

    homme: "Homme",
    femme: "Femme",
    jeuneHomme: "jeuneHomme",
    jeuneFemme: "jeuneFemme",
    enfantHomme: "enfantHomme",
    enfantFemme: "enfantFemme",
    celibataire: "celibataire",
    marie: "marie",
    veuve: "veuve",
    divorcee: "divorcee",
    Bambara: "Bambara",
    Français: "Français",
    Anglais: "Anglais",
    Bilingue: "Bilingue",
    Present: "Present",
    deces: "deces",
    depart: "depart",
    Autre: "Autre",
    Jours: "Jours",
    Semaines: "Semaines",
    Mois: "Mois",
    Années: "Années",
    Recharge: "Recharge",
    Retrait: "Retrait",
    Activites: "Activites",
    Projets: "Projets",
    paiementWeb: "paiementWeb",
    mode: "REEL",
    lien_logo_entreprise: "https://www.sevob.net/SEVOB.png",
}