import {
  AppBar,
  CircularProgress,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Toolbar
} from "@material-ui/core";
import {
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  Edit,
  Menu as MenuIcon,
  Send as SendIcon
} from "@material-ui/icons";
import classNames from "classnames";
import React, { useState } from "react";

// styles
import useStyles from "./styles";

// components
import { encode as btoa } from 'base-64';
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import { Typography } from "../Wrappers";
// context
import { Button as Button2, Form, Input, Modal, Select, Space } from "antd";
import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { Constant } from "../../Constant";
import {
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import { loginUser, signOut, useUserDispatch } from "../../context/UserContext";
import fr from "../../images/fr.png";
import login from "../../images/login.gif";
import ml from "../../images/ml.png";
import us from "../../images/us.png";
import Service from "../../service";

const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

function Header(props) {
  var classes = useStyles();
  const { t } = props;
  let user = JSON.parse(localStorage.getItem('user_info'));
  let membre = JSON.parse(localStorage.getItem('membre_info'));
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);

  const [isModalOpenAuthentification, setIsModalOpenAuthentification] = useState(false);
  const [isModalOpenConnexion, setIsModalOpenConnexion] = useState(false);
  var [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    const token = btoa(user.username + ':' + values.ancienpassword);
    setIsLoading(true);
    Service.modifierMotDePasse(Constant.access+"/modifierMotDePasse", values, token).then(
      async (response) => {
        setIsLoading(false);
          const id_token = btoa(user.username + ':' + values.nouveaupassword);
          localStorage.setItem('id_token', id_token)
          toast.success(t(response.data.message))
          setTimeout(() => {
            window.location.reload()
          }, 5000);
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data !== "" ? t(error.response.data.message) : t("accesRefuse")))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
  };


  const handleCancel = () => {
    setIsModalOpenAuthentification(false);
    setIsModalOpenConnexion(false);
  };


  const connexion = (values) => {
    setIsLoading(true);
    const token = btoa(values.username + ':' + values.mot_de_passe);
        Service.connexion(token).then(
          async (response) => {
            if(response.data.membre !== null && !response.data.membre.active) {
              toast.error(t("accesRefuse"))
              setIsLoading(false);
            } else {
              loginUser(
                userDispatch,
                values.username,
                values.mot_de_passe,
                props.history,
                setIsLoading,
                token,
                response.data
              )
            }
          }, error => {
            setIsLoading(false);
            toast.error(t("accesRefuse"))
          }
        )
  }
  

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{width: '50%'}}
      />
      <Toolbar className={classes.toolbar}>
        {user !== null &&
          <IconButton
            color="inherit"
            onClick={() => toggleSidebar(layoutDispatch)}
            className={classNames(
              classes.headerMenuButtonSandwich,
              classes.headerMenuButtonCollapse,
            )}
          >
            {layoutState.isSidebarOpened ? (
              <ArrowBackIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            ) : (
              <MenuIcon
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            )}
          </IconButton>
        }
        <Typography variant="h2" weight="medium" className={classes.logotype}>
          {Constant.appname}
        </Typography>
        <div className={classes.grow} />
        {/* <Button component={Link} href="https://flatlogic.com/templates/react-material-admin-full" variant={"outlined"} color={"secondary"} className={classes.purchaseBtn}>Unlock full version</Button>
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div> */}
        <div>
        <Select
          style={{ width: 200 }}
          defaultValue={props.i18n.language}
          onChange={(e)=>props.i18n.changeLanguage(e)}
          labelRender={(option) => {
            return <Space>
                      <img width={25} height={15} src={option.value === Constant.ml && ml || option.value === Constant.fr && fr || option.value === Constant.en && us }/> {option.label}
                    </Space>
          }}
          dropdownStyle={{zIndex: 2000}}
                  options={[
                    { value: Constant.ml, label: t("Bambara"), img: ml },
                    { value: Constant.fr, label: t("Français"), img: fr },
                    { value: Constant.en, label: t("Anglais"), img: us },
                  ]}
          optionRender={(option) => (
            <Space>
              <img width={25} height={15} src={option.data.img}/> {option.data.label}
            </Space>
          )}
        />
        </div>
        {/* <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isNotificationsUnread ? notifications.length : null}
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton> */}
        {user === null &&
          <IconButton
            color="inherit"
            aria-haspopup="true"
            aria-controls="mail-menu"
            onClick={e => {
              setIsModalOpenConnexion(true)
            }}
            className={classes.headerMenuButton}
          >
            <AccountIcon classes={{ root: classes.headerIcon }} /> {t("seconnecter")}
          </IconButton>
        }
        {user !== null &&
          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={e => setProfileMenu(e.currentTarget)}
          >
            <AccountIcon classes={{ root: classes.headerIcon }} />
          </IconButton>
        }
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              New Messages
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} New Messages
            </Typography>
          </div>
          {messages.map(message => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map(notification => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
          onClick={(e)=> {
            if(e.target.value === 0) {
              setIsModalOpenAuthentification(true); setProfileMenu(null)
            }
          }}
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {membre !== null && membre.prenom} {membre !== null && membre.nom}
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="primary"
            >
              {user !== null && user.username}
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <Edit className={classes.profileMenuIcon} /> {t("changermotdepasse")}
          </MenuItem>
          {/* <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Tasks
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Messages
          </MenuItem> */}
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              {t("sedeconnecter")}
            </Typography>
          </div>
        </Menu>
      </Toolbar>
{/* Authentification */}
      <Modal zIndex={1200} width={500} title={<p style={{borderBottom: "1px solid black"}}>{`${t("seconnecter")}`}</p>} open={isModalOpenConnexion} footer={null} onCancel={handleCancel} >
          <div style={{textAlign: 'center'}}>
            <img src={login} alt="logo" />
          </div>
          <Form
              name="basic2"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              onFinish={connexion}
              autoComplete="off"
            >
              <Form.Item
                label={t("username")}
                name="username"
                rules={[
                  {
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("password")}
                name="mot_de_passe"
                rules={[
                  {
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 16,
                }}
              >
                <div style={{marginTop: 20}}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                      {t("connexion")}
                    </Button2>
                  )}
                </div>
              </Form.Item>
            </Form>
      </Modal>
{/* Change password */}
      <Modal zIndex={1200} width={500} title={<p style={{borderBottom: "1px solid black"}}>{`${t("changermotdepasse")}`}</p>} open={isModalOpenAuthentification} footer={null} onCancel={handleCancel} >
          <Form
              name="basic1"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label={t("ancienpassword")}
                name="ancienpassword"
                rules={[
                  {
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={t("nouveaupassword")}
                name="nouveaupassword"
                rules={[
                  {
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={t("confirmationpassword")}
                name="confirmationpassword"
                rules={[
                  {
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 16,
                }}
              >
                <div style={{marginTop: 20}}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                      {t("modifier")}
                    </Button2>
                  )}
                  <Button2 onClick={handleCancel} type="primary" danger>
                    {t("annuler")}
                  </Button2>
                </div>
              </Form.Item>
            </Form>
      </Modal>
    </AppBar>
  );
}

export default withTranslation()(Header)
