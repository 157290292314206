import React, { useEffect, useState } from "react";

// styles
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, CircularProgress, IconButton, LinearProgress } from "@material-ui/core";
import { Cancel, Check, Delete, Edit, Person, RemoveRedEye } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { Button as Button2, Checkbox, Col, ConfigProvider, DatePicker, Form, Image, Input, Modal, Row, Select, Tag } from "antd";
import locale from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import ReactCountryFlagsSelect from "react-country-flags-select";
import { withTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from "react-toastify";
import { Constant } from "../../../Constant";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Service from "../../../service";
import useStyles from "./styles";
import "./tree.css";

dayjs.locale('fr');


let photo = "";
function Membres(props) {
  const {t} = props;
  var classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenInformation, setIsModalOpenInformation] = useState(false);
  const [isModalOpenAuthentification, setIsModalOpenAuthentification] = useState(false);
  const [isModalOpenRole, setIsModalOpenRole] = useState(false);
  const [isModalOpenTree, setIsModalOpenTree] = useState(false);
  var [information, setInformation] = useState("");
  const [value, setValue] = useState()
  var [isLoading, setIsLoading] = useState(false);
  var [roles, setRoles] = useState([]);
  var [defautRole, setDefautRole] = useState([]);
  var [roleSelect, setRoleSelect] = useState([]);
  var [response, setResponse] = useState([]);
  var [allAssemble, setAllAssemble] = useState([]);
  var [forUpdate, setForUpdate] = useState({});
  var [parent, setParent] = useState({});
  var [orgChart, setOrgChart] = useState({});
  var [recherhce, setRecherche] = useState({});
  const theme = useTheme();
  var [treeParam, setTreeParam] = useState({
    orientation: "vertical",
    chemin: "diagonal",
    espace: 2,
  });
  var [pagination, setPagination] = useState({page: 0, size: 50, totalElements: 0});
  let search = "";
  const { RangePicker } = DatePicker;
  let formatDateFrançais = "DD/MM/YYYY"
  let formatDateAnglais = "YYYY-MM-DD"


  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpenInformation(false);
    setIsModalOpenAuthentification(false);
    setIsModalOpenRole(false);
  };

  useEffect(()=>{
    (()=>{
      afficher(pagination.page, pagination.size)
      getAllAssemble("&search=true", 0, 5000)
      getAllRoles(pagination.page, pagination.size, "")
    })();
  }, [])

  const getAllRoles = (page, size, searchFait) => {
    Service.getAll(Constant.access+"/roleList", page, size, searchFait, true).then(
      async (response) => {
        setRoles(response.data)
      })
  }

  const getAllAssemble = (searchFait, page, size) => {
    setIsLoading(true);
    Service.getAll(Constant.access+"/assemble", page, size, searchFait, true).then(
      async (response) => {
        setAllAssemble(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const afficher = async (page, size, genre) => {
    search = "&search="
    if(recherhce.prenom !== undefined && recherhce.prenom.trim() !== ""){
      search = `${search}prenom LIKE _1_${recherhce.prenom}_2_ AND `
    }
    if(recherhce.nom !== undefined && recherhce.nom.trim() !== ""){
      search = `${search}nom LIKE _1_${recherhce.nom}_2_ AND `
    }
    if(recherhce.genre !== undefined && recherhce.genre.trim() !== ""){
      search = `${search}genre LIKE _1_${recherhce.genre}_2_ AND `
    }
    if(genre !== undefined && genre !== null){
      search = `${search}genre LIKE _1_${genre}_2_ AND `
      if(forUpdate.id !== undefined) {
        search = `${search}id!='${forUpdate.id}' AND `
      }
    }
    if(recherhce.datenaissance !== undefined && recherhce.datenaissance.length > 0 && recherhce.datenaissance[1].trim() !== ""){
      let debut  = moment(recherhce.datenaissance[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.datenaissance[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}date_naissance BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    if(recherhce.lieu_naissance !== undefined && recherhce.lieu_naissance.trim() !== ""){
      search = `${search}lieu_naissance LIKE _1_${recherhce.lieu_naissance}_2_ AND `
    }
    if(recherhce.telephone !== undefined && recherhce.telephone.trim() !== ""){
      search = `${search}telephone LIKE _1_${recherhce.telephone}_2_ AND `
    }
    if(recherhce.email !== undefined && recherhce.email.trim() !== ""){
      search = `${search}email LIKE _1_${recherhce.email}_2_ AND `
    }
    if(recherhce.adresse !== undefined && recherhce.adresse.trim() !== ""){
      search = `${search}adresse LIKE _1_${recherhce.adresse}_2_ AND `
    }
    if(recherhce.ethni !== undefined && recherhce.ethni.trim() !== ""){
      search = `${search}ethni LIKE _1_${recherhce.ethni}_2_ AND `
    }
    if(recherhce.nationalite !== undefined && recherhce.nationalite.trim() !== ""){
      search = `${search}nationalite LIKE _1_${recherhce.nationalite}_2_ AND `
    }
    if(recherhce.stuation_matrimoniale !== undefined && recherhce.stuation_matrimoniale.trim() !== ""){
      search = `${search}stuation_matrimoniale LIKE _1_${recherhce.stuation_matrimoniale}_2_ AND `
    }
    if(recherhce.profession !== undefined && recherhce.profession.trim() !== ""){
      search = `${search}profession LIKE _1_${recherhce.profession}_2_ AND `
    }
    if(recherhce.pere !== undefined && recherhce.pere.trim() !== ""){
      search = `${search}pere=(SELECT id from Membres WHERE telephone LIKE _1_${recherhce.pere}_2_) AND `
    }
    if(recherhce.mere !== undefined && recherhce.mere.trim() !== ""){
      search = `${search}mere=(SELECT id from Membres WHERE telephone LIKE _1_${recherhce.mere}_2_) AND `
    }
    if(recherhce.eglise_de_provenance !== undefined && recherhce.eglise_de_provenance.trim() !== ""){
      search = `${search}eglise_de_provenance LIKE _1_${recherhce.eglise_de_provenance}_2_ AND `
    }
    if(recherhce.assemble !== undefined && recherhce.assemble.trim() !== ""){
      search = `${search}assemble.id='${recherhce.assemble}' AND `
    }
    if(recherhce.datearrivee !== undefined && recherhce.datearrivee.length > 0 && recherhce.datearrivee[1].trim() !== ""){
      let debut  = moment(recherhce.datearrivee[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.datearrivee[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}datearrivee BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
      }
    if(recherhce.culte !== undefined && recherhce.culte.trim() !== ""){
      search = `${search}culte LIKE _1_${recherhce.culte}_2_ AND `
    }
    if(recherhce.date_conversion !== undefined && recherhce.date_conversion.length > 0 && recherhce.date_conversion[1].trim() !== ""){
      let debut  = moment(recherhce.date_conversion[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.date_conversion[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}date_conversion BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    if(recherhce.lieu_conversion !== undefined && recherhce.lieu_conversion.trim() !== ""){
      search = `${search}lieu_conversion LIKE _1_${recherhce.lieu_conversion}_2_ AND `
    }
    if(recherhce.date_bapteme !== undefined && recherhce.date_bapteme.length > 0 && recherhce.date_bapteme[1].trim() !== ""){
      let debut  = moment(recherhce.date_bapteme[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.date_bapteme[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}date_bapteme BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    if(recherhce.lieu_bapteme !== undefined && recherhce.lieu_bapteme.trim() !== ""){
      search = `${search}lieu_bapteme LIKE _1_${recherhce.lieu_bapteme}_2_ AND `
    }
    if(recherhce.date_bapteme_enfant !== undefined && recherhce.date_bapteme_enfant.length > 0 && recherhce.date_bapteme_enfant[1].trim() !== ""){
      let debut  = moment(recherhce.date_bapteme_enfant[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.date_bapteme_enfant[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}date_bapteme_enfant BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    if(recherhce.auteur_bapteme_enfant !== undefined && recherhce.auteur_bapteme_enfant.trim() !== ""){
      search = `${search}auteur_bapteme_enfant LIKE _1_${recherhce.auteur_bapteme_enfant}_2_ AND `
    }
    if(recherhce.roledanseglise !== undefined && recherhce.roledanseglise.trim() !== ""){
      search = `${search}roledanseglise LIKE _1_${recherhce.roledanseglise}_2_ AND `
    }
    if(recherhce.statut_actuelle !== undefined && recherhce.statut_actuelle.trim() !== ""){
      search = `${search}statut_actuelle LIKE _1_${recherhce.statut_actuelle}_2_ AND `
    }
    if(recherhce.information !== undefined && recherhce.information.trim() !== ""){
      search = `${search}information LIKE _1_${recherhce.information}_2_ AND `
    }
    if(recherhce.active !== undefined && recherhce.active.trim() !== ""){
      search = `${search}active=${recherhce.active} AND `
    }
    if(recherhce.dateInscription !== undefined && recherhce.dateInscription.length > 0 && recherhce.dateInscription[1].trim() !== ""){
      let debut  = moment(recherhce.dateInscription[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.dateInscription[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}dateInscription BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    getAll((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size, genre)
  }

  const getAll = (searchFait, page, size, genre) => {
    setIsLoading(true);
    searchFait = `${searchFait} ORDER BY dateInscription DESC`
    Service.getAll(Constant.access+"/membres", page, size, searchFait, true).then(
      async (response) => {
        if(genre !== undefined && genre !== null) {
          setParent({
            type: genre,
            data: response.data.data
          })
        } else {
          setPagination({
            page: page,
            size: size,
            totalElements: response.data.totalElements
          })
          setResponse(response.data.data)
        }
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }
  const modifierRole = () => {
    setIsLoading(true);
    let data = []
    roleSelect.forEach((element, index) => {
      data.push(JSON.parse(element))
    });
    search =  `?authId=${forUpdate.auth.id}`
      Service.addAll(Constant.access+"/modifierRole", search, data, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          handleCancel()
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
  };
  const onFinish2 = (values) => {
    setIsLoading(true);
    search =  `?membreId=${forUpdate.id}`
      Service.addAll(Constant.access+"/authentificationMembre", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form2.resetFields();
          handleCancel()
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
  };
  const onFinish = (values) => {
    console.log(photo);
    if(photo !== "") {
      values.photo = photo
    }
    setIsLoading(true);
    search =  `?assembleId=${values.assembleId}`
    values.nationalite = JSON.stringify(values.nationalite)
    console.log(values);
    if(forUpdate.id === undefined) {
      Service.addAll(Constant.access+"/membres", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    } else {
      values.id = forUpdate.id
      Service.putAll(Constant.access+"/membres", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          handleCancel()
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    }
  };

  const columnsParent = [
    {
      name: "prenom",
      label: t("prenom"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "nom",
      label: t("nomP"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "telephone",
      label: t("phone"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      label: t("Action"),
      name: "id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
            <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {ValiderParent(data)}}>  <span><Check /> {t("activer")}</span> </IconButton>
          </div>;
        }
      }
    }
  ];

  const columns = [
    {
      name: "photo",
      label: t("photo"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Image src={value} width={100} height={100}/>
        }
       }
    },
    {
      name: "prenom",
      label: t("prenom"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "nom",
      label: t("nomP"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "genre",
      label: t("genre"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "date_naissance",
      label: t("datenaissance"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      name: "lieu_naissance",
      label: t("lieunaissance"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "telephone",
      label: t("phone"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "email",
      label: t("email"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "adresse",
      label: t("adresse"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "ethni",
      label: t("ethnie"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "nationalite",
      label: t("Nationalite"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let val = JSON.parse(value);
          return val.label;
        }
       }
    },
    {
      name: "stuation_matrimoniale",
      label: t("matrimonial"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "profession",
      label: t("Profession"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "pere",
      label: t("pere"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(value !== null){
          return <div>
              <strong>{value.prenom} {value.nom}</strong>
              <br/>
              <strong>{value.telephone}</strong>
          </div>
          }
          return null;
       }
      }
    },
    {
      name: "mere",
      label: t("mere"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if(value !== null){
          return <div>
              <strong>{value.prenom} {value.nom}</strong>
              <br/>
              <strong>{value.telephone}</strong>
          </div>
          }
          return null;
       }
      }
    },
    {
      name: "eglise_de_provenance",
      label: t("provenance"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "assemble.nom",
      label: t("newAssemble"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "datearrivee",
      label: t("arrivee"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value !== undefined && value !== null) ? moment(value).format(formatDateFrançais) : null
        }
       }
    },
    {
      name: "culte",
      label: t("Culte"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "date_conversion",
      label: t("dateconversion"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value !== undefined && value !== null) ? moment(value).format(formatDateFrançais) : null
        }
       }
    },
    {
      name: "lieu_conversion",
      label: t("lieuconversion"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "date_bapteme",
      label: t("datebapteme"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value !== undefined && value !== null) ? moment(value).format(formatDateFrançais) : null;
        }
       }
    },
    {
      name: "lieu_bapteme",
      label: t("lieubapteme"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "date_bapteme_enfant",
      label: t("datebaptemeenfant"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value !== undefined && value !== null) ? moment(value).format(formatDateFrançais) : null;
        }
       }
    },
    {
      name: "auteur_bapteme_enfant",
      label: t("auteurbaptemeenfant"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "roledanseglise",
      label: t("roleEglise"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "statut_actuelle",
      label: t("statutactuelle"),
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "auth.username",
      label: t("username"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <>
                {value !== undefined && value !== null 
                ?
                  <div style={{textAlign:'center'}}>
                    <p>{value}</p>
                    <IconButton style={{color: 'blue', fontSize: 15}} onClick={(e)=> {openRole(data)}}><Person /> {t("role")} </IconButton>
                  </div>
                :
                  null
                }
                </>
        }
       }
    },
    {
      name: "information",
      label: t("information"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <IconButton style={{color: 'blue', fontSize: 15}} onClick={(e)=>{setInformation(value); setIsModalOpenInformation(true)}}> <RemoveRedEye /> Voir </IconButton>;
        }
       }
    },
    {
      name: "active",
      label: t("Statut"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <Tag color="success">{t("active")}</Tag> : <Tag color="error">{t("desactive")}</Tag>;
        }
       }
    },
    {
      name: "dateInscription",
      label: t("dateajout"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      label: t("Action"),
      name: "id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
            <IconButton style={{color: 'blue', fontSize: 15}} onClick={(e)=> {openAuth(data)}}> <Person /> {t("authentification")} </IconButton>
            <IconButton style={{color: data.active ? 'red' : 'green', fontSize: 15}} onClick={(e)=> {changeStatus(value)}}> {data.active ? <span><Cancel /> {t("desactiver")}</span> : <span><Check /> {t("activer")}</span>} </IconButton>
            <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm(t("suppressionConfirm")))deleteAction(value)}}> <Delete /> {t("supprimer")} </IconButton>
            <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifierAction(data)}}> <Edit /> {t("modifier")} </IconButton>
          </div>;
        }
      }
    }
  ];

  const openRole = (donnee) => {
    let data = []
    donnee.auth.roles.forEach((element, index) => {
      data.push(JSON.stringify(element))
      if((index+1) === donnee.auth.roles.length) {
        setDefautRole(data)
      }
    });
    setForUpdate(donnee);
    setIsModalOpenRole(true)
  }

  const openAuth = (data) => {
    setForUpdate(data);
    if(data.auth !== null && data.auth !== undefined && data.auth.username !== null && data.auth.username !== undefined) {
      form2.setFieldValue("username", data.auth.username)
    }
    setIsModalOpenAuthentification(true)
  }

  const changeStatus = (id) => {
    setIsLoading(true);
    Service.changeStatutAll(Constant.access+"/changeAMemberStatut", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const deleteAction = (id) => {
    setIsLoading(true);
    Service.deleteAll(Constant.access+"/membres", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const modifierAction = (data) => {
    setForUpdate(data)
    form.setFields([
      {name: "prenom", value: data.prenom},
      {name: "nom", value: data.nom},
      {name: "genre", value: data.genre},
      {name: "date_naissance", value: dayjs(moment(forUpdate.date_naissance).format(formatDateFrançais), formatDateFrançais)},
      {name: "lieu_naissance", value: data.lieu_naissance},
      {name: "telephone", value: data.telephone},
      {name: "email", value: data.email},
      {name: "adresse", value: data.adresse},
      {name: "ethni", value: data.ethni},
      {name: "nationalite", value: JSON.parse(data.nationalite)},
      {name: "stuation_matrimoniale", value: data.stuation_matrimoniale},
      {name: "profession", value: data.profession},
      {name: "pere", value: data.pere !== null ? data.pere : null},
      {name: "mere", value: data.mere !== null ? data.mere : null},
      {name: "eglise_de_provenance", value: data.eglise_de_provenance},
      {name: "assembleId", value: data.assemble.id},
      {name: "datearrivee", value: (forUpdate.datearrivee !== undefined && forUpdate.datearrivee !== null ) ? dayjs(moment(forUpdate.datearrivee).format(formatDateFrançais), formatDateFrançais) : null},
      {name: "culte", value: data.culte},
      {name: "date_conversion", value: (forUpdate.date_conversion !== undefined && forUpdate.date_conversion !== null ) ? dayjs(moment(forUpdate.date_conversion).format(formatDateFrançais), formatDateFrançais) : null},
      {name: "lieu_conversion", value: data.lieu_conversion},
      {name: "date_bapteme", value: (forUpdate.date_bapteme !== undefined && forUpdate.date_bapteme !== null ) ? dayjs(moment(forUpdate.date_bapteme).format(formatDateFrançais), formatDateFrançais) : null},
      {name: "lieu_bapteme", value: data.lieu_bapteme},
      {name: "date_bapteme_enfant", value: (forUpdate.date_bapteme_enfant !== undefined && forUpdate.date_bapteme_enfant !== null ) ? dayjs(moment(forUpdate.date_bapteme_enfant).format(formatDateFrançais), formatDateFrançais) : null},
      {name: "auteur_bapteme_enfant", value: data.auteur_bapteme_enfant},
      {name: "roledanseglise", value: data.roledanseglise},
      {name: "statut_actuelle", value: data.statut_actuelle},
      {name: "information", value: data.information},
    ])
    showModal()
  }

  const searchParent = (genre) =>{
    setParent({})
    afficher(pagination.page, pagination.size, genre)
  }

  const ValiderParent = (parent) =>{
    if(parent.genre === Constant.homme) {
      form.setFieldValue("pere", parent.id)
    } else {
      form.setFieldValue("mere", parent.id)
    }
    setParent({})
  }

  const onChange = (checkedValues) => {
    setRoleSelect(checkedValues)
  };


  const getFileBase64 = async (file) => {
    let src = file.url;
        src = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.target.files[0]);
          reader.onload = () => {
            photo = reader.result;
          };
        });
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{width: '50%'}}
      />
      <PageTitle title={t("Membres")} button={
        <div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={(e)=>{setForUpdate({}); showModal()}}
          >
              {t("newMembres")}
          </Button>
        </div>
      }/>

        <div>
          <Row gutter={16} style={{marginBottom: 20}}>
              <Col className="gutter-row" xxl={4}>
                <label>{t("prenom")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, prenom: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("nom")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, nom: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("genre")}</label>
                <br/>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", minWidth: 200, zIndex: 1}}
                    onChange={(e)=>setRecherche({...recherhce, genre: e})}
                    options={[
                      { "value": Constant.homme, "label": t(Constant.homme) },
                      { "value": Constant.femme, "label": t(Constant.femme) },
                    ]}
                  />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("datenaissance")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, datenaissance: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("lieunaissance")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, lieu_naissance: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("phone")}</label>
                <br/>
                <PhoneInput
                    onChange={(e)=> setRecherche({...recherhce, telephone: e})}
                    country={"ml"}
                    inputProps={{
                        name: 'telephone',
                        required: true,
                        autoFocus: true,
                      }}
                      inputStyle={{width: "100%", height:30}}
                />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("email")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, email: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("adresse")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, adresse: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("ethnie")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, ethni: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Nationalite")}</label>
                <br/>
                <ReactCountryFlagsSelect fullWidth searchable 
                      searchPlaceholder=""
                        onSelect={(e)=> {
                          if(e !== null) {
                            setRecherche({...recherhce, nationalite: e.label})
                          }
                        }} 
                      />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("matrimonial")}</label>
                <br/>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  style={{ width: "100%", minWidth: 200, zIndex: 1}}
                  onChange={(e)=>setRecherche({...recherhce, stuation_matrimoniale: e})}
                  options={[
                    { "value": Constant.celibataire, "label": t(Constant.celibataire) },
                    { "value": Constant.marie, "label": t(Constant.marie) },
                    { "value": Constant.veuve, "label": t(Constant.veuve) },
                    { "value": Constant.divorcee, "label": t(Constant.divorcee) },
                  ]}
                />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Profession")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, profession: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("pere")}</label>
                <br/>
                <PhoneInput
                    onChange={(e)=> setRecherche({...recherhce, pere: e})}
                    country={"ml"}
                    inputProps={{
                        name: 'telephone',
                        required: true,
                        autoFocus: true,
                      }}
                      inputStyle={{width: "100%", height:30}}
                />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("mere")}</label>
                <br/>
                <PhoneInput
                    onChange={(e)=> setRecherche({...recherhce, mere: e})}
                    country={"ml"}
                    inputProps={{
                        name: 'telephone',
                        required: true,
                        autoFocus: true,
                      }}
                      inputStyle={{width: "100%", height:30}}
                />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("provenance")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, eglise_de_provenance: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Assemblees")}</label>
                <br/>
                  <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, assemble: e})}
                        options=
                        {
                          allAssemble.length > 0 && allAssemble.map((element) => {
                            return { "value": element.id, "label": `${element.nom}` }
                          })
                        }
                      />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("arrivee")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, datearrivee: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Culte")}</label>
                <br/>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", minWidth: 200, zIndex: 1}}
                    onChange={(e)=>setRecherche({...recherhce, culte: e})}
                    options={[
                      { "value": Constant.Bambara, "label": t(Constant.Bambara) },
                      { "value": Constant.Français, "label": t(Constant.Français) },
                      { "value": Constant.Anglais, "label": t(Constant.Anglais) },
                      { "value": Constant.Bilingue, "label": t(Constant.Bilingue) },
                    ]}
                  />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("dateconversion")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, date_conversion: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("lieuconversion")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, lieu_conversion: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("datebapteme")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, date_bapteme: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("lieubapteme")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, lieu_bapteme: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("datebaptemeenfant")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, date_bapteme_enfant: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("auteurbaptemeenfant")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, auteur_bapteme_enfant: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("roleEglise")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, roledanseglise: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("statutactuelle")}</label>
                <br/>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  style={{ width: "100%", minWidth: 200, zIndex: 1}}
                  onChange={(e)=>setRecherche({...recherhce, statut_actuelle: e})}
                  options={[
                    { "value": Constant.Present, "label": t(Constant.Present) },
                    { "value": Constant.deces, "label": t(Constant.deces) },
                    { "value": Constant.depart, "label": t(Constant.depart) },
                    { "value": Constant.Autre, "label": t(Constant.Autre) },
                  ]}
                />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("information")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, information: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Statut")}</label>
                <br/>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, active: e})}
                        options={[
                          { "value": "", "label":  t("tout")},
                          { "value": "true", "label":  t("active")},
                          { "value": "false", "label":  t("desactive")},
                        ]}
                      />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("dateajout")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, dateInscription: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <br/>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={(e)=>{afficher(pagination.page, pagination.size)}}
                >
                    {t("chercher")}
                </Button>
              </Col>
          </Row>
          {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title={<h2>{`${t("total")}: ${pagination.totalElements}`}</h2>}
            data={response}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: true,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: pagination.size,
              count: pagination.totalElements,
              // textLabels: Constant.textLabels,
              selectableRows: "none",
              onChangePage(currentPage) {
                afficher(currentPage, pagination.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                afficher(0, numberOfRows)
              },
            }}
          />
        </div>
        
{/* Roles */}
      <Modal zIndex={1200} width={800} title={<p style={{borderBottom: "1px solid black"}}>{`${t("roleGestion")} (${forUpdate.prenom}  ${forUpdate.nom})`}</p>} open={isModalOpenRole} footer={null} onCancel={handleCancel} >
        <Checkbox.Group style={{ width: '100%' }} onChange={onChange} defaultValue={defautRole}>
          <Row>
            {roles.length > 0 && roles.map((element, index)=> {
              return <Col key={index} xxl={12} style={{paddingLeft:10, marginBottom: 10}}>
                <Checkbox style={{paddingTop: 10}} value={JSON.stringify(element)}><strong>{element.nom}</strong></Checkbox>
                <p style={{marginLeft: 30}}>{element.description}</p>
              </Col>
            })}
          </Row>
        </Checkbox.Group>
        <div style={{marginTop: 20}}>
          {isLoading ? (
            <CircularProgress size={26} className={classes.loginLoader} />
          ) : (
            <Button2 type="primary" onClick={modifierRole} style={{marginRight: 10}}>
              {t("modifier")}
            </Button2>
          )}
          <Button2 onClick={handleCancel} type="primary" danger>
            {t("annuler")}
          </Button2>
        </div>
      </Modal>
        
{/* Information */}
      <Modal zIndex={1200} width={1000} title={<p style={{borderBottom: "1px solid black"}}>{t("information")}</p>} open={isModalOpenInformation} footer={null} onCancel={handleCancel} >
        <div dangerouslySetInnerHTML={{ __html: information }} />
      </Modal>

{/* Authentification */}
      <Modal zIndex={1200} width={500} title={<p style={{borderBottom: "1px solid black"}}>{`${t("authentification")} (${forUpdate.prenom}  ${forUpdate.nom})`}</p>} open={isModalOpenAuthentification} footer={null} onCancel={handleCancel} >
          <Form
              name="basic5"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              onFinish={onFinish2}
              autoComplete="off"
              form={form2}
            >
              <Form.Item
                label={t("username")}
                name="username"
                rules={[
                  {
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("password")}
                name="mot_de_passe"
                rules={[
                  {
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 16,
                }}
              >
                <div style={{marginTop: 20}}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                      {t("modifier")}
                    </Button2>
                  )}
                  <Button2 onClick={handleCancel} type="primary" danger>
                    {t("annuler")}
                  </Button2>
                </div>
              </Form.Item>
            </Form>
      </Modal>

{/* Membre */}
      <Modal width={"80%"} zIndex={1200} title={<p style={{borderBottom: "1px solid black"}}>{t("newMembres")}</p>} open={isModalOpen} footer={null} onCancel={handleCancel} >
        <div>
        <Form
              name="basic6"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
          <Row gutter={16}>
            <Col className="gutter-row" lg={12}>
              <h3 style={{backgroundColor: 'rgba(255, 0, 0, 0.3)', padding:5}}>{t("InformationsPersonnels")}</h3>
                <Row gutter={16}>
            {/* Personnel 1 */}
                      <Form.Item
                        label={t("photo")}
                        name="photo"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input type="file" accept="image/*" onChange={(e)=> getFileBase64(e)}/>
                      </Form.Item>
                  <Col className="gutter-row" sm={12}>
                      <Form.Item
                        label={t("prenom")}
                        name="prenom"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={t("genre")}
                        name="genre"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options={[
                            { "value": Constant.homme, "label": t(Constant.homme) },
                            { "value": Constant.femme, "label": t(Constant.femme) },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("lieunaissance")}
                        name="lieu_naissance"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                            label={t("email")}
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: t("emailNoValid"),
                                },
                                {
                                required: false,
                                // message: t("champObligatoire"),
                                },
                            ]}
                        >
                            <Input />
                      </Form.Item>
                      <Form.Item
                        label={t("ethnie")}
                        name="ethni"
                        rules={[
                          {
                            required: false,
                            // message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={t("matrimonial")}
                        name="stuation_matrimoniale"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options={[
                            { "value": Constant.celibataire, "label": t(Constant.celibataire) },
                            { "value": Constant.marie, "label": t(Constant.marie) },
                            { "value": Constant.veuve, "label": t(Constant.veuve) },
                            { "value": Constant.divorcee, "label": t(Constant.divorcee) },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        label={<span>{t("pere")} {(form.getFieldValue("pere") !== undefined && form.getFieldValue("pere") !== null) && <Check style={{color: 'green'}}/>}</span>}
                        name="pere"
                        
                        rules={[
                          {
                            required: false,
                            // message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <div style={{backgroundColor: "rgba(0, 0, 0, 0.3)", padding: 10}}>
                          <Row gutter={16}>
                            <Col className="gutter-row" xxl={12}>
                              <label>{t("prenom")}</label>
                              <br/>
                              <Input onChange={(e)=> setRecherche({...recherhce, prenom: e.target.value})}/>
                            </Col>
                            <Col className="gutter-row" xxl={12}>
                              <label>{t("nom")}</label>
                              <br/>
                              <Input onChange={(e)=> setRecherche({...recherhce, nom: e.target.value})}/>
                            </Col>
                            <Col className="gutter-row" xxl={12}>
                              <label>{t("phone")}</label>
                              <br/>
                              <PhoneInput
                                  value={value}
                                  onChange={(e)=> setRecherche({...recherhce, telephone: e})}
                                  country={"ml"}
                                  inputProps={{
                                      name: 'telephone',
                                      required: true,
                                      autoFocus: true,
                                    }}
                                    inputStyle={{width: "100%", height:30}}
                              />
                            </Col>
                            <Col className="gutter-row" xxl={12}>
                              <Button2 onClick={(e)=> {searchParent(Constant.homme)}} style={{backgroundColor: 'green', color: 'white', marginTop: 20}}>
                                {t("chercher")}
                              </Button2>
                            </Col>
                            {parent.type !== undefined && parent.type === Constant.homme &&
                              <>
                                <hr/>
                                <Col className="gutter-row" xxl={24}>
                                  <MUIDataTable
                                      data={parent.data}
                                      columns={columnsParent}
                                      options={{
                                        draggableColumns: { enabled: true },
                                        enableNestedDataAccess: '.',
                                        filterType: "multiselect",
                                        jumpToPage: true,
                                        pagination: false,
                                        // textLabels: Constant.textLabels,
                                        selectableRows: "none",
                                      }}
                                    />
                                </Col>
                              </>
                            }
                          </Row>
                        </div>
                      </Form.Item>
                      
                  </Col>
              {/* Personnel 2 */}
                  <Col className="gutter-row" sm={12}>
                    <Form.Item
                        label={t("nomP")}
                        name="nom"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Input />
                    </Form.Item>
                    <Form.Item
                          label={t("datenaissance")}
                          name="date_naissance"
                          rules={[
                            {
                              type: 'object',
                              required: true,
                              message: t("champObligatoire"),
                            },
                          ]}
                          >
                          <ConfigProvider locale={locale}>
                              <DatePicker style={{width: '100%'}}
                                defaultValue={forUpdate.date_naissance !== undefined && dayjs(moment(forUpdate.date_naissance).format(formatDateFrançais), formatDateFrançais)}
                                format={formatDateFrançais}
                                placeholder=""
                                onChange={ (date, dateString) => {
                                  form.setFieldValue("date_naissance", date)
                                }}
                              />
                          </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                        label={t("phone")}
                        name="telephone"
                        rules={[
                            {
                            required: false,
                            // message: t("champObligatoire"),
                            },
                        ]}
                    >
                        <PhoneInput
                            value={value}
                            onChange={setValue}
                            country={"ml"}
                            inputProps={{
                                name: 'telephone',
                                required: true,
                                autoFocus: true,
                              }}
                              inputStyle={{width: "100%", height:30}}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("adresse")}
                        name="adresse"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    <Form.Item
                      label={t("Nationalite")}
                      name="nationalite"
                      rules={[
                        {
                          required: true,
                          message: t("champObligatoire"),
                        },
                      ]}
                    >
                      <ReactCountryFlagsSelect fullWidth searchable 
                      // selected={selected}
                      searchPlaceholder=""
                        onSelect={(e)=> {
                          if(e !== null) {
                            form.setFieldValue("nationalite", e)
                          }
                        }} 
                      />
                    </Form.Item>
                    <Form.Item
                        label={t("Profession")}
                        name="profession"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<span>{t("mere")} {(form.getFieldValue("mere") !== undefined && form.getFieldValue("mere") !== null)  && <Check style={{color: 'green'}}/>}</span>}
                        name="mere"
                        rules={[
                          {
                            required: false,
                            // message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <div style={{backgroundColor: "rgba(0, 0, 0, 0.3)", padding: 10}}>
                          <Row gutter={16}>
                            <Col className="gutter-row" xxl={12}>
                              <label>{t("prenom")}</label>
                              <br/>
                              <Input onChange={(e)=> setRecherche({...recherhce, prenom: e.target.value})}/>
                            </Col>
                            <Col className="gutter-row" xxl={12}>
                              <label>{t("nom")}</label>
                              <br/>
                              <Input onChange={(e)=> setRecherche({...recherhce, nom: e.target.value})}/>
                            </Col>
                            <Col className="gutter-row" xxl={12}>
                              <label>{t("phone")}</label>
                              <br/>
                              <PhoneInput
                                  value={value}
                                  onChange={(e)=> setRecherche({...recherhce, telephone: e})}
                                  country={"ml"}
                                  inputProps={{
                                      name: 'telephone',
                                      required: true,
                                      autoFocus: true,
                                    }}
                                    inputStyle={{width: "100%", height:30}}
                              />
                            </Col>
                            <Col className="gutter-row" xxl={12}>
                              <Button2 onClick={(e)=> {searchParent(Constant.femme)}} style={{backgroundColor: 'green', color: 'white', marginTop: 20}}>
                                {t("chercher")}
                              </Button2>
                            </Col>
                            {parent.type !== undefined && parent.type === Constant.femme &&
                              <>
                                <hr/>
                                <Col className="gutter-row" xxl={24}>
                                  <MUIDataTable
                                      data={parent.data}
                                      columns={columnsParent}
                                      options={{
                                        draggableColumns: { enabled: true },
                                        enableNestedDataAccess: '.',
                                        filterType: "multiselect",
                                        jumpToPage: true,
                                        pagination: false,
                                        // textLabels: Constant.textLabels,
                                        selectableRows: "none",
                                      }}
                                    />
                                </Col>
                              </>
                            }
                          </Row>
                        </div>
                      </Form.Item>
                  </Col>
                </Row>
            </Col>
        {/* Info eglise */}
            <Col className="gutter-row" lg={12}>
              <h3 style={{backgroundColor: 'rgba(255, 0, 0, 0.3)', padding:5}}>{t("InformationsSurLaFoi")}</h3>
              <Row gutter={16}>
            {/* info 1 */}
                  <Col className="gutter-row" sm={12}>
                    <Form.Item
                        label={t("provenance")}
                        name="eglise_de_provenance"
                        rules={[
                          {
                            required: false,
                            // message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Input />
                    </Form.Item>
                    <Form.Item
                          label={t("arrivee")}
                          name="datearrivee"
                          rules={[
                            {
                              type: 'object',
                              required: false,
                              // message: t("champObligatoire"),
                            },
                          ]}
                          >
                          <ConfigProvider locale={locale}>
                              <DatePicker style={{width: '100%'}}
                                defaultValue={(forUpdate.datearrivee !== undefined && forUpdate.datearrivee !== null) ? dayjs(moment(forUpdate.datearrivee).format(formatDateFrançais), formatDateFrançais) : null}
                                format={formatDateFrançais}
                                placeholder=""
                                onChange={ (date, dateString) => {
                                  form.setFieldValue("datearrivee", date)
                                }}
                              />
                          </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                          label={t("dateconversion")}
                          name="date_conversion"
                          rules={[
                            {
                              type: 'object',
                              required: false,
                              // message: t("champObligatoire"),
                            },
                          ]}
                          >
                          <ConfigProvider locale={locale}>
                              <DatePicker style={{width: '100%'}}
                                defaultValue={(forUpdate.date_conversion !== undefined && forUpdate.date_conversion !== null) ? dayjs(moment(forUpdate.date_conversion).format(formatDateFrançais), formatDateFrançais) : null}
                                format={formatDateFrançais}
                                placeholder=""
                                onChange={ (date, dateString) => {
                                  form.setFieldValue("date_conversion", date)
                                }}
                              />
                          </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                          label={t("datebapteme")}
                          name="date_bapteme"
                          rules={[
                            {
                              type: 'object',
                              required: false,
                              // message: t("champObligatoire"),
                            },
                          ]}
                          >
                          <ConfigProvider locale={locale}>
                              <DatePicker style={{width: '100%'}}
                                defaultValue={(forUpdate.date_bapteme !== undefined && forUpdate.date_bapteme !== null) ? dayjs(moment(forUpdate.date_bapteme).format(formatDateFrançais), formatDateFrançais) : null}
                                format={formatDateFrançais}
                                placeholder=""
                                onChange={ (date, dateString) => {
                                  form.setFieldValue("date_bapteme", date)
                                }}
                              />
                          </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                          label={t("roleEglise")}
                          name="roledanseglise"
                          rules={[
                            {
                              required: false,
                              // message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input />
                    </Form.Item>
                    <Form.Item
                          label={t("datebaptemeenfant")}
                          name="date_bapteme_enfant"
                          rules={[
                            {
                              type: 'object',
                              required: false,
                              // message: t("champObligatoire"),
                            },
                          ]}
                          >
                          <ConfigProvider locale={locale}>
                              <DatePicker style={{width: '100%'}}
                                defaultValue={(forUpdate.date_bapteme_enfant !== undefined && forUpdate.date_bapteme_enfant !== null) ? dayjs(moment(forUpdate.date_bapteme_enfant).format(formatDateFrançais), formatDateFrançais) : null}
                                format={formatDateFrançais}
                                placeholder=""
                                onChange={ (date, dateString) => {
                                  form.setFieldValue("date_bapteme_enfant", date)
                                }}
                              />
                          </ConfigProvider>
                    </Form.Item>
                  </Col>
            {/* infor 2 */}
                  <Col className="gutter-row" sm={12}>
                    <Form.Item
                      label={t("newAssemble")}
                      name="assembleId"
                      rules={[
                        {
                          required: true,
                          message: t("champObligatoire"),
                        },
                      ]}
                    >
                      <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options=
                          {
                            allAssemble.length > 0 && allAssemble.map((element) => {
                              return { "value": element.id, "label": `${element.nom}` }
                            })
                          }
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("Culte")}
                        name="culte"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options={[
                            { "value": Constant.Bambara, "label": t(Constant.Bambara) },
                            { "value": Constant.Français, "label": t(Constant.Français) },
                            { "value": Constant.Anglais, "label": t(Constant.Anglais) },
                            { "value": Constant.Bilingue, "label": t(Constant.Bilingue) },
                          ]}
                        />
                    </Form.Item>
                    <Form.Item
                          label={t("lieuconversion")}
                          name="lieu_conversion"
                          rules={[
                            {
                              required: false,
                              // message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input />
                    </Form.Item>
                    <Form.Item
                          label={t("lieubapteme")}
                          name="lieu_bapteme"
                          rules={[
                            {
                              required: false,
                              // message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("statutactuelle")}
                        name="statut_actuelle"
                        rules={[
                          {
                            required: true,
                            message: t("champObligatoire"),
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          style={{ width: "100%", zIndex: 1}}
                          options={[
                            { "value": Constant.Present, "label": t(Constant.Present) },
                            { "value": Constant.deces, "label": t(Constant.deces) },
                            { "value": Constant.depart, "label": t(Constant.depart) },
                            { "value": Constant.Autre, "label": t(Constant.Autre) },
                          ]}
                        />
                    </Form.Item>
                    <Form.Item
                          label={t("auteurbaptemeenfant")}
                          name="auteur_bapteme_enfant"
                          rules={[
                            {
                              required: false,
                              // message: t("champObligatoire"),
                            },
                          ]}
                        >
                          <Input />
                    </Form.Item>
                  </Col>
                </Row>

              <Form.Item
                label={t("information")}
                name="information"
                rules={[
                  {
                    required: false,
                  },
                ]}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
              >
                <CKEditor
                    editor={ ClassicEditor }
                    data={(forUpdate.information !== null && forUpdate.information !== undefined) ? forUpdate.information : ""}
                    onChange={ ( event, editor ) => {
                      form.setFieldValue("information", editor.getData())
                    }}
                />
              </Form.Item>

            </Col>
          </Row>
          
              
              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 16,
                }}
              >
                <div style={{marginTop: 20}}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                      {forUpdate.id !== undefined ? t("modifier") : t("ajouter")}
                    </Button2>
                  )}
                  <Button2 onClick={handleCancel} type="primary" danger>
                    {t("annuler")}
                  </Button2>
                </div>
              </Form.Item>

            </Form>
        </div>
      </Modal>
    </div>
  );
}
export default withTranslation()(Membres)