import React from "react";
import Accueil from "../../components/Layout/accueil";

export default function Dashboard(props) {
  
  return (
    <>
      <div style={{marginTop: -80}}>
        <Accueil />
      </div>
    </>
  );
}

