import React, { useEffect, useState } from "react";

// styles
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, CircularProgress, IconButton, LinearProgress } from "@material-ui/core";
import { Button as Button2, Col, ConfigProvider, DatePicker, Form, Image, Input, Modal, Row, Select, Tag } from "antd";
import locale from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import { withTranslation } from "react-i18next";
import PageTitle from "../../../components/PageTitle/PageTitle";
import useStyles from "./styles";

import { Cancel, Check, Delete, Edit, RemoveRedEye } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import 'dayjs/locale/fr';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import Tree from 'react-d3-tree';
import { ToastContainer, toast } from "react-toastify";
import { Constant } from "../../../Constant";
import Service from "../../../service";
import "./tree.css";

dayjs.locale('fr');


let logo = ""
function Assemblees(props) {
  const {t} = props;
  var classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenInformation, setIsModalOpenInformation] = useState(false);
  const [isModalOpenTree, setIsModalOpenTree] = useState(false);
  var [information, setInformation] = useState("");
  var [isLoading, setIsLoading] = useState(false);
  var [response, setResponse] = useState([]);
  var [forUpdate, setForUpdate] = useState({});
  var [orgChart, setOrgChart] = useState({});
  var [recherhce, setRecherche] = useState({});
  var [key, setKey] = useState(0);
  const theme = useTheme();
  var [treeParam, setTreeParam] = useState({
    orientation: "vertical",
    chemin: "diagonal",
    espace: 2,
  });
  var [pagination, setPagination] = useState({page: 0, size: 50, totalElements: 0});
  let search = "";
  const { RangePicker } = DatePicker;
  let formatDateFrançais = "DD/MM/YYYY"
  let formatDateAnglais = "YYYY-MM-DD"

  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpenInformation(false);
  };

  useEffect(()=>{
    (()=>{
      afficher(pagination.page, pagination.size)
    })();
  }, [])

  const afficher = async (page, size) => {
    search = "&search="
    if(recherhce.nom !== undefined && recherhce.nom.trim() !== ""){
      search = `${search}nom LIKE _1_${recherhce.nom}_2_ AND `
    }
    if(recherhce.adresse !== undefined && recherhce.adresse.trim() !== ""){
      search = `${search}adresse LIKE _1_${recherhce.adresse}_2_ AND `
    }
    if(recherhce.active !== undefined && recherhce.active.trim() !== ""){
      search = `${search}active=${recherhce.active} AND `
    }
    if(recherhce.datecreation !== undefined && recherhce.datecreation.length > 0 && recherhce.datecreation[1].trim() !== ""){
      let debut  = moment(recherhce.datecreation[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.datecreation[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}Datecreation BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    if(recherhce.dateajout !== undefined && recherhce.dateajout.length > 0 && recherhce.dateajout[1].trim() !== ""){
      let debut  = moment(recherhce.dateajout[0], formatDateFrançais).format(formatDateAnglais);
      let fin  = moment(recherhce.dateajout[1], formatDateFrançais).format(formatDateAnglais);
      search = `${search}dateajout BETWEEN CAST('${debut}' as date) AND CAST('${fin}' as date) AND `
    }
    getAll((search !== "&search=" ? search.slice(0, -5) : "&search=true"), page, size)
    getAssembleTree()
  }

  const getAssembleTree = () => {
    setIsLoading(true);
    Service.allStatistique(Constant.access+"/assembleTree", "", {}, true).then(
      async (response) => {
        setOrgChart(response.data.data)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const getAll = (searchFait, page, size) => {
    setIsLoading(true);
    searchFait = `${searchFait} ORDER BY dateajout DESC`
    Service.getAll(Constant.access+"/assemble", page, size, searchFait, true).then(
      async (response) => {
        setPagination({
          page: page,
          size: size,
          totalElements: response.data.totalElements
        })
        setResponse(response.data.data)
        setIsLoading(false);
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }
  const onFinish = (values) => {
    if(logo !== "") {
      values.logo = logo
    }
    setIsLoading(true);
    search =  `?search=${(values.attach_assemble && values.attach_assemble.length > 0) !== undefined ? values.attach_assemble : []}`
    if(forUpdate.id === undefined) {
      Service.addAll(Constant.access+"/assemble", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    } else {
      values.id = forUpdate.id
      Service.putAll(Constant.access+"/assemble", search, values, true).then(
        async (response) => {
          setIsLoading(false);
          toast.success(t(response.data.message))
          form.resetFields();
          handleCancel()
          afficher(pagination.page, pagination.size)
        }, error => {
          setIsLoading(false);
          toast.error(t(error.response.data.message))
          if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
            toast.error(error.response.data.cause.message)
          }
        }
      )
    }
  };

  const columns = [
    {
      name: "logo",
      label: t("logo"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Image src={value} width={100} height={100}/>
        }
       }
    },
    {
      name: "nom",
      label: t("nom"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "adresse",
      label: t("adresse"),
      options: {
        filter: true,
        sort: true,
       }
    },
    {
      name: "datecreation",
      label: t("Datecreation"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      name: "longitude",
      label: t("longitude"),
      options: {
        filter: false,
        sort: false,
       }
    },
    {
      name: "latitude",
      label: t("latitude"),
      options: {
        filter: false,
        sort: false,
       }
    },
    {
      name: "enfant",
      label: t("enfant"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>
            {value.length > 0 && value.map((element, index)=>{
              return <p key={index+"a"} style={{backgroundColor:"rgba(255, 0, 0, 0.1)"}}>{element.enfant.nom}</p>
            })}
          </div>
       }
      }
    },
    {
      name: "parent",
      label: t("parent"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>
            {value.length > 0 && value.map((element, index)=>{
              return <p key={index+"b"} style={{backgroundColor:"rgba(0, 255, 0, 0.1)"}}>{element.parent.nom}</p>
            })}
          </div>
        }
       }
    },
    {
      name: "information",
      label: t("information"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <IconButton style={{color: 'blue', fontSize: 15}} onClick={(e)=>{setInformation(value); setIsModalOpenInformation(true)}}> <RemoveRedEye /> Voir </IconButton>;
        }
       }
    },
    {
      name: "active",
      label: t("Statut"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <Tag color="success">{t("active")}</Tag> : <Tag color="error">{t("desactive")}</Tag>;
        }
       }
    },
    {
      name: "dateAjout",
      label: t("dateajout"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format(formatDateFrançais)
        }
       }
    },
    {
      label: t("Action"),
      name: "id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = tableMeta.tableData[tableMeta.rowIndex];
          return <div>
            <IconButton style={{color: data.active ? 'red' : 'green', fontSize: 15}} onClick={(e)=> {changeStatus(value)}}> {data.active ? <span><Cancel /> {t("desactiver")}</span> : <span><Check /> {t("activer")}</span>} </IconButton>
            <IconButton style={{color: 'red', fontSize: 15}} onClick={(e)=> {if(window.confirm(t("suppressionConfirm")))deleteAction(value)}}> <Delete /> {t("supprimer")} </IconButton>
            <IconButton style={{color: 'green', fontSize: 15}} onClick={(e)=> {modifierAction(data)}}> <Edit /> {t("modifier")} </IconButton>
          </div>;
        }
      }
    }
  ];

  const changeStatus = (id) => {
    setIsLoading(true);
    Service.changeStatutAll(Constant.access+"/changeAssembleStatut", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const deleteAction = (id) => {
    setIsLoading(true);
    Service.deleteAll(Constant.access+"/assemble", id, true).then(
      async (response) => {
        setIsLoading(false);
        toast.success(t(response.data.message))
        afficher(pagination.page, pagination.size)
      }, error => {
        setIsLoading(false);
        toast.error(t(error.response.data.message))
        if(error.response.data.cause !== undefined && error.response.data.cause !== null && error.response.data.cause.message !== undefined) {
          toast.error(error.response.data.cause.message)
        }
      })
  }

  const modifierAction = (data) => {
    setKey(key + 1)
    let attach_assemble = []
    data.parent.forEach(element => {
      attach_assemble.push(element.parent.id)
    });
    setForUpdate(data)
    form.setFields([
      // {name: "logo", value: data.logo},
      {name: "nom", value: data.nom},
      {name: "adresse", value: data.adresse},
      {name: "datecreation", value: dayjs(moment(forUpdate.datecreation).format(formatDateFrançais), formatDateFrançais)},
      {name: "longitude", value: data.longitude},
      {name: "latitude", value: data.latitude},
      {name: "information", value: data.information},
      {name: "attach_assemble", value: attach_assemble},
    ])
    showModal()
  }


  const getFileBase64 = async (file) => {
    let src = file.url;
        src = await new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(file.target.files[0]);
          reader.onload = () => {
            logo = reader.result;
          };
        });
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{width: '50%'}}
      />
      <PageTitle title={t("Assemblees")} button={
        <div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={(e)=>{setKey(key + 1); setForUpdate({}); showModal()}}
          >
              {t("newAssemble")}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={(e)=>{setIsModalOpenTree(true)}}
          >
              {t("Schema")}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={(e)=>{setIsModalOpenTree(false)}}
          >
              {t("table")}
          </Button>
        </div>
      }/>

      {!isModalOpenTree ?
        <div>
          <Row gutter={16} style={{marginBottom: 20}}>
              <Col className="gutter-row" xxl={4}>
                <label>{t("nom")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, nom: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("adresse")}</label>
                <br/>
                <Input onChange={(e)=> setRecherche({...recherhce, adresse: e.target.value})}/>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Statut")}</label>
                <br/>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        style={{ width: "100%", minWidth: 200, zIndex: 1}}
                        onChange={(e)=>setRecherche({...recherhce, active: e})}
                        options={[
                          { "value": "", "label":  t("tout")},
                          { "value": "true", "label":  t("active")},
                          { "value": "false", "label":  t("desactive")},
                        ]}
                      />
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("Datecreation")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, datecreation: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <label>{t("dateajout")}</label>
                <br/>
                <ConfigProvider locale={locale}>
                  <RangePicker format={formatDateFrançais} onChange={(dates, datesString)=> setRecherche({...recherhce, dateajout: datesString})} placeholder={[t("du"), t("au")]}/>
                </ConfigProvider>
              </Col>
              <Col className="gutter-row" xxl={4}>
                <br/>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={(e)=>{afficher(pagination.page, pagination.size)}}
                >
                    {t("chercher")}
                </Button>
              </Col>
          </Row>
          {isLoading && <LinearProgress color={Constant.couleurSpinnerLine} />}
          <MUIDataTable
            title={<h2>{`${t("total")}: ${pagination.totalElements}`}</h2>}
            data={response}
            columns={columns}
            options={{
              draggableColumns: { enabled: true },
              enableNestedDataAccess: '.',
              filterType: "multiselect",
              jumpToPage: true,
              serverSide: true,
              rowsPerPageOptions:[50, 100, 500, 1000],
              rowsPerPage: pagination.size,
              count: pagination.totalElements,
              // textLabels: Constant.textLabels,
              selectableRows: "none",
              onChangePage(currentPage) {
                afficher(currentPage, pagination.size)
              },
              onChangeRowsPerPage(numberOfRows) {
                afficher(0, numberOfRows)
              },
            }}
          />
        </div>
        :
        <div>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <label>{t("orientation")}</label>
                <br/>
                <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setTreeParam({...treeParam, orientation:  e})}
                      // defaultValue={(state.service.id !== "") && state.service.id}
                      options={[
                        {label: "vertical", value: "vertical"},
                        {label: "horizontal", value: "horizontal"}
                      ]}
                    />
              </Col>
              <Col className="gutter-row" span={6}>
                <label>{t("Chemin")}</label>
                <br/>
                <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setTreeParam({...treeParam, chemin:  e})}
                      // defaultValue={(state.service.id !== "") && state.service.id}
                      options={[
                        {label: "diagonal", value: "diagonal"},
                        {label: "elbow", value: "elbow"},
                        {label: "step", value: "step"},
                        {label: "straight", value: "straight"}
                      ]}
                    />
              </Col>
              <Col className="gutter-row" span={6}>
                <label>{t("Espace")}</label>
                <br/>
                <Select
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      style={{ width: "100%", zIndex: 1}}
                      onChange={(e)=>setTreeParam({...treeParam, espace:  e})}
                      // defaultValue={(state.service.id !== "") && state.service.id}
                      options=
                        {
                          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((element) => {
                            return { "value": element, "label": `${element}` }
                          })
                        }
                    />
              </Col>
            </Row>
            <div id="treeWrapper" style={{ width: window.screen.width/1.2, height: window.screen.height}}>
              <Tree data={orgChart} 
                rootNodeClassName="node__root"
                branchNodeClassName="node__branch"
                leafNodeClassName="node__leaf"
                separation={{siblings: treeParam.espace, nonSiblings: 2}}
                orientation={treeParam.orientation}
                enableLegacyTransitions={true}
                centeringTransitionDuration={5000}
                pathFunc={treeParam.chemin}
                translate={{x: treeParam.orientation === "horizontal" ? 50 : 531, y: treeParam.orientation === "horizontal" ? 300: 50}}
                zoomable={true}
                draggable={true}
              />
            </div>
        </div>
      }
{/* Information */}
      <Modal zIndex={1200} width={1000} title={<p style={{borderBottom: "1px solid black"}}>{t("information")}</p>} open={isModalOpenInformation} footer={null} onCancel={handleCancel} >
        <div dangerouslySetInnerHTML={{ __html: information }} />
      </Modal>

{/* Assemble */}
      <Modal zIndex={1200} title={<p style={{borderBottom: "1px solid black"}}>{t("newAssemble")}</p>} open={isModalOpen} footer={null} onCancel={handleCancel} >
        <div>
          <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label={t("logo")}
                name="logo"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input type="file" accept="image/*" onChange={(e)=> getFileBase64(e)}/>
              </Form.Item>
              <br/>
              <Form.Item
                label={t("nom")}
                name="nom"
                rules={[
                  {
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <br/>
              <Form.Item
                label={t("adresse")}
                name="adresse"
                rules={[
                  {
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <br/>
              <Form.Item
                label={t("Datecreation")}
                name="datecreation"
                rules={[
                  {
                    type: 'object',
                    required: true,
                    message: t("champObligatoire"),
                  },
                ]}
                >
                <ConfigProvider locale={locale}>
                    <DatePicker style={{width: '100%'}}
                      defaultValue={forUpdate.id !== undefined && dayjs(moment(forUpdate.datecreation).format(formatDateFrançais), formatDateFrançais)}
                      format={formatDateFrançais}
                      placeholder=""
                      onChange={ (date, dateString) => {
                        form.setFieldValue("datecreation", date)
                      }}
                    />
                </ConfigProvider>
              </Form.Item>
              <br/>
              <Form.Item
                label={t("longitude")}
                name="longitude"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <br/>
              <Form.Item
                label={t("latitude")}
                name="latitude"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <br/>
              <Form.Item
                label={t("lierA")}
                name="attach_assemble"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    style={{ width: "100%", zIndex: 1}}
                    // onChange={(e)=>setState({...state, service: {...state.service, id: e}})}
                    // defaultValue={(state.service.id !== "") && state.service.id}
                    options=
                    {
                      response.length > 0 && response.map((element) => {
                        return { "value": element.id, "label": `${element.nom}` }
                      })
                    }
                  />
              </Form.Item>
              <br/>
              <Form.Item
                label={t("information")}
                name="information"
                rules={[
                  {
                    required: false,
                  },
                ]}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
              >
                <CKEditor
                key={key}
                    editor={ ClassicEditor }
                    data={forUpdate.information}
                    onChange={ ( event, editor ) => {
                      form.setFieldValue("information", editor.getData())
                    }}
                />
              </Form.Item>
              <br/>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button2 type="primary" htmlType="submit" style={{marginRight: 10}}>
                    {forUpdate.id !== undefined ? t("modifier") : t("ajouter")}
                  </Button2>
                )}
                <Button2 onClick={handleCancel} type="primary" danger>
                  {t("annuler")}
                </Button2>
              </Form.Item>

            </Form>
        </div>
      </Modal>
    </div>
  );
}
export default withTranslation()(Assemblees)