export const en = {
    connexion: "Login",
    jean1721: "John 17:21",
    jean1721verset: "That all of them may be one, Father, just as you are in me and I am in you. May they also be in us so that the world may believe that you have sent me.",
    username: "Username",
    password: "Password",
    seconnecter: "To log in",
    motdepasseoublier: "Forgot your password ?",
    changermotdepasse: "change my password",
    motDePasseConfirmationDifferent: "The new password and its confirmation are different",
    ancienpassword: "Old password",
    nouveaupassword: "New password",
    confirmationpassword: "Enter the new password again",
    accesRefuse: "Your access request has been refused. Please verify your information and your network.",
    dashboard: "Dashboard",
    Assemblees: "Assemblies",
    Departements: "Departments",
    Membres: "Members",
    Mariages: "Weddings",
    Activites: "Activities",
    newActivites: "New activity",
    Projets: "Projects",
    newProjets: "New project",
    Comptabilites: "Accounting",
    SMS: "Messages",
    Statistiques: "Statistics",
    newAssemble: "New Assemblie",
    champObligatoire: "This field is required",
    logo: "Logo",
    nom: "Name",
    adresse: "Address",
    Datecreation: "Creation date",
    dateajout: "date Added",
    longitude: "Longitude",
    latitude: "Latitude",
    information: "Other information",
    ajouter: "Add",
    modifier: "Update",
    supprimer: "Delete",
    annuler: "Cancel",
    ajoutSuccess: "Addition completed successfully",
    modificationSuccess: "Change completed successfully",
    suppressionSuccess: "Deletion completed successfully",
    erreurServeur: "The server encountered an error. If the error persists, please contact the administrator.",
    Statut: "Status",
    active: "Enabled",
    desactive: "Disabled",
    activer: "Enable",
    desactiver: "Disable",
    suppressionConfirm: "Are you sure of your action?",
    Action: "Action",
    lierA: "Related to",
    Schema: "Plan",
    table: "Table",
    parent: "Father",
    enfant: "Child",
    chercher: "To search",
    tout: "All",
    du: "From",
    au: "to",
    de: "From",
    a: "to",
    orientation: "Orientation",
    Chemin: "Path",
    Espace: "Space",
    newDepartements: "New department",
    newMembres: "New member",
    nouveauMembres: "New members",
    ancienMembres: "Old members",
    newMariages: "New wedding",
    InformationsPersonnels: "Personal information",
    InformationsSurLaFoi: "Information about faith",
    prenom: "FirstName",
    nomP: "LastName",
    datenaissance: "Date of Birth",
    datemariage: "Date of wedding",
    lieunaissance: "Place of birth",
    matrimonial: "Marital status",
    celibataire: "Single",
    marie: "Married",
    veuve: "Widow",
    divorcee: "Divorcee",
    ethnie: "Ethnic group",
    Profession: "Profession",
    Nationalite: "Nationality",
    arrivee: "Arrival date",
    provenance: "Assembly of provenance",
    dateconversion: "Conversion date",
    lieuconversion: "Place of conversion",
    datebapteme: "Baptism date",
    lieubapteme: "Baptism place",
    datebaptemeenfant: "Date of baptism as a child",
    auteurbaptemeenfant: "Author of baptism as a child",
    lieu: "Place",
    dateTime: "Date and time",
    roleEglise: "Role in the assembly",
    Culte: "Worship",
    Bambara: "Bamanankan",
    Français: "French",
    Anglais: "English",
    Bilingue: "Bilingual",
    statutactuelle: "Status in the assembly",
    Present: "Here",
    deces: "Death",
    depart: "Departure",
    Autre: "Other",
    Observation: "Observation",
    genre: "Gender",
    Homme: "Man",
    Femme: "Woman",
    newCulte: "New cult",
    jeuneHomme: "Young man",
    jeuneFemme: "Young woman",
    enfantHomme: "Boy",
    enfantFemme: "Girl",
    photo: "Picture",
    phone: "Phone",
    emailNoValid: "This email is not valid",
    email: "Email",
    pere: "Father",
    mere: "Mather",
    afficher: "Show",
    cacher: "Hide",
    periode: "Period",
    periodeRepetion: "Period number",
    Jours: "Days",
    Semaines: "Weeks",
    Mois: "Months",
    Années: "Years",
    Chaque: "Each",
    datedebut: "Start date",
    datefin: "End date",
    heuredebut: "Start time",
    heurefin: "End time",
    heureAjout: "Add time",
    montant: "Amount",
    newAdd: "New add",
    Recharge: "Top-up",
    Retrait: "Withdrawal",
    rowSelect: "Rows selected",
    authentification: "Authentication",
    roleGestion: "Role management",
    role: "Role",
    fraisParticipation: "Participation fee",
    clesSms: "Rôle",
    clesPaiement: "Rôle",
    messageConfirmation: "Rôle",
    fraisParticipation: "Participation fee",
    clesSms: "SMS key on sevob.com",
    expediteursms: "Valid sender on sevob.com",
    clesPaiement: "Payment key on sevob.com",
    messageConfirmation: "Confirmation message",
    destinateur: "Recipients",
    envoiSuccess: "Sending done successfully",
    send: "Send",
    sedeconnecter: "Sign out",
    Reste: "Rest",
    total: "Total",
    queEstVotreAssemble: "What is your congregation ?",
    gratuit: "Free",
    payerMaintenant: "Pay now",
    inscrivezvousMaintenant: "Register now",
    contibuerMaintenant: "Contribute now",
    personneDansTableau: "Please add people to the table",
    oui: "Yes",
    non: "No",
    administrateur: "Administrator",
    BetiwQuiz: "Betiw Questions",
    BetiwEnseignement: "Betiw Teachings",
    chapitre: "Chapiter",
    niveau: "Level",
    question: "Question",
    reponse: "Answer",
    vrai: "Good answer",
    theme: "Theme",
    contenu: "Content",
    audio: "Audio",
    orateur: "Preacher",
}